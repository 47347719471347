import { Button } from "antd";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import "./decisioning.scss";
import { useState } from "react";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";

interface IPasteDecision {
  getDecisioningList: any;
}

const PasteDecision: React.FC<IPasteDecision> = (props: IPasteDecision) => {
  const { getDecisioningList } = props;
  const { productsObject } = useSelector((state: RootState) => state.common);
  const [loading, setLoading] = useState(false);

  const addDecisioning = (payload: any) => {
    setLoading(true);
    API_SERVICE.addDecisioning(payload)
      .then(({ data }) => updateDecisioning(data?.payload?.id, payload))
      .catch((e: any) => {
        setLoading(false);
        API_SERVICE.handleErrors(e)
      });
  };

  const updateDecisioning = (id: any, payload: any) => {
    API_SERVICE.updateDecisioningProfile(id, payload)
      .then(() => getDecisioningList())
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  const onSubmit = async () => {
    const errorMsg = "Please click on Copy icon for chosen Decision Name to enable Paste Decisioning action";
    try {
      const data = await navigator.clipboard.readText();
      const values = JSON.parse(data);
      if((values?.name?.length ?? 0) == 0){
        API_SERVICE.handleErrors(errorMsg);
        return;
      }
      const payload = {
        decisionType: values?.decisionType,
        description: values?.description,
        masterProductId: productsObject?.productsId,
        name: values?.name,
        purpose: values?.purpose,
        lenderBusinessId: values?.lenderBusinessId,
        currentPayload: values?.currentPayload,
      };
      addDecisioning(payload);
    } catch (e: any) {
      API_SERVICE.handleErrors(errorMsg);
    }
  };

  return (
    <>
    <Button
      type="primary"
      style={{ height: "40px", marginLeft: "10px" }}
      onClick={() => onSubmit()}
      loading={loading}
    >
      Paste Decisioning
    </Button>
    {/* {loading && <AppLoading />} */}
    </>
  );
};

export default PasteDecision;
