import React, { useEffect } from "react";
import { Form, Row, Space } from 'antd';
import FormInput from '../../../../shared/component/FormInput/FormInput';
import FormButton from '../../../../shared/component/FormButton/FormButton';
import FormTextArea from '../../../../shared/component/FormTextArea/FormTextArea';
import "../../properties.scss"

interface IAddBasicInfo {
    setSelectedStage: Function;
    setFormData1: any;
    formData1: any;
    setAddPropery: any;
}
const AddBasicInfo: React.FC<IAddBasicInfo> = (props: IAddBasicInfo) => {
    const { setSelectedStage, setFormData1, formData1, setAddPropery } = props

    const [basicInfoForm] = Form.useForm() as any;

    useEffect(() => {
        basicInfoForm.setFieldsValue({
            labelInput: formData1?.labelInput,
            description: formData1?.description,
        });
    }, [])

    const onNextClick = (record: any) => {
        setSelectedStage(2)
        setFormData1(record)
    }

    return (
        <Form
            form={basicInfoForm}
            layout="vertical"
            onFinish={onNextClick}>

            <FormInput
                label="Label"
                classNameForm="space-input"
                isBorder={true}
                name="labelInput"
                placeholder="Enter Label"
                requiredLabel="label"
                isRequired
            />
            <FormTextArea
                label="Description"
                classNameForm="space-input"
                isBorder={true}
                name="description"
                placeholder="Enter Description"
            />
            <Space className='step-button'>
                <FormButton
                    label="Cancel"
                    onclick={() => {
                        setAddPropery(false);
                    }}
                />
                <FormButton
                    size="large"
                    label="Next"
                    type="primary"
                    htmlType="submit"
                />
            </Space>
        </Form>
    );
};

export default AddBasicInfo;