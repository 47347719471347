import { useEffect, useState } from "react";
import { Tabs, notification } from "antd";
import ScoreCard from "./ScoreCard";
import FormButton from "../../../shared/component/FormButton/FormButton";
import "./scoreCard.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
import AddAction from "./AddAction";
import AddScoreCard from "./AddScoreCard";
import PublishHistory from "./PublishHistory";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import PublishComment from "./PublishComment";
import TestDecision from "./TestDecision";
import Publish from "./Publish";
import { useDispatch } from "react-redux";
import {
  setScoreCardPageNo,
  setScoreCardSearchParam,
} from "../../../shared/redux/scoreCard-reducer";
import { setPublishPageNo } from "../../../shared/redux/publish-reducer";
import { setPublishListPageNo } from "../../../shared/redux/publishList-reducer";
import { setDecisioningObject } from "../../../shared/redux/common-reducer";

interface IScoreCardTabs {
  decisionData: any;
  setShowScoreCardTabs: any;
}

const ScoreCardTabs: React.FC<IScoreCardTabs> = (props: IScoreCardTabs) => {
  const { decisionData, setShowScoreCardTabs } = props;
  const [showAddCondition, setShowAddCondition] = useState(false);
  const [isEditCondition, setIsEditCondition] = useState(false);
  const [editConditionData, setEditConditionData] = useState({} as any);
  const [showAddAction, setShowAddAction] = useState(false);
  const [isEditAction, setIsEditAction] = useState(false);
  const [editActionData, setEditActionData] = useState({} as any);
  const [editActionIndex, setEditActionIndex] = useState(Number);
  const [currentPayloadData, setCurrentPayloadData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [openPublishComment, setOpenPublishComment] = useState(false);
  const [isAddRuleClicked, setIsAddRuleClicked] = useState(false);
  const [isCreateTable, setIsCreateTable] = useState(false);
  const [isAddRule, setIsAddRule] = useState(false);
  const [testDecision, setTestDecision] = useState(false);
  const [isButtonHide, setIsButtonHide] = useState(true);
  const [isShowTest, setIsShowTest] = useState(false);
  const [testRule, setTestRule] = useState([] as any);
  const [testTotalScore, setTestTotalScore] = useState(0);
  const [testedStatus, setTestedStatus] = useState("");
  const [isShowCancelBtn, setIsShowCancelBtn] = useState(false);
  const [isDataTested, setIsDataTested] = useState(true);
  const [currentPublishedId, setCurrentPublishedId] = useState("");
  const [eligibleLendersArr, setEligibleLendersArr] = useState([] as any);
  const [offerCalculationLenderOutputResponses, setOfferCalculationLenderOutputResponses] = useState([] as any);
  const [conditionEvaluationResults, setConditionEvaluationResults] = useState([] as any);
  const dispatch = useDispatch();

  useEffect(() => {
    listAllTheFinanciers();
    // getDraftList();
  }, []);

  const listAllTheFinanciers = () => {
    setIsLoading(true);
    API_SERVICE.listAllTheFinanciers()
      .then(({ data }) => {
        const displayNameList = data?.payload?.map((listVal: any) => {
          return {
            key: listVal?.businessInfoId,
            value: listVal?.businessName,
          };
        });
        setEligibleLendersArr(displayNameList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => {
        getDraftList();
      });
  };

  const onAddRule = () => {
    setIsShowCancelBtn(true);
    dispatch(setScoreCardPageNo(1));
    dispatch(setScoreCardSearchParam(""));
    setIsShowTest(false);
    const ruleDataCheck = currentPayloadData?.ruleMetadata?.at(0);
    let actionCheck;
    ruleDataCheck?.decisioningActions?.map((item: any) => {
      if (item?.categoryInfoList?.length > 0) {
        actionCheck = item?.scoreOrReject != "";
      } else {
        actionCheck = item?.actionParamterValues != "";
      }
    });
    let conditionCheck;
    ruleDataCheck?.decisioningConditions?.map((item: any) => {
      conditionCheck = item?.conditionParamterValues != "";
    });
    let ruleNameCheck = ruleDataCheck?.ruleName != "";

    if (
      decisionData?.decisionType == "OFFER_DECISION" &&
      (currentPayloadData?.ruleMetadata?.length ?? 0) > 0
    ) {
      const rulesTmp = ruleDataCheck?.ruleName?.split("-");
      ruleNameCheck =
        (rulesTmp?.at(0)?.length ?? 0) > 0 &&
        (rulesTmp?.at(-1)?.length ?? 0) > 0 &&
        rulesTmp?.at(0) != "undefined" &&
        rulesTmp?.at(-1) != "undefined";
    }

    if (!ruleNameCheck && !actionCheck && !conditionCheck) {
      notification.error({ message: "Please enter proper rule name" });
      return;
    }

    let decisioningConditions: any = [];
    currentPayloadData?.conditionMetadata?.map((item: any) => {
      decisioningConditions.push({
        conditionId: item?.conditionId,
        conditionParamterValues: "",
      });
    });
    let decisioningActions: any = [];
    currentPayloadData?.actionMetadata?.map((item: any) => {
      decisioningActions.push({
        actionId: item?.actionId,
        actionParamterValues: "",
        categoryId: (item?.categoryInfoList?.length ?? 0) > 0 ? "" : null,
        scoreOrReject: "",
      });
    });
    let newRule = {
      ruleName: "",
      decisioningConditions: decisioningConditions,
      decisioningActions: decisioningActions,
    };
    const ruleMetadata = currentPayloadData?.ruleMetadata ?? [];
    ruleMetadata?.unshift(newRule);

    const currentPayload = currentPayloadData;
    currentPayload.ruleMetadata = ruleMetadata;
    setCurrentPayloadData({ ...currentPayload });
    setIsCreateTable(true);
    setIsAddRuleClicked(true);
    setIsAddRule(true);
  };

  const backDecisioning = () => {
    setShowScoreCardTabs(false);
    dispatch(setScoreCardPageNo(1));
    dispatch(setPublishPageNo(1));
    dispatch(setPublishListPageNo(1));
    dispatch(setDecisioningObject({}));
  };

  const addButtons = (
    <div className="add-btns">
      <a
        style={{
          textDecoration: "underline",
          color: "#1677ff",
          padding: "8px",
          fontSize: "medium",
        }}
        onClick={backDecisioning}
      >
        Back To Decisioning
      </a>
      {isButtonHide && (
        <>
          <FormButton
            label="Add Condition"
            type="primary"
            disabled={decisionData?.decisionType == "OFFER_DECISION"}
            onclick={() => {
              if (isAddRule) {
                notification.error({ message: "Please complete Rule" });
              } else {
                setIsShowTest(false);
                setEditConditionData(null);
                setShowAddCondition(true);
                setIsEditCondition(false);
              }
            }}
          />
          <FormButton
            label="Add Action"
            type="primary"
            disabled={
              decisionData?.decisionType == "OFFER_DECISION" ||
              !(currentPayloadData?.conditionMetadata?.length > 0)
            }
            onclick={() => {
              if (isAddRule) {
                notification.error({ message: "Please complete Rule" });
              } else {
                setIsShowTest(false);
                setEditActionData(null);
                setShowAddAction(true);
                setIsEditAction(false);
              }
            }}
          />
          <FormButton
            label="Add Rule"
            type="primary"
            disabled={!(currentPayloadData?.actionMetadata?.length > 0)}
            onclick={onAddRule}
          />
        </>
      )}
    </div>
  );

  const getDraftList = () => {
    setIsLoading(true);
    API_SERVICE.editDecisioningData(decisionData.id)
      .then(({ data }) => {
        setCurrentPublishedId(data?.payload?.currentPublishedId);
        if (!(data?.payload?.currentPayload?.conditionMetadata?.length > 0)) {
          setShowAddCondition(true);
        }
        let currentPayload = data?.payload?.currentPayload ?? {};

        const ruleData = currentPayload?.ruleMetadata?.map((rule: any) => {
          currentPayload?.conditionMetadata?.map((itemMeta: any) => {
            let decCondition = rule?.decisioningConditions?.map(
              (dec: any) => dec?.conditionId
            );

            if (!decCondition?.includes(itemMeta?.conditionId)) {
              rule?.decisioningConditions?.push({
                conditionId: itemMeta?.conditionId,
                conditionParamterValues: "",
              });
            }
          });

          currentPayload?.actionMetadata?.map((itemMeta: any) => {
            let decCondition = rule?.decisioningActions?.map(
              (ac: any) => ac?.actionId
            );

            if (!decCondition?.includes(itemMeta?.actionId)) {
              rule?.decisioningActions?.push({
                actionId: itemMeta?.actionId,
                actionParamterValues: "",
                categoryId: itemMeta?.categoryInfoList?.length > 0 ? "0" : null,
                scoreOrReject: "",
              });
            }
          });

          return rule;
        });

        currentPayload = {
          ...currentPayload,
          ruleMetadata: ruleData,
        };

        setCurrentPayloadData(currentPayload);
        setIsDataTested(data?.payload?.tested ?? false);
        setTimeout(() => {
          setIsCreateTable(true);
        }, 500);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  const onChangeTab = (key: any) => {
    setIsButtonHide(key === "1");
  };

  const renderTabView = () => {
    return (
      <Tabs
        tabBarExtraContent={addButtons}
        className="scorecard-tabs"
        defaultActiveKey="1"
        type="card"
        onChange={onChangeTab}
      >
        <Tabs.TabPane key="1" tab={<span className="tabActive">Drafts</span>}>
          <ScoreCard
            decisionData={decisionData}
            currentPayloadData={currentPayloadData}
            setCurrentPayloadData={setCurrentPayloadData}
            setOpenPublishComment={setOpenPublishComment}
            setTestDecision={setTestDecision}
            isCreateTable={isCreateTable}
            setIsCreateTable={setIsCreateTable}
            setIsAddRuleClicked={setIsAddRuleClicked}
            setIsAddRule={setIsAddRule}
            isAddRule={isAddRule}
            isAddRuleClicked={isAddRuleClicked}
            getDraftList={getDraftList}
            setShowAddAction={setShowAddAction}
            setShowAddCondition={setShowAddCondition}
            setIsEditAction={setIsEditAction}
            setIsEditCondition={setIsEditCondition}
            setEditActionData={setEditActionData}
            setEditConditionData={setEditConditionData}
            isShowTest={isShowTest}
            testRule={testRule}
            testTotalScore={testTotalScore}
            testedStatus={testedStatus}
            setEditActionIndex={setEditActionIndex}
            setIsShowTest={setIsShowTest}
            setIsShowCancelBtn={setIsShowCancelBtn}
            isShowCancelBtn={isShowCancelBtn}
            isDataTested={isDataTested}
            eligibleLendersArr={eligibleLendersArr}
            offerCalculationLenderOutputResponses={offerCalculationLenderOutputResponses}
            conditionEvaluationResults={conditionEvaluationResults}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={<span className="tabActive">Publish</span>}>
          <Publish
            currentPublishedId={currentPublishedId}
            eligibleLendersArr={eligibleLendersArr}
            decisionData={decisionData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="3"
          tab={<span className="tabActive">Publish History</span>}
        >
          <PublishHistory decisionData={decisionData} />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "20px" }}>
      {showAddCondition ? (
        <AddScoreCard
          setShowAddCondition={setShowAddCondition}
          decisionData={decisionData}
          getDraftList={getDraftList}
          currentPayloadData={currentPayloadData}
          isEditCondition={isEditCondition}
          editConditionData={editConditionData}
        />
      ) : showAddAction ? (
        <AddAction
          decisioningType={decisionData?.decisionType}
          setShowAddAction={setShowAddAction}
          getDraftList={getDraftList}
          decisionData={decisionData}
          currentPayloadData={currentPayloadData}
          isEditAction={isEditAction}
          editActionData={editActionData}
          editActionIndex={editActionIndex}
        />
      ) : openPublishComment ? (
        <PublishComment
          setOpenPublishComment={setOpenPublishComment}
          decisionData={decisionData}
          getDraftList={getDraftList}
        />
      ) : testDecision ? (
        <TestDecision
          setTestDecision={setTestDecision}
          setIsShowTest={setIsShowTest}
          setTestRule={setTestRule}
          setTestTotalScore={setTestTotalScore}
          setTestedStatus={setTestedStatus}
          decisionData={decisionData}
          getDraftList={getDraftList}
          setOfferCalculationLenderOutputResponses={setOfferCalculationLenderOutputResponses}
          setConditionEvaluationResults={setConditionEvaluationResults}
        />
      ) : (
        renderTabView()
      )}
      {isLoading && <AppLoading />}
    </div>
  );
};

export default ScoreCardTabs;
