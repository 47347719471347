import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const productDocumentSlice = createSlice({
  name: "ProductDocumentReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setProductDocumentPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setProductDocumentPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setProductDocumentSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    },
  },
});

export const {
  setProductDocumentPageNo,
  setProductDocumentPageSize,
  setProductDocumentSearchParam,
} = productDocumentSlice.actions;
export default productDocumentSlice.reducer;
