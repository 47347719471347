import { Divider, Drawer, Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";

const { Title } = Typography;

type IAddCategory = {
  openAdd: any;
  setOpenAdd: any;
  isEdit: any;
  editRecord: any;
  getDocumentCategorys: any;
};

const AddCategory: React.FunctionComponent<IAddCategory> = (
  props: IAddCategory
) => {
  const { openAdd, setOpenAdd, isEdit, editRecord, getDocumentCategorys } =
    props;
  const category: any = useSelector((state: RootState) => state.category);

  const [categoryForm] = Form.useForm() as any;

  useEffect(() => {
    if (isEdit) {
      categoryForm.setFieldsValue({
        categoryName: editRecord?.name,
      });
    }
  }, []);

  const createDocumentCategory = (payload: any) => {
    API_SERVICE.createDocumentCategory(payload)
      .then((data) => {
        setOpenAdd(false);
        getDocumentCategorys(
          category.pageNo,
          category.pageSize,
          category.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateDocumentCategorys = (payload: any) => {
    API_SERVICE.updateDocumentCategorys(payload)
      .then((data) => {
        setOpenAdd(false);
        getDocumentCategorys(
          category.pageNo,
          category.pageSize,
          category.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const onFinishClick = (value: any) => {
    const createpayload = {
      name: value.categoryName,
    };

    if (isEdit) {
      const updatepayload = {
        ...createpayload,
        id: editRecord.id,
      };
      updateDocumentCategorys(updatepayload);
    } else {
      createDocumentCategory(createpayload);
    }
  };

  return (
    <>
      <Title level={5} style={{ paddingTop: "17px" }}>
        {isEdit ? "EDIT CATEGORY" : "ADD NEW CATEGORY"}
      </Title>
      <Divider />
      <Form
        form={categoryForm}
        layout="vertical"
        onFinish={onFinishClick}
        style={{ maxWidth: "400px" }}
      >
        <FormInput
          label="Category Name"
          classNameForm="space-input"
          isBorder={true}
          name="categoryName"
          placeholder="Enter category name"
          requiredLabel="Category name"
          isRequired
        />

        {/* <FormInput
          label="Order"
          classNameForm="space-input"
          isBorder={true}
          name="order"
          type="number"
          placeholder="Enter sort order"
          requiredLabel="order"
          isRequired
        /> */}
        <div className="step-button gap-20">
          <FormButton
            size="large"
            label="Save"
            type="primary"
            htmlType="submit"
          />
          <FormButton
            size="large"
            label={"Cancel"}
            type="default"
            onclick={() => {
              setOpenAdd(false);
            }}
          />
        </div>
      </Form>
    </>
  );
};

export default AddCategory;
