import { Button, Col, Form, Input, Row, Switch, Table } from "antd";
import React, { useState } from "react";
import search from "../../assets/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../shared/redux/store";
import { setCouponsPageNo, setCouponsPageSize } from "../../shared/redux/coupons-reducer";
import AddCoupons from "./AddCoupons";
import { ColumnsType } from "antd/es/table";
import FormIconButton from "../../shared/component/FormIconButton/FormIconButton";

interface columns {
    key: React.ReactNode;
    code: any;
    purchasePrice: any;
    discountApplicable: any;
    maxDiscountApplicable: any;
    startDate: any;
    endDate: any;
    reusable: any;
}

type ICoupons = {};

const Coupons: React.FunctionComponent<ICoupons> = () => {

    const [loading, setLoading] = useState(false);
    const [openCouponsDrawer, setOpenCouponsDrawer] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [editCouponsData, setEditCouponsData] = useState(null as any);
    // const [couponsList, setCouponsList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const coupons: any = useSelector((state: RootState) => state.coupons);
    const dispatch = useDispatch();

    const actionEdit = () => {
        setOpenCouponsDrawer(true)
        setIsEdit(true)
    }

    const columns: ColumnsType<columns> = [
        {
            title: 'Code',
            dataIndex: "code",
            key: 'code',
            render: (text) => <span style={{ color: "#4285F4" }}>{text}</span>,
        },
        {
            title: 'Purchase Price',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
            align: "center",
        },
        {
            title: "Discount Applicable",
            dataIndex: "discountApplicable",
            key: "discountApplicable",
            align: "center",
        },
        {
            title: "Max Discount Applicable",
            dataIndex: "maxDiscountApplicable",
            key: "maxDiscountApplicable",
            align: "center",
        },
        {
            title: 'Start Date',
            dataIndex: "startDate",
            key: 'startDate',
            align: "center",
        },
        {
            title: 'End Date',
            dataIndex: "endDate",
            key: 'endDate',
            align: "center",
        },
        {
            title: 'Reusable',
            dataIndex: "reusable",
            key: 'reusable',
            align: "center",
        },
        {
            title: "Status",
            key: "status",
            render: (_: any, record: any) => (
                <Switch
                    checked={record.active}
                // disabled={}
                // onChange={(event) => onChangeStatus(event, record)}
                />
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "center",
            fixed: "right",
            render: (_: any, record: any) => (
                <FormIconButton
                    type={"edit"}
                    onClick={() => actionEdit()}
                />
            ),
        },
    ];

    const dataSource = [
        {
            key: 1,
            code: "dfhgd",
            purchasePrice: 12,
            discountApplicable: "100%",
            maxDiscountApplicable: 550,
            startDate: "Aug 1,2023",
            endDate: "Aug 20,2023",
            reusable: 2,
        },
        {
            key: 1,
            code: "dfhgd",
            purchasePrice: 12,
            discountApplicable: "100%",
            maxDiscountApplicable: 550,
            startDate: "Aug 1,2023",
            endDate: "Aug 20,2023",
            reusable: 2,
        },
    ]

    const handlePaginationChange = (current: any, pageSize: any) => {
        dispatch(setCouponsPageNo(current));
        dispatch(setCouponsPageSize(pageSize));
        // getCouponsList(current, pageSize, coupons.searchParam);
    };

    return (
        <>
            <div className={"layout-main-top"} style={{ display: "flex", justifyContent: "space-between" }}>
                <Form layout="inline"
                // onFinish={handleSearch}
                >
                    <Form.Item name='searchParam'>
                        <Input
                            size="large"
                            className="search-input"
                            prefix={<img src={search} alt=""/>}
                            placeholder="Search"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" style={{ height: '40px' }} htmlType="submit">Search</Button>
                    </Form.Item>
                </Form>
                <div>
                    <Button onClick={() => {
                        setOpenCouponsDrawer(true);
                        setEditCouponsData({});
                        setIsEdit(false);
                    }} type="primary" style={{ height: '40px' }} >Add New Coupon</Button>
                </div>
            </div>

            <div className={"layout-main"} >
                <Row style={{ padding: '15px' }}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            size='middle'
                            loading={loading}
                            onChange={(pagination: any) => {
                                handlePaginationChange(pagination.current, pagination.pageSize);
                            }}
                            pagination={{
                                position: ['bottomCenter'],
                                current: coupons.pageNo,
                                pageSize: coupons.pageSize,
                                total: totalCount
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </div>
            {
                openCouponsDrawer &&
                <AddCoupons
                    openCouponsDrawer={openCouponsDrawer}
                    setOpenCouponsDrawer={setOpenCouponsDrawer}
                    // getCouponsList={getCouponsList}
                    editCouponsData={editCouponsData}
                    isEdit={isEdit}
                />
            }
        </>
    )
};

export default Coupons;