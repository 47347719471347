import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const decisioningSlice = createSlice({
    name: "DecisioningReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null, masterProductFilter: null },
    reducers: {
        setDecisioningtPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setDecisioningPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setDecisioningSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        },
        setDecisioningMasterProductFilter: (state, action: PayloadAction<any>) => {
            state.masterProductFilter = action.payload;
        }
    }
});

export const { setDecisioningtPageNo, setDecisioningPageSize, setDecisioningSearchParam,setDecisioningMasterProductFilter } = decisioningSlice.actions;
export default decisioningSlice.reducer;