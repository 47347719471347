import { Divider, Form, Space, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
// import "./scoreCard.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
import { viewTypeJourneyProduct } from "../ProductJourneyV2/Journey";
import { setJourneyObject } from "../../../shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
const { Title } = Typography;

interface IPublishComment {
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
  productWorkflowId: any;
  fetchMasterProductList: any;
}

const JourneyPublishComment: React.FunctionComponent<IPublishComment> = (
  props: IPublishComment
) => {
  const { setViewTypeJourney, productWorkflowId, fetchMasterProductList } =
    props;
  const [publishCommentForm] = Form.useForm() as any;
  const dispatch = useDispatch();
  const journey: any = useSelector((state: RootState) => state.journey);

  const onPublish = (value: any) => {
    const payload = {
      publishingComment: value?.commentPublish,
    };
    API_SERVICE.publishProductWorkflow(productWorkflowId, payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setViewTypeJourney("journey");
        dispatch(setJourneyObject({}));
        fetchMasterProductList(
          journey?.pageNo,
          journey?.pageSize,
          journey.searchParam
        );
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  return (
    <>
      <div className={"layout-main"} style={{ marginTop: "20px" }}>
        <Title level={3} className="condition-title">
          Publish Comment
        </Title>
        <Divider style={{ margin: "0" }} />
        <div className="decisioning-form">
          <Form
            form={publishCommentForm}
            layout="vertical"
            onFinish={onPublish}
          >
            <FormTextArea
              label="Comment"
              classNameForm="space-input"
              isBorder={true}
              row={4}
              name="commentPublish"
              placeholder="Publish Comment..."
              isRequired
            />

            <Space style={{ marginTop: "20px" }}>
              <FormButton
                size="large"
                label="Save"
                type="primary"
                htmlType="submit"
              />
              <FormButton
                size="large"
                label="Cancel"
                onclick={() => {
                  setViewTypeJourney("journey");
                  dispatch(setJourneyObject({}));
                  fetchMasterProductList(
                    journey?.pageNo,
                    journey?.pageSize,
                    journey.searchParam
                  );
                }}
              />
            </Space>
          </Form>
        </div>
      </div>
    </>
  );
};

export default JourneyPublishComment;
