import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import search from "../../../assets/images/search.svg";
import type { ColumnsType } from "antd/es/table";
import AddCategory from "./AddCategory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setCategoryPageNo,
  setCategoryPageSize,
  setCategorySearchParam,
} from "../../../shared/redux/category-reducer";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { API_SERVICE } from "../../../shared/services/api-services";

interface IDataType {
  key: React.Key;
  name: string;
  order: number;
}

type IManageCategory = {};

const ManageCategory: React.FunctionComponent<IManageCategory> = () => {
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [manageCategory, setManageCategory] = useState([] as any);
  const [editRecord, setEditRecord] = useState(null as any);
  const dispatch = useDispatch();

  const category: any = useSelector((state: RootState) => state.category);

  useEffect(() => {
    getDocumentCategorys();
  }, []);

  const getDocumentCategorys = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParameter: searchParam,
    };
    setLoading(true);
    API_SERVICE.getDocumentCategorys(params)
      .then((data) => {
        setManageCategory(data?.data?.payload?.content ?? []);
        setTotalCount(data?.data?.payload?.totalElements);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const switchChange = (e: any, record: any) => {
    const status = record.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    API_SERVICE.updateStatusOfDocumentCategory(record.id, status)
      .then((data) => {
        getDocumentCategorys(
          category.pageNo,
          category.pageSize,
          category.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const columns: ColumnsType<IDataType> = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      render: (_: number, record: any, index: number) => (
        <>{(category.pageNo - 1) * category.pageSize + index + 1}</>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span style={{ color: "#4285F4" }}>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: number, record: any) => (
        <Switch
          checked={record.status === "ACTIVE"}
          onClick={(e) => switchChange(e, record)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <FormIconButton
            type={"edit"}
            onClick={() => {
              setIsEdit(true);
              setOpenAdd(true);
              setEditRecord(record);
            }}
          />

          {/* <FormIconButton
            type={"delete"}
            onClick={() => {
              setDeleteCategory(true);
            }}
          /> */}
        </>
      ),
    },
    // {
    //   title: "Change Sequence",
    //   dataIndex: "changeSequence",
    //   key: "changeSequence",
    //   render: (_: any, record: any) => (
    //     <>
    //       <FormIconButton type={"up"} onClick={() => { }} />
    //       <FormIconButton type={"down"} onClick={() => { }} />
    //     </>
    //   ),
    // },
  ];

  const renderDeleteVerify = () => {
    return (
      <Modal
        className="categoryModal"
        open={deleteCategory}
        closable={false}
        footer={null}
        width={300}
      >
        <>
          <div style={{ textAlign: "center" }}>
            <div>Are you sure you want to delete this category?</div>
            <Space style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                size="large"
                onClick={() => setDeleteCategory(false)}
              >
                Yes
              </Button>
              <Button size="large" onClick={() => setDeleteCategory(false)}>
                No
              </Button>
            </Space>
          </div>
        </>
      </Modal>
    );
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setCategoryPageNo(current));
    dispatch(setCategoryPageSize(pageSize));
    getDocumentCategorys(current, pageSize);
  };

  const handleSearchParam = (e: any) => {
    dispatch(setCategoryPageNo(1));
    dispatch(setCategorySearchParam(e.searchParam));
    getDocumentCategorys(1, category.pageSize, e.searchParam);
  };

  const renderView = () => {
    return (
      <>
        {deleteCategory && renderDeleteVerify()}

        <div
          className="layout-main-top"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearchParam}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>

          <Button
            type="primary"
            style={{ height: "40px" }}
            htmlType="submit"
            onClick={() => {
              setIsEdit(false);
              setOpenAdd(true);
              setEditRecord({});
            }}
          >
            Create New Category
          </Button>
        </div>

        <div className={"layout-main"}>
          <Row style={{ padding: "15px" }}>
            <Col xs={24} xl={13} span={13}>
              <Table
                columns={columns}
                dataSource={manageCategory}
                loading={loading}
                onChange={(pagination: any) => {
                  handlePaginationChange(
                    pagination.current,
                    pagination.pageSize
                  );
                }}
                pagination={{
                  position: ["bottomCenter"],
                  current: category.pageNo,
                  pageSize: category.pageSize,
                  total: totalCount,
                }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <>
      {openAdd ? (
        <div
          className={"layout-main"}
          style={{
            minHeight: "70px",
            marginTop: "20px",
            paddingInline: "30px",
          }}
        >
          <AddCategory
            openAdd={openAdd}
            setOpenAdd={setOpenAdd}
            isEdit={isEdit}
            editRecord={editRecord}
            getDocumentCategorys={getDocumentCategorys}
          />
        </div>
      ) : (
        renderView()
      )}
    </>
  );
};

export default ManageCategory;
