import { Divider, Form, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
// @ts-ignore
import { Form as Formio } from "react-formio";
import FormInput from "../../../shared/component/FormInput/FormInput";
import "./scoreCard.scss";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";

const { Title } = Typography;

interface ITestDecision {
  setTestDecision: any;
  decisionData: any;
  getDraftList: any;
  setIsShowTest: any;
  setTestRule: any;
  setTestTotalScore: any;
  setTestedStatus: any;
  setOfferCalculationLenderOutputResponses: any;
  setConditionEvaluationResults: any;
}

const TestDecision: React.FunctionComponent<ITestDecision> = (
  props: ITestDecision
) => {
  const {
    setTestDecision,
    decisionData,
    getDraftList,
    setIsShowTest,
    setTestRule,
    setTestTotalScore,
    setTestedStatus,
    setOfferCalculationLenderOutputResponses,
    setConditionEvaluationResults,
  } = props;
  const [publishCommentForm] = Form.useForm() as any;
  // const [testDecisionArray, setTestDecisionArray] = useState([] as any);
  const [jsonSchema, setJsonSchema] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // publishCommentForm.setFieldsValue({
    //   leverage: 1.6,
    //   tenure: 30,
    //   noOfUnsecuredLoans: 2,
    //   auditedGst: 50000000,
    //   dipInPat: 0,
    //   creditTransactionFor6Months: 6,
    //   termDebtByEbdita: 4.6,
    //   noOfCreditTransaction: 0,
    //   abb: 160000,
    //   debtorDays: 87,
    //   totalLiabilityByNetWorth: 2.4,
    //   netProfit: 0,
    //   pbt: 0,
    //   age: 25,
    //   creditAmount: 0,
    //   dipInGst: 0,
    //   netWorth: 3500000,
    //   turnoverInCrores: 3,
    //   noOfCashProfits: 0,
    //   cashProfitChange: 1,
    //   vintage: 6,
    //   loanAmount: 2500000,
    //   marginalAuditedGstByObligations: 0,
    //   turnoverByCashProfits: 1,
    //   dscr: 2,
    //   cibil: 700,
    //   abbTimes: 12,
    //   noOfEnquiries: 7,
    //   pat: 0,
    //   dipInTo: 15,
    //   toByGst: 0,
    // });

    setTestDecision(true);
    generateDecisioning();
  }, []);

  // const renderTestDecisionFiels = (
  //   label: string,
  //   key: string,
  //   type: any,
  //   components: any
  // ) => {
  //   return (
  //     <>
  //       <FormInput
  //         label={label}
  //         name={key}
  //         classNameForm="space-input"
  //         isBorder={true}
  //         requiredLabel={label}
  //         type={type}
  //         isRequired
  //       />
  //       {(components?.length ?? 0) > 0 &&
  //         components?.map((comItem: any) => {
  //           return (
  //             <div style={{ marginLeft: "15px" }}>
  //               {renderTestDecisionFiels(
  //                 comItem.label,
  //                 comItem.key,
  //                 comItem.type,
  //                 comItem?.components
  //               )}
  //             </div>
  //           );
  //         })}
  //     </>
  //   );
  // };

  const generateDecisioning = () => {
    let payload = {
      decisioningMasterId: decisionData.id,
    };
    API_SERVICE.generateFormTemplate(payload)
      .then(({ data }) => {
        setJsonSchema(data?.payload?.form?.definition ?? {});
        // let formData = data?.data?.payload?.form?.definition?.components;
        // setTestDecisionArray(formData);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const getTestDecision = (value: any) => {
    setIsLoading(true);
    let param: any = {
      data: value?.data,
      decisioningMasterId: decisionData.id,
      executionType: "TEST",
    };
    if (decisionData?.decisionType == "STAGE_CONFIG") {
      param["maxRuleMatches"] = 1;
    }
    API_SERVICE.testDecisionMaster(param)
      .then(({ data }) => {
        getDraftList();
        setTestDecision(false);
        setTestRule(data?.payload?.ruleNames ?? []);
        setConditionEvaluationResults(
          data?.payload?.conditionEvaluationResults ?? []
        );
        setTestTotalScore(
          data?.payload?.scorecardOutputResponse?.summingScore?.totalScore ?? 0
        );
        setTestedStatus(
          data?.payload?.scorecardOutputResponse?.summingScore?.status ?? ""
        );
        setIsShowTest(true);
        setOfferCalculationLenderOutputResponses(
          data?.payload?.offerCalculationLenderOutputResponses ?? []
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={"layout-main"} style={{ marginTop: "20px" }}>
        <Title level={3} className="condition-title">
          Test Required data
        </Title>
        <Divider style={{ margin: "0" }} />
        <div style={{ padding: "20px" }}>
          {/* <Form
            form={publishCommentForm}
            layout="vertical"
            onFinish={getTestDecision}
          >
            {testDecisionArray.map((item: any) => {
              return renderTestDecisionFiels(
                item.label,
                item.key,
                item.type,
                item?.components
              );
            })} */}
          <Formio
            key={"testDecisioningForm"}
            form={jsonSchema}
            jsonSchema={jsonSchema}
            onSubmit={getTestDecision}
            onCustomEvent={() => setTestDecision(false)}
          />
          {/* <Space style={{ marginTop: "20px" }}>
            <FormButton
              size="large"
              label="Test"
              type="primary"
              htmlType="submit"
            />
            <FormButton
              size="large"
              label="Cancel"
              onclick={() => setTestDecision(false)}
            />
          </Space> */}
          {/* </Form> */}
        </div>
        {isLoading && <AppLoading />}
      </div>
    </>
  );
};

export default TestDecision;
