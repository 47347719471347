import React, { useEffect, useState } from "react";
import { Button, Form, Input, Switch, Table } from "antd";
import removeIcone from "../../../assets/images/removeIcon.svg";
import rightIcon from "../../../assets/images/rightIcon.svg";
import search from "../../../assets/images/search.svg";
import {
  setDocumentPageSize,
  setDocumentSearchParam,
  setDoumentPageNo,
} from "../../../shared/redux/document-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import FormButton from "../../../shared/component/FormButton/FormButton";
import AddDocumentScreen from "./AddDocumentScreen";
import { API_SERVICE } from "../../../shared/services/api-services";
import { viewTypeProduct } from "../Products/Products";
import { setProductsObject, setScreenTitle } from "../../../shared/redux/common-reducer";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";

interface IDocumentScreen {
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  getProductList: any;
}

const DocumentScreen: React.FC<IDocumentScreen> = (props: IDocumentScreen) => {
  const { setViewType, getProductList } = props;

  const [addDocumentScreenOpen, setAddDocumentScreenOpen] = useState(false);
  const [docList, setDocList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const document: any = useSelector((state: RootState) => state.document);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const product: any = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();

  const productDocument: any = useSelector(
    (state: RootState) => state.productDocument
  );

  const dataSource: any = [
    {
      key: "0",
      category: "Financial Document",
      document: "Bank Statement",
      rules: "Max Size:6MB",
      requried: <img src={removeIcone} alt="" />,
    },
    {
      key: "1",
      category: "Address Proof",
      document: "Aadhar Card",
      rules: "Max Size:1MB",
      requried: <img src={rightIcon} alt="" />,
    },
  ];

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    setLoading(true);

    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParameter: searchParam,
    };

    API_SERVICE.getDocumentProducts(productsObject.productsId, params)
      .then((data) => {
        setDocList(data?.data?.payload?.content ?? []);
        setTotalCount(data?.data?.payload?.totalElements);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDoumentPageNo(current));
    dispatch(setDocumentPageSize(pageSize));
    getDocumentList(current, pageSize, document.searchParam);
  };

  const handleSearch = (value: any) => {
    dispatch(setDoumentPageNo(1));
    dispatch(setDocumentSearchParam(value?.searchParam));
    getDocumentList(1, document.pageSize, value?.searchParam);
  };

  const columns: any = [
    {
      title: "Document",
      dataIndex: ["documentTypeResponse", "name"],
      key: "name",
    },
    {
      title: "Category",
      dataIndex: ["documentTypeResponse", "documentCategoryResponseList"],
      key: "documentCategoryResponseList",
      render: (_: any, record: any) => (
        <>
          {record?.documentTypeResponse?.documentCategoryResponseList
            ?.map((item: any) => item.name)
            .join(", ")}
        </>
      ),
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      render: (value: any, record: any) => (
        <Switch checked={value} onClick={(e) => switchChange(e, record)} />
      ),
    },
  ];

  const switchChange = (e: any, record: any) => {
    const params = {
      mandatory: !record?.mandatory,
      productDocumentMapId: record?.mappingId,
    };
    setLoading(true);
    API_SERVICE.updateDocumentProduct(params)
      .then((data) => {
        getDocumentList(
          document.pageNo,
          document.pageSize,
          document.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const renderDocScreenTable = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearch}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <FormButton
              label="Search"
              htmlType="submit"
              style={{ height: "40px" }}
              type="primary"
            />
          </Form>
          <div>
            <Button
              type="link"
              onClick={() => {
                setViewType("product");
                dispatch(setScreenTitle("Products"));
                dispatch(setProductsObject({}));
                getProductList(product?.pageNo, product?.pageSize, product.searchParam);
              }}
            >
              Back to product
            </Button>
            <Button
              type="primary"
              style={{ height: "40px" }}
              onClick={() => setAddDocumentScreenOpen(true)}
            >
              Add Document
            </Button>
          </div>
        </div>

        <div className={"layout-main"}>
          <Table
            rowClassName={() => "editable-row"}
            bordered
            dataSource={docList}
            columns={columns}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: document.pageNo,
              pageSize: document.pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {addDocumentScreenOpen ? (
        <div className={"layout-main"} style={{ marginTop: "20px" }}>
          <AddDocumentScreen
            setAddDocumentScreenOpen={setAddDocumentScreenOpen}
            getDocumentList={getDocumentList}
          />
        </div>
      ) : (
        renderDocScreenTable()
      )}
      {loading && <AppLoading />}
    </>
  );
};

export default DocumentScreen;
