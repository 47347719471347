import React, { useEffect, useState } from "react";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import editIcon from "../../../assets/images/edit_white.svg";
import deleteIcon from "../../../assets/images/delete_gray.svg";
import search from "../../../assets/images/search.svg";
import FormButton from "../../../shared/component/FormButton/FormButton";
import DrawerFile from "./AddProperty/DrawerFile";
import NotificationMessage from "../../../shared/component/NotificationMessage/NotificationMessage";
import { API_SERVICE } from "../../../shared/services/api-services";
import { RootState } from "../../../shared/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { IoCopy } from "react-icons/io5";
import {
  setPropertyPageNo,
  setPropertyPageSize,
  setPropertySearchParam,
} from "../../../shared/redux/property-reducer";
import "../properties.scss";
import { setScreenTitle } from "../../../shared/redux/common-reducer";

interface DataType {
  key: React.ReactNode;
  name: string;
  object: string;
  createdBy: string;
  type: string;
  status: string;
}

interface IProperty {
  selectedObject: any;
  setEditPropertyData: any;
  setShowAddPropery: any;
}

const Property: React.FC<IProperty> = (props: IProperty) => {
  const { selectedObject, setEditPropertyData, setShowAddPropery } = props;

  const [propertyList, setPropertyList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const property: any = useSelector((state: RootState) => state.property);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenTitle('Properties'))
    getPropertyList(property.pageNo, property.pageSize, property.searchParam, selectedObject);
  }, [selectedObject]);

  const goToEdit = (record: any) => {
    API_SERVICE.editPropertyData(record.id)
      .then(({ data }: any) => {
        setEditPropertyData(data?.payload);
        setShowAddPropery(true);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePropertyDelete = (id: any) => {
    API_SERVICE.deleteProperty(id)
      .then(() => {
        getPropertyList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (record: any) => (

        <Button
          size="small"
          type="text"
          icon={<IoCopy />}
          onClick={() => navigator.clipboard.writeText(record)}
        />

      ),
    },
    {
      title: 'API Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
    },
    {
      title: "Object",
      dataIndex: ["parent", "displayName"],
      key: "object",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: "System Property",
      dataIndex: "systemProperty",
      key: "systemProperty",
      sorter: true,
      render: (_: any, record: any) => (
        <>
          {record.systemProperty ? "Yes" : "No"}
        </>
      )
    },
    {
      title: "Vas Property",
      dataIndex: "vasProperty",
      key: "vasProperty",
      sorter: true,
      render: (_: any, record: any) => (
        <>
          {record.vasProperty ? "Yes" : "No"}
        </>
      )
    },
    {
      title: "Created By",
      dataIndex: ["createdBy", "fullName"],
      key: "createdBy",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => (
        <>
          {record.vasProperty || record.vasProperty ?
            "" : <Space className="action-button" size="small">
              <Button
                style={{ borderRadius: "6px", background: "#1A3175" }}
                size="small"
                type="primary"
                icon={<img src={editIcon} alt="" />}
                onClick={() => goToEdit(record)}
              >
                Edit
              </Button>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
                className="remove"
                onConfirm={() => handlePropertyDelete(record?.id)}
              >
                <Button
                  className="deleteButton"
                  size="small"
                  icon={<img src={deleteIcon} alt="" />}
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          }</>
      ),
    },
  ];

  const getPropertyList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null,
    parentId: any = null,
    direction: any = null
  ) => {
    setLoading(true);
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      name: searchParam,
      parentId: parentId,
      direction: direction,
    };
    API_SERVICE.getPropertyList(params)
      .then(({ data }: any) => {
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map((property: any) => {
            property.key = property.id;
          });
        }
        setPropertyList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e: any) => {
    dispatch(setPropertyPageNo(1));
    dispatch(setPropertySearchParam(e.target.value));
    getPropertyList(1, property.pageSize, e.target.value);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setPropertyPageNo(current));
    dispatch(setPropertyPageSize(pageSize));
    getPropertyList(current, pageSize, property.searchParam);
  };

  return (
    <>
      <NotificationMessage />
      <div className="search-and-btn">
        <Input
          size="large"
          className="search-box"
          defaultValue={property?.searchParam ?? ""}
          prefix={<img src={search} alt="" />}
          placeholder="Search"
          onChange={debounce(handleSearch, 1000)}
          allowClear
        />
        <FormButton
          label="Add Property"
          type="primary"
          onclick={() => {
            setEditPropertyData({});
            setShowAddPropery(true);
          }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          dataSource={propertyList}
          loading={loading}
          onChange={(pagination: any) => {
            handlePaginationChange(pagination.current, pagination.pageSize);
          }}
          pagination={{
            position: ["bottomCenter"],
            current: property.pageNo,
            pageSize: property.pageSize,
            total: totalCount,
          }}
          expandable={{
            showExpandColumn: false,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </>
  );
};

export default Property;
