import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const journeySlice = createSlice({
    name: "JourneyReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setJourneyPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setJourneyPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setJourneySearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setJourneyPageNo, setJourneyPageSize, setJourneySearchParam } = journeySlice.actions;
export default journeySlice.reducer;