import React from "react";
import { Form, Button } from "antd";
import "./FormButton.scss";

interface IFormButton {
  label?: string;
  onclick?: React.MouseEventHandler<HTMLElement> | undefined;
  className?: string;
  htmlType?: "submit" | "button" | "reset" | undefined;
  disabled?: boolean;
  style?: any;
  size?: "large" | "middle" | "small"
  type?: "primary" | "default"
  icon?: React.ReactNode
  iconPositon?: "left" | "top";
  loading?: any;
}

const FormButton = (props: IFormButton) => {
  const { label,
    onclick,
    className,
    htmlType,
    disabled,
    style,
    size,
    type,
    icon,
    iconPositon,
    loading
  } = props;


  return (
    <Form.Item>
      <Button
        className={"btn-common " + className}
        htmlType={htmlType ?? "button"}
        style={style}
        type={type}
        onClick={onclick}
        size={size ?? "large"}
        disabled={disabled}
        loading={loading}
      >
        <>
          {iconPositon == "left" && <><span>{icon}</span>&nbsp;</>}
          {iconPositon == "top" && <><span>{icon}</span><br /></>}
          {!iconPositon && <>{icon}</>}
          {label}
        </>
      </Button>
    </Form.Item>
  );
};

export default FormButton;
