import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  notification,
} from "antd";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormInput from "../../../shared/component/FormInput/FormInput";
import closeIcon from "../../../assets/images/closeIcon.svg";
import searchIcon from "../../../assets/images/search.svg";
import { DownOutlined } from "@ant-design/icons";
import { GrCircleInformation } from "react-icons/gr";
import "./scoreCard.scss";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import { API_SERVICE } from "../../../shared/services/api-services";
import FormButton from "../../../shared/component/FormButton/FormButton";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { getEnumList } from "../../../shared/Utility/Utility";

const { Title } = Typography;

interface IAddScoreCard {
  setShowAddCondition: any;
  decisionData: any;
  currentPayloadData: any;
  getDraftList: any;
  isEditCondition: any;
  editConditionData: any;
}

const AddScoreCard: React.FunctionComponent<IAddScoreCard> = (
  props: IAddScoreCard
) => {
  const {
    setShowAddCondition,
    decisionData,
    currentPayloadData,
    getDraftList,
    isEditCondition,
    editConditionData,
  } = props;
  const [changeConditionType, setChangeConditionType] = useState("SIMPLE");
  const [isValidateBtn, setIsValidateBtn] = useState(true);
  const [isPropertyList, setIsPropertyList] = useState(false);
  const [isPropertyListModal, setIsPropertyListModal] = useState(false);
  const [propertiesIndex, setPropertiesIndex] = useState(Number);
  const [propertiesList, setPropertiesList] = useState([] as any);
  const [propertiesListFilter, setPropertiesListFilter] = useState([] as any);
  const [validationResponse, setValidationResponse] = useState({} as any);
  const [scoreCardForm] = Form.useForm() as any;

  useEffect(() => {
    getPropertiesList();
    if (
      isEditCondition &&
      (currentPayloadData?.conditionMetadata?.length ?? 0) > 0
    ) {
      setChangeConditionType(editConditionData?.conditionType?.toUpperCase());
      let setFields: any = [];
      let fieldsObj;
      const fieldData = editConditionData?.expression?.split(" ");
      if (editConditionData?.conditionType?.toUpperCase() == "SIMPLE") {
        for (let i = 0; i < (fieldData?.length - 1) / 4; i++) {
          fieldsObj = JSON.parse(`{
            "property": "${fieldData[i * 4 + 1]}",
            "operator": "${fieldData[i * 4 + 2]}",
            "piHolder": "${fieldData[i * 4 + 3]}",
            "expression": "${fieldData[i * 4]}"
          }`);
          setFields.push(fieldsObj);
        }
      }
      scoreCardForm.setFieldsValue({
        conditionName: editConditionData?.conditionName,
        conditionDescription: editConditionData?.description,
        conditionType: editConditionData?.conditionType?.toUpperCase(),
        expressionList: editConditionData?.expression,
        fields: setFields,
      });
    }
  }, []);

  const getPropertiesList = () => {
    API_SERVICE.getPropertiesList(decisionData?.masterProductId)
      .then(({ data }) => {
        const displayNameListData = data.payload?.map((item: any) => {
          return {
            path: item.path,
            displayName: item.displayName,
            children: item?.children,
          };
        });
        setPropertiesList(displayNameListData);
        setPropertiesListFilter(displayNameListData);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const renderList = (
    item: any,
    index: number,
    children: any,
    isChild = false
  ) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="child-view">
            {isChild && <div className="line-horizontal" />}
            <span>{item.displayName}</span>
          </div>
          {children?.length > 0 ? (
            ""
          ) : (
            <FormIconButton
              type={isPropertyListModal ? "clickFinger" : "copyBlack"}
              onClick={() => {
                if (isPropertyListModal) {
                  let fieldsObj: any;
                  const setFields: any = [];
                  scoreCardForm
                    .getFieldsValue()
                    ?.fields?.map((fieldData: any) => {
                      if (
                        fieldData?.piHolder ==
                        "$" + `${propertiesIndex + 1}`
                      ) {
                        fieldsObj = {
                          property: item.path.includes(".")
                            ? item?.path?.replaceAll(".", "!.")
                            : item?.path,
                          operator: fieldData?.operator,
                          piHolder: fieldData?.piHolder,
                          expression: fieldData?.expression,
                        };
                      } else {
                        fieldsObj = fieldData;
                      }
                      setFields.push(fieldsObj);
                    });
                  scoreCardForm.setFieldsValue({
                    fields: setFields,
                  });
                  setIsPropertyListModal(false);
                } else {
                  if (item.path.includes(".")) {
                    let nameReplace = item?.path?.replaceAll(".", "!.");
                    navigator.clipboard.writeText(nameReplace);
                  } else {
                    navigator.clipboard.writeText(item.path);
                  }
                }
              }}
            />
          )}
        </div>

        {item?.children?.length > 0 &&
          children?.map((data: any, index: number) => {
            const isLast = index == children?.length - 1;

            return (
              <div
                style={{
                  marginLeft: isChild ? "20px" : "10px",
                  // borderLeft: "1px solid gray",
                  display: "flex",
                }}
              >
                <div
                  className="line-vertical"
                  style={isLast ? { height: "22px" } : {}}
                />
                <p
                  style={{
                    // marginLeft: "10px",
                    marginTop: "5px",
                    marginBottom: "0px",
                    width: "100%",
                  }}
                >
                  {renderList(data, index, data?.children, true)}
                </p>
              </div>
            );
          })}
      </>
    );
  };

  const operatorList: any = [
    {
      key: "==",
      value: "==",
    },
    {
      key: "!=",
      value: "!=",
    },
    {
      key: "<",
      value: "<",
    },
    {
      key: "<=",
      value: "<=",
    },
    {
      key: ">",
      value: ">",
    },
    {
      key: ">=",
      value: ">=",
    },
  ];

  const expressionList: any = [
    {
      key: "&&",
      value: "&&",
    },
    {
      key: "||",
      value: "||",
    },
  ];

  const order = {
    items: [{}],
  };

  const onChangePropertyType = (e: any) => {
    order.items.map((item: any) => []);
    setChangeConditionType(e);
    scoreCardForm.resetFields([
      "expression",
      "property",
      "operator",
      "piHolder",
      "expressionTextArea",
    ]);
    if (e == "SIMPLE") {
      let fieldsObj = JSON.parse(`{
            "property": "",
            "operator": "",
            "piHolder": "$1",
            "expression": ""
          }`);
      const setFields: any = [fieldsObj];
      scoreCardForm.setFieldsValue({
        fields: setFields,
      });
    }
  };

  const itemInputs = order.items.map((item: any) => {
    return {
      piHolder: item.piHolder,
      property: item.property,
      expression: item.expression,
      operator: item.operator,
    };
  });

  const onDelete = () => {
    let tmpArr = currentPayloadData?.conditionMetadata;
    const ind = currentPayloadData?.conditionMetadata?.findIndex(
      (dec: any) => dec?.conditionId == editConditionData?.conditionId
    );
    const remove = tmpArr.splice(ind, 1);

    const ruleData = currentPayloadData?.ruleMetadata?.map((rule: any) => {
      const ind = rule?.decisioningConditions.findIndex(
        (dec: any) => dec?.conditionId == remove?.at(0)?.conditionId
      );
      rule?.decisioningConditions?.splice(ind, 1);
      return rule;
    });

    const actionData =
      tmpArr?.length == 0 ? [] : currentPayloadData?.actionMetadata;

    const deletePayload = {
      currentPayload: {
        ...currentPayloadData,
        conditionMetadata: tmpArr,
        ruleMetadata: tmpArr?.length == 0 ? [] : ruleData,
        actionMetadata: actionData,
      },
      decisionType: decisionData?.decisionType,
      description: decisionData?.description,
      masterProductId: decisionData?.masterProductId,
      dsaBusinessId: decisionData?.id,
      name: decisionData?.name,
      purpose: decisionData?.purpose,
    };
    addDecisioning(deletePayload);
  };

  const addDecisioning = (payload: any) => {
    API_SERVICE.updateDecisioning(decisionData?.id, payload)
      .then(() => {
        getDraftList();
        setShowAddCondition(false);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const validateExpression = (payload: any) => {
    API_SERVICE.validateConditionExpression(payload)
      .then(({ data }) => {
        setIsValidateBtn(false);
        setValidationResponse({
          isValid: true,
          message: data?.payload?.status,
        });
      })
      .catch((e: any) => {
        setValidationResponse({
          isValid: false,
          message: API_SERVICE.handleErrors(e, true),
        });
      });
  };

  const onFinish = (values: any) => {
    if (values?.conditionType == "COMPLEX" && isValidateBtn) {
      const payloadVaild = {
        decisioningMasterId: decisionData?.id,
        expression: values?.expressionList,
      };
      validateExpression(payloadVaild);
    } else {
      let expressionData: any;

      values?.fields?.map((item: any, index: number) => {
        expressionData += `${
          item?.expression == undefined ? "" : item?.expression
        } ${item?.property} ${item?.operator} $${index + 1} `;
      });

      const conditionMetadata = currentPayloadData?.conditionMetadata ?? [];

      const newCon = {
        canEdit: true,
        conditionId: isEditCondition ? editConditionData?.conditionId : "",
        conditionName: values?.conditionName,
        conditionType: values?.conditionType?.toUpperCase(),
        description: values?.conditionDescription,
        expression: !isValidateBtn
          ? values?.expressionList
          : expressionData?.replace("undefined", ""),
      };

      if (isEditCondition) {
        const ind = conditionMetadata.findIndex(
          (dec: any) => dec?.conditionId == editConditionData?.conditionId
        );
        conditionMetadata[ind] = newCon;
      } else {
        conditionMetadata?.push(newCon);
      }

      const payload = {
        currentPayload: {
          ...currentPayloadData,
          conditionMetadata: conditionMetadata,
        },
        decisionType: decisionData?.decisionType,
        description: decisionData?.description,
        masterProductId: decisionData?.masterProductId,
        dsaBusinessId: decisionData?.id,
        name: decisionData?.name,
        purpose: decisionData?.purpose,
      };
      addDecisioning(payload);
    }
  };

  const filterData = (items: any, filterText: any) => {
    return items
      .map((item: any) => {
        const filteredItem = { ...item };

        if (item.children) {
          filteredItem.children = filterData(item.children, filterText);
        }

        return filteredItem;
      })
      .filter(
        (item: any) =>
          item.displayName.toLowerCase().includes(filterText.toLowerCase()) ||
          (item.children && item.children.length > 0)
      );
  };

  const setpiHolderOnAddRemove = () => {
    const fieldsTmp = scoreCardForm
      .getFieldsValue()
      ?.fields?.map((fieldData: any, index: any) => {
        const fieldsObj = {
          ...fieldData,
          piHolder: "$" + `${index + 1}`,
        };
        return fieldsObj;
      });
    console.log("fieldsTmp: ", fieldsTmp);
    scoreCardForm.setFieldsValue({
      fields: fieldsTmp,
    });
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "20px" }}>
      <Modal
        title="Properties List"
        open={isPropertyListModal}
        onCancel={() => setIsPropertyListModal(false)}
        footer={false}
      >
        <Card style={{ width: "100%", height: 370, overflow: "auto" }}>
          <Input
            size="large"
            className="search-input"
            style={{ marginBottom: "10px" }}
            prefix={<img src={searchIcon} alt="" />}
            placeholder="Search"
            allowClear
            onChange={(e: any) => {
              const filteredData = filterData(propertiesList, e?.target?.value);
              // const filterSearch = propertiesList?.filter((item: any) => {
              //   return item?.displayName
              //     ?.toLowerCase()
              //     ?.includes(e?.target?.value?.toLowerCase());
              // });
              setPropertiesListFilter(filteredData);
            }}
          />
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={propertiesListFilter}
            itemLayout="horizontal"
            renderItem={(item: any, index: number) => (
              <List.Item style={{ marginBottom: "4px" }}>
                {renderList(item, index, item?.children)}
              </List.Item>
            )}
          />
        </Card>
      </Modal>
      <Title level={3} className="condition-title">
        {isEditCondition &&
        (currentPayloadData?.conditionMetadata?.length ?? 0) > 0
          ? "Edit Condition"
          : "Add Condition"}
      </Title>
      <Divider style={{ margin: "0" }} />
      <div className="select-content">
        <Form
          name="scoreCardForm"
          layout="vertical"
          form={scoreCardForm}
          onFinish={onFinish}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Row gutter={24}>
            <Col xl={10} lg={10} xs={24}>
              <FormInput
                label="Condition Name"
                name="conditionName"
                classNameForm="space-input"
                isBorder={true}
                requiredLabel="condition name"
                isRequired
              />
              <FormInput
                label="Condition Description"
                name="conditionDescription"
                classNameForm="space-input"
                isBorder={true}
              />
              <FormSelect
                label="Condition Type"
                name="conditionType"
                classNameForm="space-input"
                placeholder="Select Property Type"
                isBorder={true}
                options={getEnumList("DECISIONING_CONDITION_TYPE")}
                onChange={onChangePropertyType}
                defaultValue={changeConditionType}
                isRequired
              />
              {changeConditionType === "SIMPLE" ? (
                <>
                  <Form.List name="fields" initialValue={itemInputs}>
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map(({ key, name }, index: number) => (
                            <div>
                              {key !== 0 && (
                                <FormSelect
                                  label="Expression"
                                  name={[name, "expression"]}
                                  classNameForm="space-input"
                                  placeholder="Select expression"
                                  isBorder={true}
                                  options={expressionList}
                                  isRequired
                                />
                              )}
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div className="fields-width">
                                  <Form.Item
                                    name={[name, "property"]}
                                    label="Property"
                                    className="field-bg field-border"
                                    style={{
                                      marginTop: "20px",
                                      width: "-webkit-fill-available",
                                    }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select property",
                                      },
                                    ]}
                                  >
                                    <Input
                                      suffix={<DownOutlined />}
                                      style={{ border: "none" }}
                                      placeholder="Select Property"
                                      onClick={() => {
                                        setPropertiesIndex(index);
                                        setIsPropertyListModal(true);
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="fields-width">
                                  <FormSelect
                                    label="Operator"
                                    name={[name, "operator"]}
                                    classNameForm="space-input"
                                    placeholder="Select"
                                    style={{ width: "-webkit-fill-available" }}
                                    isBorder={true}
                                    options={operatorList}
                                    isRequired
                                  />
                                </div>
                                <div>
                                  <FormInput
                                    label="PI-holder"
                                    name={[name, "piHolder"]}
                                    style={{ width: "115px" }}
                                    isReadonly
                                    initialValue={"$" + `${index + 1}`}
                                    classNameForm="space-input"
                                    isBorder={true}
                                    requiredLabel="piHolder"
                                  />
                                </div>

                                <div>
                                  {key === 0 ? (
                                    <FormIconButton
                                      className="vertical-center"
                                      type="plus"
                                      onClick={() => {
                                        const last = scoreCardForm
                                          .getFieldsValue()
                                          ?.fields?.at(-1);
                                        if (
                                          last?.piHolder != "$1" &&
                                          (last?.expression?.length ?? 0) == 0
                                        ) {
                                          notification.error({
                                            message: "Please select Expression",
                                          });
                                        } else if (
                                          (last?.property?.length ?? 0) == 0
                                        ) {
                                          notification.error({
                                            message: "Please select Property",
                                          });
                                        } else if (
                                          (last?.operator?.length ?? 0) == 0
                                        ) {
                                          notification.error({
                                            message: "Please select Operator",
                                          });
                                        } else {
                                          add();
                                          setpiHolderOnAddRemove();
                                        }
                                      }}
                                    />
                                  ) : (
                                    <FormIconButton
                                      className="vertical-center"
                                      type="minus"
                                      onClick={() => {
                                        remove(name);
                                        setpiHolderOnAddRemove();
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  </Form.List>
                </>
              ) : (
                ""
              )}
              {changeConditionType !== "SIMPLE" && (
                <>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button type="link" onClick={() => setIsPropertyList(true)}>
                      list of properties
                    </Button>
                    <Tooltip title="(loanAmount >= $1 || loanAmount <= $2) && (unsecurdLoanSummary.twelveMonthAnalysis.credit.getAvg() >=$3)">
                      <GrCircleInformation
                        style={{ marginTop: "7px", fontSize: "20px" }}
                      />
                    </Tooltip>
                  </div>
                  <FormTextArea
                    classNameForm="space-input"
                    name="expressionList"
                    label="Expression"
                    placeholder="Enter your expression"
                    isRequired
                    isBorder={true}
                    requiredLabel="expression"
                    onChange={() => setIsValidateBtn(true)}
                  />
                </>
              )}
              <div
                style={{ color: validationResponse?.isValid ? "green" : "red" }}
              >
                {validationResponse?.message}
              </div>
            </Col>
            {isPropertyList && changeConditionType !== "SIMPLE" && (
              <Divider type="vertical" style={{ height: "auto" }} />
            )}
            <Col span={4}>
              <>
                {isPropertyList && changeConditionType !== "SIMPLE" && (
                  <Card
                    title="Properties List"
                    extra={
                      <a onClick={() => setIsPropertyList(false)}>
                        <img src={closeIcon} />
                      </a>
                    }
                    style={{ width: 400, height: 370, overflow: "auto" }}
                  >
                    <Input
                      size="large"
                      className="search-input"
                      style={{ marginBottom: "10px" }}
                      prefix={<img src={searchIcon} alt="" />}
                      placeholder="Search"
                      allowClear
                      onChange={(e: any) => {
                        const filteredData = filterData(
                          propertiesList,
                          e?.target?.value
                        );
                        // const filterSearch = propertiesList?.filter(
                        //   (item: any) => {
                        //     return item?.displayName
                        //       ?.toLowerCase()
                        //       ?.includes(e?.target?.value?.toLowerCase());
                        //   }
                        // );
                        setPropertiesListFilter(filteredData);
                      }}
                    />
                    <List
                      grid={{ gutter: 16, column: 1 }}
                      dataSource={propertiesListFilter}
                      itemLayout="horizontal"
                      renderItem={(item: any, index: number) => (
                        <List.Item style={{ marginBottom: "4px" }}>
                          {renderList(item, index, item?.children)}
                        </List.Item>
                      )}
                    />
                  </Card>
                )}
              </>
            </Col>
          </Row>
          <Space style={{ marginTop: "20px" }}>
            <FormButton
              type="primary"
              htmlType="submit"
              label={
                changeConditionType == "COMPLEX" && isValidateBtn
                  ? "Validate"
                  : "Save"
              }
              style={{ height: "50px", width: "150px" }}
            />
            {isEditCondition &&
              currentPayloadData?.conditionMetadata?.length > 0 && (
                <Form.Item>
                  <Popconfirm
                    title="If you delete this condition, your rule data will also disappear. Are you sure to delete?"
                    okText="Yes"
                    cancelText="No"
                    className="remove"
                    onConfirm={() => {
                      onDelete();
                    }}
                  >
                    <Button
                      htmlType={"button"}
                      style={{
                        height: "50px",
                        width: "150px",
                        fontSize: "16px",
                      }}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Form.Item>
              )}
            <FormButton
              label={"Cancel"}
              onclick={() => {
                setShowAddCondition(false);
              }}
              style={{ height: "50px", width: "150px" }}
            />
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default AddScoreCard;
