import { Card } from "antd";
import { numberWithCommas } from "../../../shared/Utility/Utility";

interface IOfferTestOutput {
  offerTestedData: any;
}

const OfferTestOutput: React.FC<IOfferTestOutput> = (
  props: IOfferTestOutput
) => {
  const { offerTestedData } = props;

  return (
    <Card title="Test Output">
      <p className="tested-data">
        Max Loan Amount
        <span>
          Rs. {numberWithCommas(offerTestedData?.maxAllowedLoanAmount)}
        </span>
      </p>
      <p className="tested-data">
        Total EMI
        <span>Rs. {numberWithCommas(offerTestedData?.totalEMI)}</span>
      </p>
      {/* <p className="tested-data">
        EMI Per Lakh
        <span>Rs. {numberWithCommas(offerTestedData?.emiPerLakh)}</span>
      </p> */}
      <p className="tested-data">
        Tenure
        <span>{offerTestedData?.tenureInMonths}</span>
      </p>
    </Card>
  );
};

export default OfferTestOutput;
