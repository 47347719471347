import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const propertySlice = createSlice({
  name: "PropertyReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null, parentId: null },
  reducers: {
    setPropertyPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPropertyPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPropertySearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

export const { setPropertyPageNo, setPropertyPageSize, setPropertySearchParam } = propertySlice.actions;
export default propertySlice.reducer;