import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Table, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setDecisioningPageSize,
  setDecisioningSearchParam,
  setDecisioningtPageNo,
} from "../../../shared/redux/decisioning-reducer";
import search from "../../../assets/images/search.svg";
import AddDecisioning from "./Add-decisioning";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import FormButton from "../../../shared/component/FormButton/FormButton";
import ScoreCardTabs from "../ScoreCard/ScoreCard-tabs";
import { API_SERVICE } from "../../../shared/services/api-services";
import {
  setDecisioningObject,
  setProductsObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import { getEnumValue } from "../../../shared/Utility/Utility";
import { viewTypeProduct } from "../Products/Products";
import PasteDecision from "./PasteDecision";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}
interface IDecisioning {
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  getProductList: any;
}

const Decisioning: React.FC<IDecisioning> = (props: IDecisioning) => {
  const { setViewType, getProductList } = props;

  const [showAddDecisioning, setShowAddDecisioning] = useState(false);
  const [showScoreCardTabs, setShowScoreCardTabs] = useState(false);
  const [decisioningList, setDecisioningList] = useState(null as any);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingApp, setLoadingApp] = useState(false);
  const [editDecisioningData, setEditDecisioningData] = useState(null as any);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddDuplicate, setIsAddDuplicate] = useState(false);
  const [decisionData, setDecisionData] = useState("");
  const decisioning: any = useSelector((state: RootState) => state.decisioning);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const product: any = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();

  const goToEdit = (record: any) => {
    API_SERVICE.editDecisioningData(record?.id)
      .then(({ data }) => {
        setEditDecisioningData(data?.payload);
        setShowAddDecisioning(true);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const getPublishList = (record: any) => {
    if(record?.currentPublishedId === null) {
      setLoadingApp(false);
      API_SERVICE.handleErrors("No published decisioning found");
      return;
    }
    setLoadingApp(true);
    API_SERVICE.getScorecardPublisList(record?.currentPublishedId)
      .then(({ data }) => {
        const s = {
          ...record,
          lenderBusinessId: record?.lenderBusinessId,
          currentPayload: data?.payload?.publishedPayload
        }
        navigator.clipboard.writeText(JSON.stringify(s))
        notification.success({ message: "Copied to clipboard" });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoadingApp(false));
  };

  const decisioningData = (data: any) => {
    let param = {
      decisionName: data?.name,
      masterProductId: data?.masterProductId,
      decisionType: data?.decisionType,
      purpose: data?.purpose,
      lenderName: data?.lenderName,
    };
    dispatch(setDecisioningObject(param));
  };

  const columns: ColumnsType<columns> = [
    {
      title: "Decision Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any) => (
        <>
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setShowScoreCardTabs(true);
              setDecisionData(record);
              decisioningData(record);
            }}
          >
            {record?.name}
          </a>
        </>
      ),
    },
    {
      title: "Master Product",
      dataIndex: "masterProductId",
      key: "masterProduct",
    },
    {
      title: "Decision Type",
      dataIndex: "decisionType",
      key: "decisionType",
      render: (_: any, record: any) => (
        <>{getEnumValue("DECISIONING_TYPE", record.decisionType)}</>
      ),
    },
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      render: (_: any, record: any) => (
        <>{getEnumValue("DECISIONING_PURPOSE", record.purpose)}</>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      render: (_: any, record: any) => (
        <div style={{display:'flex'}}>
          {record?.currentPublishedId !== null ?
            <FormIconButton
              type="copyBlack"
              onClick={() => getPublishList(record)}
              tooltipTitle="Copy published decision"
            />
            : <div style={{width:"30px"}}/>
          }
          <FormIconButton
            type="addDuplicate"
            onClick={() => {
              goToEdit(record);
              setIsEdit(false);
              setIsAddDuplicate(true);
            }}
          />
          <FormIconButton
            type="editBlack"
            onClick={() => {
              goToEdit(record);
              setIsAddDuplicate(false);
              setIsEdit(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDecisioningtPageNo(current));
    dispatch(setDecisioningPageSize(pageSize));
    getDecisioningList(current, pageSize, decisioning?.searchParam);
  };

  const handleSearch = (value: any) => {
    dispatch(setDecisioningtPageNo(1));
    dispatch(setDecisioningSearchParam(value?.searchParam));
    getDecisioningList(1, decisioning.pageSize, value?.searchParam);
  };

  useEffect(() => {
    getDecisioningList();
    decisioningData("");
  }, []);

  const getDecisioningList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      direction: "DESC",
      nameFilter: searchParam,
      masterProductFilter: productsObject.productsId,
    };
    API_SERVICE.getDecisioningList(params)
      .then(({ data }) => {
        setDecisioningList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backproduct = () => {
    setViewType("product");
    dispatch(setScreenTitle("Products"));
    dispatch(setProductsObject({}));
    dispatch(setDecisioningSearchParam(null));
    getProductList(product?.pageNo, product?.pageSize, product.searchParam);
  };

  const renderDecisioning = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearch}>
            <Form.Item
              name="searchParam"
              initialValue={decisioning?.searchParam}
            >
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <FormButton
              label="Search"
              htmlType="submit"
              style={{ height: "40px" }}
              type="primary"
            />
          </Form>
          <div>
            <Button type="link" onClick={backproduct}>
              Back to product
            </Button>
            <Button
              type="primary"
              style={{ height: "40px" }}
              onClick={() => {
                setIsAddDuplicate(false);
                setIsEdit(false);
                setShowAddDecisioning(true);
              }}
            >
              Add Decisioning
            </Button>
            <PasteDecision getDecisioningList={getDecisioningList}  />
          </div>
        </div>

        <div className={"layout-main"}>
          <Table
            columns={columns}
            dataSource={decisioningList}
            size="middle"
            loading={loading}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: decisioning.pageNo,
              pageSize: decisioning.pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {showScoreCardTabs ? (
        <ScoreCardTabs
          decisionData={decisionData}
          setShowScoreCardTabs={setShowScoreCardTabs}
        />
      ) : (
        <>
          {showAddDecisioning ? (
            <AddDecisioning
              setShowAddDecisioning={setShowAddDecisioning}
              getDecisioningList={getDecisioningList}
              editdecisioningData={editDecisioningData}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              setIsAddDuplicate={setIsAddDuplicate}
              isAddDuplicate={isAddDuplicate}
            />
          ) : (
            renderDecisioning()
          )}
        </>
      )}
      {loadingApp && <AppLoading />}
    </>
  );
};

export default Decisioning;
