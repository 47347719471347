import { Col, Row, Switch, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setProductsObject, setScreenTitle } from "../../shared/redux/common-reducer";
import { AnyAction } from "redux";
import { viewTypeProduct } from "./Products/Products";
import { RootState } from "../../shared/redux/store";
import { API_SERVICE } from "../../shared/services/api-services";
import { useEffect, useState } from "react";



const { Title } = Typography

interface columns {
    key: React.ReactNode;
    name: string;
    category: string,
    status: boolean
}

interface IAction {
    setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
    getProductList: any;
}

const Action: React.FC<IAction> = (props: IAction) => {
    const { setViewType, getProductList } = props;
    const dispatch = useDispatch();
    const [actionList, setActionList] = useState([] as any)
    const [activeIdList, setActiveIdList] = useState([] as any)
    const [loading, setLoading] = useState(false)
    const { productsObject } = useSelector((state: RootState) => state.common);
    const product: any = useSelector((state: RootState) => state.product);
    const { productsId } = productsObject

    useEffect(() => {
        getProductActionList(productsId)
    }, [])

    const createActionList = (data: any) => {
        if (data?.payload) {
            const actionListItems: any = data?.payload?.map((i: any) => {
                return {
                    id: i.id,
                    name: i.name,
                    category: i.category,
                    status: i.status
                }
            })
            setActionList(actionListItems);
        } else {
            setActionList([]);
        }
    }

    const createActiveIdList = (data: any) => {
        if (data?.payload) {
            const activeIdList: any = data?.payload?.filter((i: any) => i.status == "ACTIVE").map((i: any) => i.id)
            setActiveIdList(activeIdList)
        } else {
            setActiveIdList([])
        }
    }

    const getProductActionList = (id: any) => {
        setLoading(true)
        API_SERVICE.getProductActionList(id).then(({ data }: any) => {
            createActionList(data)
            createActiveIdList(data)
        }).catch((e: any) => {
            API_SERVICE.handleErrors(e);
        }).finally(() => {
            setLoading(false)
        })
    }

    const updateActionStatus = (id: any, statusTo: boolean) => {
        let payload = {}
        if (statusTo) {
            payload = {
                activeActionsIds: [...activeIdList, String(id)]
            }
        } else {
            setActiveIdList((prev: any) => {
                const updatedActiveIdList = prev.filter((item: any) => item != id)
                payload = {
                    activeActionsIds: [...updatedActiveIdList]
                }
                return updatedActiveIdList
            });

        }
        setLoading(true)
        API_SERVICE.updateActionStatus(productsId, payload).then(({ data }: any) => {
            getProductActionList(data?.payload?.productId)
        }).catch((e: any) => {
            API_SERVICE.handleErrors(e);
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: any, record: any) => {
                return (
                    <>
                        <Switch checked={record.status == "ACTIVE" ? true : false} onChange={(statusTo: boolean) => {
                            updateActionStatus(record.id, statusTo)
                        }} />
                    </>
                )
            }
        }
    ]

    return (
        <>

            <div className={"layout-main"} style={{ marginTop: "15px" }}>
                <Row justify="space-between">
                    <Col>
                        <Title level={5} style={{ padding: "17px" }}>
                            Action Status
                        </Title>
                    </Col>
                    <Col>
                        <Title style={{ color: "#4285F4", cursor: "pointer", padding: "17px" }} onClick={() => {
                            setViewType("product")
                            dispatch(setScreenTitle("Products"))
                            dispatch(setProductsObject({}));
                            getProductList(product?.pageNo, product?.pageSize, product.searchParam);
                        }} level={5} >
                            Back to product
                        </Title>
                    </Col>
                </Row>

                <div className={"layout-main"}>
                    <Row style={{ padding: "15px" }}>
                        <Col xs={24} xl={24} span={13}>
                            <Table
                                columns={columns}
                                dataSource={actionList}
                                size="middle"
                                loading={loading}
                                onChange={(pagination: any) => {
                                    //   handlePaginationChange(pagination.current, pagination.pageSize);
                                }}
                                pagination={{
                                    position: ["bottomCenter"],
                                    //   current: product.pageNo,
                                    //   pageSize: product.pageSize,
                                    //   total: totalCount,
                                }}
                                scroll={{ x: "max-content" }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Action;
