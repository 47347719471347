import { Button, DatePicker, Form } from "antd";
import React, { useEffect, useState } from "react";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import { API_SERVICE } from "../../shared/services/api-services";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { saveByteArray } from "../../shared/Utility/Utility";
import excelIcon from "../../assets/images/excel.svg";

const { RangePicker } = DatePicker;

type IReports = {};

const Reports: React.FunctionComponent<IReports> = () => {
  const [reportTypes, setReportTypes] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [reportForm] = Form.useForm() as any;

  useEffect(() => {
    getAllTypes();
  }, []);

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredReportTypes = data.find(
          (item: any) => item?.propertyName === "ADMIN_REPORT_TYPE"
        );
        setReportTypes(filteredReportTypes?.keyAndDisplayValueList ?? []);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = (values: any) => {
    let startDate = "";
    let endDate = "";
    if (values && values?.dateRange) {
      startDate = values.dateRange[0].format("YYYY-MM-DD");
      endDate = values.dateRange[1].format("YYYY-MM-DD");
    }
    setLoading(true);
    API_SERVICE.generateReportByReportType(
      startDate,
      endDate,
      values.reportType
    )
      .then(({ data }) => {
        saveByteArray([data], values.reportType.toLowerCase() + "_report.xlsx");
        setLoading(false);
      })
      .catch(async (e: any) => {
        const data = JSON.parse(await e.response.data.text());
        const message = data?.payload?.errorMessage ?? e;
        API_SERVICE.handleErrors(message);
        setLoading(false);
      });
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "20px" }}>
      <div className="select-content">
        <Form
          name="reportForm"
          layout="vertical"
          form={reportForm}
          onFinish={onFinish}
          autoComplete="off"
        >
          <FormSelect
            label="Report Type"
            name="reportType"
            classNameForm="space-input"
            placeholder="Select Report Type"
            isBorder={true}
            options={reportTypes}
            isRequired
          />
          <Form.Item
            label="Date Range"
            name="dateRange"
            className="field-bg field-border"
            rules={[{ required: true, message: "Please select date range" }]}
            style={{ marginTop: "20px", width: "100%" }}
          >
            <RangePicker
              style={{ width: "250px" }}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%", textAlign: "center" }}
              icon={<img src={excelIcon} alt=""/>}
            >
              Export to Excel
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Reports;
