import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const documentTypeSlice = createSlice({
    name: "DocumentTypeReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setDocumentTypePageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setDocumentTypePageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setDocumentTypeSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setDocumentTypePageNo, setDocumentTypePageSize, setDocumentTypeSearchParam } = documentTypeSlice.actions;
export default documentTypeSlice.reducer;