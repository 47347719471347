import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import FormButton from "../../../shared/component/FormButton/FormButton";
import FormInput from "../../../shared/component/FormInput/FormInput";
import { API_SERVICE } from "../../../shared/services/api-services";
import passIcon from "../../../assets/images/pass.svg";
import failIcon from "../../../assets/images/fell.svg";
import errorIcon from "../../../assets/images/error.svg";
import warningIcon from "../../../assets/images/warning.svg";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import { GrCircleInformation } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import searchIcon from "../../../assets/images/search.svg";
import {
  setScoreCardPageNo,
  setScoreCardPageSize,
  setScoreCardSearchParam,
} from "../../../shared/redux/scoreCard-reducer";
import { getEnumList } from "../../../shared/Utility/Utility";
import OfferTestOutput from "../OfferCalculation/OfferTestOutput";

const { Text } = Typography;

interface IScoreCard {
  currentPayloadData: any;
  setCurrentPayloadData: any;
  decisionData: any;
  setOpenPublishComment: any;
  setTestDecision: any;
  setIsAddRuleClicked: any;
  isAddRuleClicked: any;
  isCreateTable: any;
  setIsCreateTable: any;
  setIsAddRule: any;
  isAddRule: any;
  getDraftList: any;
  setShowAddAction: any;
  setIsEditAction: any;
  setEditActionData: any;
  setEditActionIndex: any;
  setShowAddCondition: any;
  setIsEditCondition: any;
  setEditConditionData: any;
  setIsShowTest: any;
  isShowTest: any;
  testRule: any;
  testTotalScore: any;
  testedStatus: any;
  setIsShowCancelBtn: any;
  isShowCancelBtn: any;
  isDataTested: boolean;
  eligibleLendersArr: any;
  offerCalculationLenderOutputResponses: any;
  conditionEvaluationResults: any;
}

const ScoreCard: React.FunctionComponent<IScoreCard> = (props: IScoreCard) => {
  const {
    currentPayloadData,
    setCurrentPayloadData,
    setIsAddRuleClicked,
    isAddRuleClicked,
    isCreateTable,
    setIsCreateTable,
    setIsAddRule,
    isAddRule,
    getDraftList,
    decisionData,
    setTestDecision,
    setShowAddAction,
    setIsEditAction,
    setEditActionData,
    setEditActionIndex,
    setIsEditCondition,
    setShowAddCondition,
    setEditConditionData,
    setOpenPublishComment,
    setIsShowTest,
    isShowTest,
    testRule,
    testTotalScore,
    testedStatus,
    setIsShowCancelBtn,
    isShowCancelBtn,
    isDataTested,
    eligibleLendersArr,
    offerCalculationLenderOutputResponses,
    conditionEvaluationResults,
  } = props;

  const [scoreCardForm] = Form.useForm();
  const [scoreCardSearchForm] = Form.useForm();
  const [fieldEdit] = useState([] as any);
  const [tmpColumns, setTmpColumns] = useState<any>();
  const [scorecardData, setScoreCardData] = useState([] as any);
  const [scorecardDataFilter, setScoreCardDataFilter] = useState([] as any);
  // const [eligibleLendersArr, setEligibleLendersArr] = useState([] as any);
  const [isFinish, setIsFinish] = useState(false);
  const [index, setTableRecIndex] = useState(Number as any);
  const [isLoanOffer, setIsLoanOffer] = useState(false);
  const [loanOfferData, setLoanOfferData] = useState({} as any);
  const [isCancel, setIsCancel] = useState(false);
  const scoreCard: any = useSelector((state: RootState) => state.scoreCard);
  const dispatch = useDispatch();

  const configArr: any = [
    {
      key: "TRUE",
      value: "True",
    },
    {
      key: "FALSE",
      value: "False",
    },
  ];

  useEffect(() => {
    if (isCreateTable) {
      columnsAndDataDisplay(currentPayloadData, isShowTest);
    }
    setIsCreateTable(false);
  }, [isCreateTable]);

  useEffect(() => {
    if (isAddRuleClicked) {
      fieldEdit.push(0);
    }
    setIsAddRuleClicked(false);
  }, [isAddRuleClicked]);

  useEffect(() => {
    fieldEdit.length = 0;
    setIsFinish(false);
    columnsAndDataDisplay(currentPayloadData, isShowTest);
  }, [isFinish]);

  const columnsAndDataDisplay = (
    data: any,
    showTest: boolean,
    eligibleLendersArrTmp: any = null
  ) => {
    const eligibleLenders =
      eligibleLendersArrTmp == null
        ? eligibleLendersArr
        : eligibleLendersArrTmp;

    const columns: any = [
      {
        title: "Rule Name",
        key: "ruleName",
        dataIndex: ["ruleName"],
        width: 150,
        fixed: "left",
        render: (value: any, record: any, index: number) => {
          let tableIndex = tableData?.findIndex(
            (item: any) => item?.ruleName == record?.ruleName
          );
          setTableRecIndex(tableIndex);
          if (fieldEdit.includes(index)) {
            if (decisionData?.decisionType == "OFFER_DECISION") {
              const rules = value?.split("-");
              scoreCardForm.setFieldsValue({
                [`ruleName${tableIndex}`]: rules?.at(0),
                [`ruleName${tableIndex}-purpose`]: rules?.at(-1),
              });
            } else {
              scoreCardForm.setFieldsValue({
                [`ruleName${tableIndex}`]: value,
              });
            }
            return (
              <>
                {decisionData?.decisionType == "OFFER_DECISION" ? (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Tooltip
                      color="#1a2778"
                      placement="top"
                      title={
                        <div style={{ display: "grid" }}>
                          To create rule:
                          <span>1. Select a lender from drop down 1</span>
                          <span>2. Select a lender from drop down 2</span>
                          <span>
                            3. Enter the range required for positive Offer
                            Decision. Ex: If a minimum score of 7 is required
                            for an offer, enter "7,10"
                          </span>
                        </div>
                      }
                    >
                      <GrCircleInformation
                        style={{ marginTop: "25px", fontSize: "20px" }}
                      />
                    </Tooltip>
                    <FormSelect
                      name={`ruleName${tableIndex}`}
                      options={eligibleLenders}
                      optionFilterProp="children"
                      isBorder
                      isRequired
                      requiredLabel="Lender"
                      style={{ minWidth: "100px", maxWidth: "250px" }}
                      onChange={(e: any) => {
                        const ruleData = currentPayloadData?.ruleMetadata ?? [];
                        const rules =
                          ruleData[tableIndex][`${"ruleName"}`]?.split("-");
                        ruleData[tableIndex][`${"ruleName"}`] = `${e}-${rules?.at(-1) ?? ""
                          }`;
                      }}
                    />
                    <FormSelect
                      name={`ruleName${tableIndex}-purpose`}
                      options={getEnumList("DECISIONING_PURPOSE")}
                      isBorder
                      isRequired
                      requiredLabel="Purpose"
                      style={{ minWidth: "100px", maxWidth: "250px" }}
                      onChange={(e: any) => {
                        const ruleData = currentPayloadData?.ruleMetadata ?? [];
                        const rules =
                          ruleData[tableIndex][`${"ruleName"}`]?.split("-");
                        ruleData[tableIndex][`${"ruleName"}`] = `${rules?.at(0) ?? ""
                          }-${e}`;
                      }}
                    />
                  </div>
                ) : (
                  <FormInput
                    size="small"
                    name={`ruleName${tableIndex}`}
                    defaultValue={value}
                    isBorder
                    onBlur={(e) => {
                      const ruleData = currentPayloadData?.ruleMetadata ?? [];
                      ruleData[tableIndex][`${"ruleName"}`] = e?.target?.value;
                    }}
                  />
                )}
              </>
            );
          } else {
            let tmpValue = value;
            if (decisionData?.decisionType == "OFFER_DECISION") {
              const rules = value?.split("-");
              const eligibleName = eligibleLenders?.find(
                (item: any) => item?.key == rules?.at(0)
              );
              const purpose = getEnumList("DECISIONING_PURPOSE")?.find(
                (item: any) => item?.key == rules?.at(-1)
              );
              tmpValue =
                (eligibleName?.value ?? "") + " - " + (purpose?.value ?? "");
            }
            return <Text>{tmpValue}</Text>;
          }
        },
      },
    ];

    data?.conditionMetadata?.map((item: any, conIndex: number) => {
      columns.push({
        title: (
          <div>
            Condition
            {decisionData?.decisionType != "OFFER_DECISION" && (
              <FormIconButton
                type={"editBlack"}
                className="columns-edit"
                onClick={() => {
                  setIsShowTest(false);
                  setEditConditionData(item ?? {});
                  setIsEditCondition(true);
                  setShowAddCondition(true);
                }}
              />
            )}
          </div>
        ),
        children: [
          {
            title: item?.conditionName,
            children: [
              {
                title: item?.expression,
                key: item?.conditionId,
                width: 300,
                dataIndex: `conditionId_${item?.conditionId}`,
                render: (value: any, record: any, index: number) => {
                  let tableIndex = tableData?.findIndex(
                    (item: any) => item?.ruleName == record?.ruleName
                  );
                  if (fieldEdit.includes(index)) {
                    if (item?.expression?.trim()?.includes('validatePincodeByLender')) {
                      const ruleData = value?.split(",");
                      scoreCardForm.setFieldsValue({
                        [`conditionId_${item?.conditionId}${tableIndex}`]: ruleData?.at(0),
                        [`conditionId_${item?.conditionId}${tableIndex}-input`]: ruleData?.at(-1),
                      });
                    } else {
                      scoreCardForm.setFieldsValue({
                        [`conditionId_${item?.conditionId}${tableIndex}`]: value,
                      });
                    }
                    if (item?.expression?.trim()?.includes('validatePincodeByLender')) {
                      return (
                        <Space>
                          <FormSelect
                            name={`conditionId_${item?.conditionId}${tableIndex}`}
                            options={eligibleLenders}
                            optionFilterProp="children"
                            isBorder
                            style={{ minWidth: "180px", maxWidth: "250px" }}
                            onChange={(e: any) => {
                              const ruleData = currentPayloadData?.ruleMetadata ?? [];
                              const decis = ruleData[
                                tableIndex
                              ]?.decisioningConditions?.map((dec: any) => {
                                if (dec?.conditionId == item?.conditionId) {
                                  // const decs = dec.conditionParamterValues?.trim()?.split(",");
                                  dec.conditionParamterValues = e;
                                  //  `${e},${decs?.at(-1) ?? ""}`
                                }
                                return dec;
                              });
                              ruleData[tableIndex][`${"decisioningConditions"}`] =
                                decis;
                            }}
                          />
                          {/* <FormInput
                            name={`conditionId_${item?.conditionId}${tableIndex}-input`}
                            onChange={(e: any) => {
                              const ruleData = currentPayloadData?.ruleMetadata ?? [];
                              const decis = ruleData[
                                tableIndex
                              ]?.decisioningConditions?.map((dec: any) => {
                                if (dec?.conditionId == item?.conditionId) {
                                  const decs = dec.conditionParamterValues?.trim()?.split(',')
                                  dec.conditionParamterValues = `${decs?.at(0) ?? ""},${e?.target?.value}`;
                                }
                                return dec;
                              });
                              ruleData[tableIndex][`${"decisioningConditions"}`] =
                                decis;
                            }}
                            isBorder /> */}
                        </Space>
                      )
                    } else {
                      return (
                        <FormInput
                          size="small"
                          name={`conditionId_${item?.conditionId}${tableIndex}`}
                          defaultValue={value}
                          isBorder
                          onBlur={(e) => {
                            const ruleData =
                              currentPayloadData?.ruleMetadata ?? [];
                            let ruleNameTmp = ruleData[
                              tableIndex
                            ]?.ruleName?.replaceAll("-", ",");
                            const decis = ruleData[
                              tableIndex
                            ]?.decisioningConditions?.map((dec: any) => {
                              if (dec?.conditionId == item?.conditionId) {
                                dec.conditionParamterValues =
                                  decisionData?.decisionType == "OFFER_DECISION"
                                    ? `${ruleNameTmp},${e?.target?.value}`
                                    : e?.target?.value;
                              }
                              return dec;
                            });
                            ruleData[tableIndex][`${"decisioningConditions"}`] =
                              decis;
                          }}
                        />
                      );
                    }
                  } else {
                    const isPass = conditionEvaluationResults?.some(
                      (res: any) =>
                        res?.ruleMetaDataId == tableIndex &&
                        res?.passedConditionResultData?.some(
                          (passed: any) =>
                            passed?.conditionMetadataId == item?.conditionId
                        )
                    );
                    let tmpValue = value;
                    if (item?.expression?.trim()?.includes('validatePincodeByLender')) {
                      const val = value?.split(',')
                      const findLenderName = eligibleLenders?.find(
                        (item: any) => item?.key == val?.at(0)
                      );
                      tmpValue = findLenderName?.value ?? "";
                      //  (findLenderName?.value ?? "") + ',' + (val?.at(1) ?? "");
                    }
                    return (
                      <div className="content-sb">
                        <Text>{tmpValue}</Text>
                        {showTest && (
                          <img
                            src={
                              isPass || (value ?? "") == ""
                                ? passIcon
                                : failIcon
                            }
                            alt=""
                          />
                        )}
                      </div>
                    );
                  }
                },
              },
            ],
          },
        ],
      });
    });

    data?.actionMetadata?.map((item: any, index: number) => {
      const displayNameListData = item?.categoryInfoList?.map((item: any) => {
        return {
          key: item.id,
          value: `${item.categoryName},${item?.weightage}%`,
        };
      });
      columns.push({
        title: (
          <div>
            Action
            {decisionData?.decisionType != "OFFER_DECISION" && (
              <FormIconButton
                type={"editBlack"}
                className="columns-edit"
                onClick={() => {
                  setIsShowTest(false);
                  setEditActionIndex(index);
                  setEditActionData(item ?? {});
                  setIsEditAction(true);
                  setShowAddAction(true);
                }}
              />
            )}
          </div>
        ),
        children: [
          {
            title: item?.actionName,
            children: [
              {
                title: item?.description?.replaceAll(",", ", "),
                key: item?.actionId,
                width: 300,
                dataIndex: `actionId_${item?.actionId}`,
                render: (value: any, record: any, index: number) => {
                  let tableIndex = tableData?.findIndex(
                    (item: any) => item?.ruleName == record?.ruleName
                  );

                  if (
                    fieldEdit.includes(index) &&
                    decisionData?.decisionType != "OFFER_DECISION"
                  ) {
                    const isCategoryInfoList =
                      (item?.categoryInfoList?.length ?? 0) > 0;
                    if (isCategoryInfoList) {
                      scoreCardForm.setFieldsValue({
                        [`actionList_${item?.actionId}${tableIndex}`]: value,
                        [`actionId_${item?.actionId}${tableIndex}`]:
                          record?.scoreOrReject,
                      });
                    } else if (item?.expression == "ELIGIBLE_LENDERS") {
                      scoreCardForm.setFieldsValue({
                        [`actionId_${item?.actionId}${tableIndex}`]: value,
                      });
                    } else {
                      scoreCardForm.setFieldsValue({
                        [`actionId_${item?.actionId}${tableIndex}`]: value,
                      });
                    }
                    return (
                      <Space>
                        {isCategoryInfoList && (
                          <div style={{ width: "220px" }}>
                            <FormSelect
                              name={`actionList_${item?.actionId}${tableIndex}`}
                              options={displayNameListData}
                              isBorder
                              onChange={(e) => {
                                const ruleData =
                                  currentPayloadData?.ruleMetadata ?? [];
                                const decis = ruleData[
                                  tableIndex
                                ]?.decisioningActions?.map((dec: any) => {
                                  if (dec?.actionId == item?.actionId) {
                                    dec.categoryId = e;
                                  }
                                  return dec;
                                });
                                ruleData[tableIndex][
                                  `${"decisioningActions"}`
                                ] = decis;
                              }}
                            />
                          </div>
                        )}
                        {item?.expression == "SET_CONDITIONAL_CONFIG_VALUE" ? (
                          <FormSelect
                            name={`actionId_${item?.actionId}${tableIndex}`}
                            options={configArr}
                            isBorder
                            style={{ minWidth: "220px" }}
                            onChange={(e: any) => {
                              const ruleData =
                                currentPayloadData?.ruleMetadata ?? [];
                              const decis = ruleData[
                                tableIndex
                              ]?.decisioningActions?.map((dec: any) => {
                                if (dec?.actionId == item?.actionId) {
                                  return (dec.actionParamterValues = e);
                                }
                                ruleData[tableIndex][
                                  `${"decisioningActions"}`
                                ] = decis;
                              });
                            }}
                          />
                        ) : item?.expression == "ELIGIBLE_LENDERS" ? (
                          <FormSelect
                            name={`actionId_${item?.actionId}${tableIndex}`}
                            optionFilterProp="children"
                            options={eligibleLenders}
                            isBorder
                            style={{ minWidth: "220px" }}
                            onChange={(e: any) => {
                              const ruleData =
                                currentPayloadData?.ruleMetadata ?? [];
                              if (
                                ruleData[tableIndex]?.decisioningActions == null
                              ) {
                                ruleData[tableIndex].decisioningActions = [
                                  {
                                    actionId: item?.actionId,
                                    actionParamterValues: e,
                                  },
                                ];
                              } else {
                                const decis = ruleData[
                                  tableIndex
                                ]?.decisioningActions?.map((dec: any) => {
                                  if (dec?.actionId == item?.actionId) {
                                    return (dec.actionParamterValues = e);
                                  }
                                  ruleData[tableIndex][
                                    `${"decisioningActions"}`
                                  ] = decis;
                                });
                              }
                            }}
                          />
                        ) : (
                          <FormInput
                            size="small"
                            name={`actionId_${item?.actionId}${tableIndex}`}
                            style={{
                              minWidth: "80px",
                            }}
                            isBorder
                            onBlur={(e) => {
                              const ruleData =
                                currentPayloadData?.ruleMetadata ?? [];
                              const decis = ruleData[
                                tableIndex
                              ]?.decisioningActions?.map((dec: any) => {
                                if (dec?.actionId == item?.actionId) {
                                  if (isCategoryInfoList) {
                                    dec.scoreOrReject = e?.target?.value;
                                  } else {
                                    dec.actionParamterValues = e?.target?.value;
                                  }
                                }
                                return dec;
                              });
                              ruleData[tableIndex][`${"decisioningActions"}`] =
                                decis;
                              // setCurrentPayloadData({
                              //   ...currentPayloadData,
                              //   ruleMetadata: ruleData,
                              // })
                            }}
                          />
                        )}
                      </Space>
                    );
                  } else {
                    if (item?.expression == "SET_CONDITIONAL_CONFIG_VALUE") {
                      let configName = configArr?.find(
                        (item: any) => item?.key == value
                      );
                      return <Text>{configName?.value}</Text>;
                    } else if (item?.expression == "ELIGIBLE_LENDERS") {
                      let eligibleName = eligibleLenders?.find(
                        (item: any) => item?.key == value
                      );
                      return <Text>{eligibleName?.value}</Text>;
                    } else {
                      let categoryName = displayNameListData?.find(
                        (item: any) => item?.key == value
                      );
                      const cat = categoryName?.value
                        ? `${categoryName?.value},${record?.scoreOrReject}`
                        : "";
                      return (
                        <Text>
                          {item?.categoryInfoList?.length > 0 ? cat : value}
                        </Text>
                      );
                    }
                  }
                },
              },
            ],
          },
        ],
      });
    });

    const tableData: any = [];
    data?.ruleMetadata?.map((item: any) => {
      let getRule = { ruleName: item?.ruleName };
      item?.decisioningConditions?.map((item: any) => {
        try {
          let value = item?.conditionParamterValues;
          if (decisionData?.decisionType == "OFFER_DECISION") {
            const values = value?.split(",");
            values?.splice(0, 2);
            value = values?.join(",");
          }
          const getValue = JSON.parse(
            `{"conditionId_${item?.conditionId}": "${value}"}`
          );
          getRule = { ...getRule, ...getValue };
        } catch (e: any) {
          console.log("error on creating rule condition: ", e);
        }
      });

      item.decisioningActions?.map((item: any) => {
        let getValue: any;
        try {
          if (item?.categoryId?.length > 0) {
            getValue = JSON.parse(
              `{"actionId_${item?.actionId}": "${item?.categoryId}","scoreOrReject": "${item?.scoreOrReject}"}`
            );
          } else {
            getValue = JSON.parse(
              `{"actionId_${item?.actionId}": "${item?.actionParamterValues}"}`
            );
          }
          getRule = { ...getRule, ...getValue };
        } catch (e: any) {
          console.log("error on creating rule Action: ", e);
        }
      });

      tableData.push(getRule);
    });

    columns.push({
      title: (
        <>
          {testTotalScore > 0 ? (
            <div style={{ textAlign: "center", display: "grid" }}>
              Total Score <br />
              <span style={{ fontSize: "18px" }}>{testTotalScore}</span>
              <span>Status: {testedStatus}</span>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      key: "ac",
      dataIndex: "ac",
      width: 130,
      fixed: "right",
      render: (_: any, record: any, index: number) => {
        const findOfferCalculationLender =
          offerCalculationLenderOutputResponses?.find(
            (item: any) => item?.ruleNameIdentifier == record?.ruleName
          );
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {showTest && (
              <>
                {(offerCalculationLenderOutputResponses?.length ?? 0) > 0 &&
                  findOfferCalculationLender && (
                    <Tooltip
                      title={
                        findOfferCalculationLender?.errorDescriptionOnProcessing
                      }
                    >
                      <img
                        style={{
                          cursor: "pointer",
                          marginTop: "2px",
                          marginRight: "2px",
                        }}
                        src={
                          findOfferCalculationLender?.loanOffer
                            ? warningIcon
                            : errorIcon
                        }
                        alt=""
                        onClick={() => {
                          setLoanOfferData(
                            findOfferCalculationLender?.loanOffer ?? {}
                          );
                          if (findOfferCalculationLender?.loanOffer) {
                            setIsLoanOffer(true);
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                <img
                  src={
                    testRule?.includes(record?.ruleName?.trim())
                      ? passIcon
                      : failIcon
                  }
                  alt=""
                />
              </>
            )}
            {fieldEdit.includes(index) ? (
              ""
            ) : (
              <FormIconButton
                type="editBlack"
                onClick={() => {
                  setIsShowCancelBtn(true);
                  setIsShowTest(false);
                  columnsAndDataDisplay(data, false);
                  fieldEdit.push(index);
                }}
              />
            )}
            <FormIconButton
              isShowPopconfirm
              type="delete"
              onClick={() => {
                let tmpArray = data?.ruleMetadata;
                const findIndex = tmpArray?.findIndex((item: any) => item?.ruleName == record?.ruleName);
                tmpArray.splice(findIndex, 1);
                fieldEdit.length = 0;
                onAddRule(tmpArray);
                setIsAddRule(false);
              }}
            />
          </div>
        );
      },
    });
    setScoreCardData(tableData);
    setTmpColumns(columns);
    dataFilterSearch(tableData);
  };

  const dataFilterSearch = (data?: any) => {
    const tmpData = data ?? scorecardData;
    const searchText = scoreCardSearchForm?.getFieldValue("searchRule");

    if ((searchText?.length ?? 0) == 0) {
      setScoreCardDataFilter(tmpData);
      return;
    }
    if (decisionData?.decisionType == "OFFER_DECISION") {
      let tmpScoreMap = tmpData?.map((item: any) => {
        const rules = item?.ruleName?.split("-");
        const getLenderName = eligibleLendersArr?.find(
          (lend: any) => lend?.key == rules?.at(0)
        );
        item["lenderName"] = getLenderName?.value;
        return item;
      });
      const filterSearch = tmpScoreMap?.filter((item: any) => {
        return (
          item?.ruleName == "" ||
          item?.lenderName?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });
      setScoreCardDataFilter(filterSearch);
    } else {
      const filterSearch = tmpData?.filter((item: any) => {
        return (
          item?.ruleName == "" ||
          item?.ruleName?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });
      setScoreCardDataFilter(filterSearch);
    }
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dataFilterSearch();
    dispatch(setScoreCardPageNo(current));
    dispatch(setScoreCardPageSize(pageSize));
  };

  const onAddRule = (newData: any) => {
    const payload = {
      currentPayload: {
        ...currentPayloadData,
        ruleMetadata: newData,
      },
      decisionType: decisionData?.decisionType,
      description: decisionData?.description,
      masterProductId: decisionData?.masterProductId,
      dsaBusinessId: decisionData?.id,
      name: decisionData?.name,
      purpose: decisionData?.purpose,
    };
    API_SERVICE.updateDecisioning(decisionData?.id, payload)
      .then(() => {
        getDraftList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const saveData = (values: any) => {
    let newData: any = [];
    let isRuleName = true;
    currentPayloadData?.ruleMetadata?.map((item: any, index: number) => {
      if (values.hasOwnProperty("ruleName" + index)) {
        const ruleNameTmp = values?.["ruleName" + index];
        if ((ruleNameTmp?.length ?? 0) == 0) {
          isRuleName = false;
        }
        if (decisionData?.decisionType == "OFFER_DECISION") {
          const rulePurposeTmp = values?.["ruleName" + index + "-purpose"];
          if ((rulePurposeTmp?.length ?? 0) == 0) {
            isRuleName = false;
          }
          item.ruleName = (ruleNameTmp ?? "") + "-" + (rulePurposeTmp ?? "");
        } else {
          item.ruleName = ruleNameTmp;
        }

        item?.decisioningActions?.map((item: any) => {
          const actionValues = values?.[`actionId_${item.actionId}` + index];
          if (item?.categoryId?.length > 0) {
            item.categoryId = values?.[`actionList_${item?.actionId}` + index];
            item.scoreOrReject = actionValues ?? "";
          } else {
            item.actionParamterValues = actionValues ?? "";
          }
        });

        item?.decisioningConditions?.map((itemCon: any) => {
          const valueCondition =
            values?.[`conditionId_${itemCon.conditionId}` + index];
          if (
            decisionData?.decisionType == "OFFER_DECISION" &&
            (valueCondition?.length ?? 0) > 0
          ) {
            const rulePurposeTmp = values?.["ruleName" + index + "-purpose"];
            return (itemCon.conditionParamterValues =
              `${ruleNameTmp ?? ""},${rulePurposeTmp ?? ""},` + valueCondition);
          }
          // const findExpression = currentPayloadData?.conditionMetadata?.find((item: any) => item?.conditionId == itemCon?.conditionId);
          // if (findExpression?.expression?.trim()?.includes('validatePincodeByLender')) {
          //   return (itemCon.conditionParamterValues = `${valueCondition},${values?.[`conditionId_${itemCon.conditionId}` + index + `-input`]}`)
          // }
          return (itemCon.conditionParamterValues = valueCondition);
        });
      }
      newData.push(item);
    });
    if (!isRuleName) {
      notification.error({ message: "Please enter all rule name" });
      return;
    }
    setIsShowTest(false);
    setIsAddRule(false);
    onAddRule(newData);
    setIsFinish(true);
    setIsShowCancelBtn(false);
  };

  const renderCancelConfirm = () => {
    return (
      <Modal
        width={300}
        open={isCancel}
        closable={false}
        footer={null}
        centered
      >
        <p style={{ textAlign: "center", fontSize: "20px" }}>
          Are you sure to cancel data?
        </p>
        <Space style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="large"
            style={{ width: "120px" }}
            onClick={() => setIsCancel(false)}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "120px" }}
            onClick={() => {
              getDraftList();
              setIsShowCancelBtn(false);
              fieldEdit.length = 0;
              setIsCancel(false);
            }}
          >
            Yes
          </Button>
        </Space>
      </Modal>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "15px",
        }}
      >
        <Form
          layout="inline"
          form={scoreCardSearchForm}
          style={{ marginBottom: "20px", marginLeft: "20px" }}
          onFinish={(values: any) => {
            fieldEdit.length = 0;
            dataFilterSearch();
            dispatch(setScoreCardPageNo(1));
            dispatch(setScoreCardSearchParam(values?.searchRule));
          }}
        >
          <Form.Item name="searchRule">
            <Input
              size="large"
              className="search-input"
              prefix={<img src={searchIcon} alt="" />}
              placeholder="Search"
              allowClear
            />
          </Form.Item>
          {/* <FormInput classNameForm="mt-0" name="search" isBorder /> */}
          <FormButton label="Search" htmlType="submit" />
        </Form>
        <Popconfirm
          placement="topRight"
          title={<div style={{width:"250px"}}>{"All rules will get over-written on using paste, please confirm to continue?"}</div>}
          onConfirm={async () => {
            const errorMsg = "Please click on Copy icon for chosen Rule to enable Paste Decisioning action"
            try {
              const data = await navigator.clipboard.readText();
              const payload = JSON.parse(data);
              if(payload?.conditionMetadata?.length > 0){ 
                setCurrentPayloadData(payload);
                setIsCreateTable(true);
                setIsShowCancelBtn(true);
                setIsShowTest(false);
              } else {
                API_SERVICE.handleErrors(errorMsg);
              }
            } catch (e: any) {
              API_SERVICE.handleErrors(errorMsg);
            }
          }}
        >
          <Button size="large" type="primary">
            Paste Rules
          </Button>
        </Popconfirm>
      </div>
      <Form form={scoreCardForm} onFinish={saveData}>
        <Table
          columns={tmpColumns}
          size="small"
          onChange={(pagination: any) => {
            setIsFinish(true);
            handlePaginationChange(pagination.current, pagination.pageSize);
          }}
          pagination={{
            position: ["bottomLeft"],
            current: scoreCard?.pageNo,
            pageSize: scoreCard?.pageSize,
            total: scorecardDataFilter?.length,
            showSizeChanger: true,
          }}
          scroll={{ x: "max-content" }}
          dataSource={
            (scorecardDataFilter?.length ?? 0) > 0
              ? scorecardDataFilter
              : scorecardData
          }
          className="scorecard-tabel"
        />
        <div
          className="btns-tables"
          style={
            (scorecardDataFilter || scorecardData)?.length > 0
              ? { marginTop: "-45px" }
              : {}
          }
        >
          <FormButton
            label="Save"
            style={{ background: "#20A74E" }}
            type="primary"
            htmlType="submit"
          />
          {isShowCancelBtn ? (
            <>
              <Form.Item>
                {/* <Popconfirm
                  title="Are you sure to cancel data?"
                  okText="Yes"
                  cancelText="No"
                  style={{ position: "fixed" }}
                  className="remove"
                  placement="topLeft"
                  onConfirm={() => {
                    getDraftList();
                    setIsShowCancelBtn(false);
                    fieldEdit.length = 0;
                  }}
                > */}
                <Button
                  htmlType={"button"}
                  style={{
                    height: "40px",
                    width: "90px",
                    fontSize: "16px",
                  }}
                  onClick={() => setIsCancel(true)}
                >
                  Cancel
                </Button>
                {/* </Popconfirm> */}
              </Form.Item>
              {isCancel && renderCancelConfirm()}
            </>
          ) : (
            <>
              <FormButton
                label="Test"
                style={{ background: "#C71C8E" }}
                type="primary"
                onclick={() => {
                  if (fieldEdit?.length > 0 || isAddRule) {
                    notification.error({ message: "Please Save rule" });
                    return;
                  }
                  API_SERVICE.decisionVaildate(decisionData?.id)
                    .then((data) => {
                      setTestDecision(
                        currentPayloadData?.ruleMetadata?.length > 0
                      );
                    })
                    .catch((e: any) => API_SERVICE.handleErrors(e));
                }}
              />
              <FormButton
                label="Publish"
                type="primary"
                disabled={!isDataTested}
                onclick={() => {
                  setOpenPublishComment(
                    currentPayloadData?.ruleMetadata?.length > 0
                  );
                }}
              />
            </>
          )}
        </div>
      </Form>
      <Modal
        title="Loan Offer"
        open={isLoanOffer}
        onCancel={() => setIsLoanOffer(false)}
        footer={false}
      >
        <OfferTestOutput offerTestedData={loanOfferData} />
      </Modal>
    </>
  );
};

export default ScoreCard;
