import { Checkbox, Collapse, Form } from "antd";
import FormInput from "../../../../shared/component/FormInput/FormInput";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../../shared/services/api-services";
import "../ProductProperty.scss";

interface ICollectionProperty {
  setSelectedCollectionProperty: any;
  selectedCollectionProperty: any;
}

const { Panel } = Collapse;

const CollectionProperty: React.FunctionComponent<ICollectionProperty> = (
  props: ICollectionProperty
) => {
  const { setSelectedCollectionProperty, selectedCollectionProperty } = props;
  const [collectionList, setCollectionList] = useState([] as any);

  useEffect(() => {
    masterCollectionProperty();
  }, []);

  const masterCollectionProperty = (searchParam: any = null) => {
    const parms = {
      name: searchParam,
      pageSize: 100,
    };
    API_SERVICE.getCollectionList(parms)
      .then(({ data }) => {
        const displayNameList = data?.payload?.content?.map((item: any) => {
          return {
            key: item?.id,
            value: item?.name,
            children: item?.properties,
          };
        });
        setCollectionList(displayNameList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  return (
    <div className="decisioning-form">
      <FormInput
        label="Search"
        name="search"
        classNameForm="space-input"
        onChange={(e: any) => {
          masterCollectionProperty(e?.target?.value);
        }}
        isBorder={true}
      />
      <Form.Item name="properties">
        <div
          className="preview-box"
          style={{ marginLeft: "0px", height: "225px" }}
        >
          <div className="preview-name-div">
            <label>Properties</label>
          </div>

          <div
            className="preview-marging"
            style={{ height: "200px", overflow: "auto" }}
          >
            <ul style={{ width: "100%" }}>
              {collectionList.map((data: any, index: number) => {
                return (
                  <>
                    <li style={{ listStyleType: "none", marginLeft: "-15px" }}>
                      <Collapse expandIconPosition="right" ghost size="small">
                        <Panel
                          showArrow={(data?.children?.length ?? 0) > 0}
                          collapsible={
                            (data?.children?.length ?? 0) > 0
                              ? "icon"
                              : "disabled"
                          }
                          header={
                            <Checkbox
                              key={index}
                              value={data?.key}
                              onChange={() => {
                                let tmp = [...selectedCollectionProperty];
                                if (
                                  selectedCollectionProperty?.includes(
                                    data?.key
                                  )
                                ) {
                                  tmp = selectedCollectionProperty?.filter(
                                    (item: any) => item != data?.key
                                  );
                                } else {
                                  tmp.push(data?.key);
                                }
                                setSelectedCollectionProperty(tmp);
                              }}
                            >
                              {data.value}
                            </Checkbox>
                          }
                          key={index}
                        >
                          {data?.children?.map((item: any, index1: number) => {
                            const isLast = index1 == data?.children?.length - 1;
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "4px",
                                }}
                              >
                                <div
                                  className="line-vertical"
                                  style={isLast ? { height: "11px" } : {}}
                                />
                                <div>
                                  <div className="child-view">
                                    <div className="line-horizontal" />
                                    {item?.displayName}
                                  </div>
                                </div>
                              </div>
                              // <div
                              //   style={{
                              //     marginLeft: "10px",
                              //     borderLeft: "1px solid gray",
                              //   }}
                              // >
                              //   &nbsp;&nbsp;{item?.displayName}
                              // </div>
                            );
                          })}
                        </Panel>
                      </Collapse>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </Form.Item>
    </div>
  );
};

export default CollectionProperty;
