import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import search from "../../../assets/images/search.svg";
import {
  setJourneyPageNo,
  setJourneyPageSize,
  setJourneySearchParam,
} from "../../../shared/redux/journey-reducer";
import { Button, Dropdown, Form, Input, Menu } from "antd";
import { API_SERVICE } from "../../../shared/services/api-services";
import {
  EllipsisOutlined,
  WarningOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import JourneyDetails from "./JourneyDetails";
import { viewTypeProduct } from "../Products/Products";
import {
  setJourneyObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import ProductJourney from "../ProductJourney/ProductJourney";
import AddProductJourney from "../ProductJourney/AddProductJourney";
import PublishDetails from "../Products/PublishDetails";
import StartForm from "./StartForm/StartForm";
import { getEnumValue } from "../../../shared/Utility/Utility";
import JourneyPublishComment from "../ProductJourney/JourneyPublishComment";
import AssignConnector from "./AssignConnector";
import { MdOutlineEdit } from "react-icons/md";
import FormButton from "../../../shared/component/FormButton/FormButton";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";

interface IJourney {
  productWorkflowId: any;
  masterproductId: any;
  productData: any;
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  getProductList: any;
  setProductWorkflowId: any;
}

export type viewTypeJourneyProduct =
  | "journey"
  | "journeyDetails"
  | "addJourney"
  | "startForm"
  | "publishDetails"
  | "publish";

const Journey: React.FC<IJourney> = (props: IJourney) => {
  const {
    productWorkflowId,
    masterproductId,
    productData,
    setViewType,
    getProductList,
    setProductWorkflowId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [journeyMasterData, setJourneyMasterData] = useState([] as any);
  const [viewTypeJourney, setViewTypeJourney] =
    useState<viewTypeJourneyProduct>("journey");
  const [selectedJourney, setSelectedJourney] = useState({} as any);
  const [showAddConnector, setShowAddConnector] = useState(false);
  const [
    complementorBorrowerDirectJourneys,
    setComplementorBorrowerDirectJourneys,
  ] = useState(undefined as any);
  const [workflowType, setWorkflowType] = useState("");
  const dispatch = useDispatch();
  const [searchJourneyForm] = Form.useForm() as any;
  const journey: any = useSelector((state: RootState) => state.journey);
  const product: any = useSelector((state: RootState) => state.product);

  useEffect(() => {
    dispatch(setJourneyPageNo(1));
    fetchMasterProductList(
      journey.pageNo,
      journey.pageSize,
      journey.searchParam
    );
    searchJourneyForm.setFieldsValue({
      searchParam: journey?.searchParam ?? "",
    });
  }, []);

  const fetchMasterProductList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
      name: searchParam,
      direction: "DESC",
    };
    setIsLoading(true);
    API_SERVICE.fetchMasterProductList(masterproductId, payload)
      .then(({ data }: any) => {
        setJourneyMasterData(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMenuClick = (e: any, record: any) => {
    setProductWorkflowId(record?.id);
    journeyData(record);
    setWorkflowType(record?.workflowType);
    if (e.key == "journey") {
      dispatch(setScreenTitle("Product Journeys"));
      setViewTypeJourney("journeyDetails");
    } else if (e.key == "startForm") {
      setViewTypeJourney("startForm");
      dispatch(setScreenTitle("Product Journeys"));
    } else if (e?.key == "publish") {
      setViewTypeJourney("publish");
      dispatch(setScreenTitle("Product Journeys"));
    }
  };

  const journeyData = (data: any) => {
    let param = {
      name: data?.name,
      //productsCategory: data?.category,
      //productsType: data?.type,
      description: data?.description,
      // productsId: data?.productId,
    };
    dispatch(setJourneyObject(param));
  };

  const Journey =
    complementorBorrowerDirectJourneys ??
    productData?.mapMasterProductLoanProductDetail?.masterProductData
      ?.complementorBorrowerDirectJourneys;
  const columns: ColumnsType<any> = [
    {
      title: "Jouney Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Workflow Type",
      dataIndex: "workflowType",
      key: "workflowType",
      render: (rec: any) => getEnumValue("WORKFLOW_TYPE", rec),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Connector Id(Enabled)",
      dataIndex: "connectorId",
      key: "description",
      width: "220px",
      render: (_: any, rec: any) => {
        const filtered = Journey?.filter(
          (item: any) => item?.journeyId == rec?.id
        );
        if (rec?.workflowType == "BORROWER_DIRECT") {
          return (
            <div>
              {filtered
                ?.map((item: any) => `${item?.connectorId}(${item?.enable})`)
                .join(", ")}
              <FormIconButton
                type="plus"
                onClick={() => {
                  setSelectedJourney(rec);
                  setShowAddConnector(true);
                }}
              />
            </div>
          );
        }
        return <></>;
      },
    },
    {
      title: "Published Comment",
      dataIndex: "publishDetails",
      key: "publishedComment",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.publishedProductWorkflowResponse ? (
              <a
                style={{ color: "#008dff" }}
                onClick={() => {
                  setViewTypeJourney("publishDetails");
                  setProductWorkflowId(record?.id);
                  journeyData(record);
                }}
              >
                Details
              </a>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "configStepStatus",
      key: "action",
      align: "center",
      fixed: "right",
      width: 180,
      render: (_: any, record: any) => {
        const capitalize = (str: string) =>
          str.charAt(0).toUpperCase() + str.slice(1);
        const filteredList = _?.filter((item: any) =>
          record?.workflowType == "ASSISTED" ? item?.key != "startForm" : item
        );
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)}>
            {filteredList?.map((option: any, i: any) => (
              <Menu.Item
                disabled={!option.active}
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                key={option.key}
              >
                {i + 1} .{" "}
                <span style={{ paddingRight: "8px" }}>
                  {capitalize(option.key)}
                </span>{" "}
                <span
                  style={
                    option.key != "properties"
                      ? {
                          color:
                            option.state == "COMPLETED" ? "inherit" : "orange",
                        }
                      : {}
                  }
                >
                  {option.state == "COMPLETED" ? (
                    <CheckOutlined />
                  ) : (
                    <WarningOutlined />
                  )}
                </span>
              </Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              className="ellipsis-button"
              icon={
                <EllipsisOutlined
                  style={{ color: "black", fontSize: "30px" }}
                />
              }
            />
          </Dropdown>
        );
      },
    },
  ];

  const handleSearch = (values: any) => {
    dispatch(setJourneyPageNo(1));
    dispatch(setJourneySearchParam(values?.searchParam));
    fetchMasterProductList(1, journey?.pageSize, values.searchParam);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setJourneyPageNo(current));
    dispatch(setJourneyPageSize(pageSize));
    fetchMasterProductList(current, pageSize, journey?.searchParam);
  };

  const handleOpenAddJourney = () => {
    setViewTypeJourney("addJourney");
  };

  const renderJourneyList = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form
            layout="inline"
            form={searchJourneyForm}
            onFinish={handleSearch}
          >
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button
              type="link"
              onClick={() => {
                setViewType("product");
                dispatch(setScreenTitle("Product"));
                // dispatch(setProductsObject({}));
                getProductList(
                  product?.pageNo,
                  product?.pageSize,
                  product.searchParam
                );
              }}
            >
              Back to Product
            </Button>
            <Button
              type="primary"
              style={{ height: "40px" }}
              onClick={handleOpenAddJourney}
            >
              Add New Journey
            </Button>
          </div>
        </div>
        <div className={"layout-main"}>
          <Table
            columns={columns}
            dataSource={journeyMasterData}
            size="middle"
            loading={isLoading}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: journey.pageNo,
              pageSize: journey.pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    );
  };

  const renderView = () => {
    if (viewTypeJourney == "journeyDetails") {
      return (
        <>
          {/* {workflowType == "ASSISTED" ? (
            <>
              <ProductJourney
                productWorkflowId={productWorkflowId}
                fetchMasterProductList={fetchMasterProductList}
                setViewTypeJourney={setViewTypeJourney}
              />
            </>
          ) : (
            <> */}
          <JourneyDetails
            productWorkflowId={productWorkflowId}
            setViewTypeJourney={setViewTypeJourney}
            masterproductId={masterproductId}
            fetchMasterProductList={fetchMasterProductList}
          />
          {/* </>
          )} */}
        </>
      );
    } else if (viewTypeJourney == "addJourney") {
      return (
        <AddProductJourney
          productWorkflowId={productWorkflowId}
          masterproductId={masterproductId}
          setViewTypeJourney={setViewTypeJourney}
          fetchMasterProductList={fetchMasterProductList}
        />
      );
    } else if (viewTypeJourney == "startForm") {
      return (
        <StartForm
          setViewTypeJourney={setViewTypeJourney}
          fetchMasterProductList={fetchMasterProductList}
          productWorkflowId={productWorkflowId}
        />
      );
    } else if (viewTypeJourney == "publishDetails") {
      return (
        <PublishDetails
          productWorkflowId={productWorkflowId}
          setViewTypeJourney={setViewTypeJourney}
          fetchMasterProductList={fetchMasterProductList}
        />
      );
    } else if (viewTypeJourney == "publish") {
      return (
        <JourneyPublishComment
          setViewTypeJourney={setViewTypeJourney}
          productWorkflowId={productWorkflowId}
          fetchMasterProductList={fetchMasterProductList}
        />
      );
    } else {
      return renderJourneyList();
    }
  };
  return (
    <>
      {renderView()}
      {showAddConnector && (
        <AssignConnector
          setShowAddConnector={setShowAddConnector}
          showAddConnector={showAddConnector}
          selectedJourney={selectedJourney}
          mapMasterProductLoanProductDetail={
            productData?.mapMasterProductLoanProductDetail
          }
          setComplementorBorrowerDirectJourneys={
            setComplementorBorrowerDirectJourneys
          }
        />
      )}
    </>
  );
};

export default Journey;
