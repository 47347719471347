import { useEffect, useRef, useState } from "react";
import { Form, message, notification, Typography } from "antd";
import logo from '../../assets/images/logo.svg'
import FormInput from "../../shared/component/FormInput/FormInput";
import './SignIn.scss'
import FormButton from "../../shared/component/FormButton/FormButton";
import { NUMBER_REGEX } from "../../shared/AppConstants";
import ResetPassword from "./ResetPassword";
import { API_SERVICE } from "../../shared/services/api-services";
import ReCAPTCHA from "react-google-recaptcha";

const { Title } = Typography;

export type formType = "Email" | "otp" | "resetPassword";

const ForgotPassword = () => {

  const [typeForm, setTypeForm] = useState("Email" as formType);
  const [getEmail, setGetEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [captchaVisible,setCaptchaVisible]=useState<boolean>(false);
  const recapchaRef=useRef<ReCAPTCHA|null>(null);
  const [reCaptchaToken, setReCaptchaToken] = useState("") as any

  const time = 5 * 2 - 1;

  const [countDown, setCountDown] = useState(time);
  const [runTimer, setRunTimer] = useState(true);
  const [isTimeStoped, setIsTimeStoped] = useState(false);

  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  useEffect(() => {
    if (countDown === 0) {
      setIsTimeStoped(true);
    }
  });

  const getOTP = (email: string) => {
    handleCaptcha(email);
    API_SERVICE.getOtp(email)
      .then(({ data }) => {
        if (data) {
          setTypeForm("otp");
          setGetEmail(email);
          setVerificationId(data?.payload?.id);
          setCountDown(time);
          setIsTimeStoped(false);
          setRunTimer(true);
        }
      }).catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const verifyOTP = (otp: any) => {
    const payload = {
      "emailId": getEmail,
      "emailOtp": otp,
      "mobileNumber": "",
      "mobileOtp": "",
      "verificationId": verificationId,
    }
    API_SERVICE.verifyOtp(payload)
      .then(({ data }) => {
        if (data) {
          setTypeForm("resetPassword");
        }
      }).catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  }

  async function handleCaptcha(email:any){
    if(email.length > 0) {
      const payload = {
        email: email,
        reCaptchaScreenName:"OTP"
      }
      setLoading(true)
      setCaptchaVisible(false)
      API_SERVICE.checkCapchaRequired(payload)
      .then(({ data }) => {
        setCaptchaVisible(data?.payload?.captchaRequired ?? false);
        setReCaptchaToken("")
      })
      .finally(() => setLoading(false));
  }
}
  const handleFormSubmit = (values: any) => {
    if((reCaptchaToken.length === 0 && captchaVisible)){
      notification.error({ message: "reCaptcha is not checked"});
    }
    else{
    setReCaptchaToken("")
      setCaptchaVisible(false)
    if (typeForm === "Email") {
        getOTP(values?.email);
    } else{
      handleCaptcha(getEmail)
      verifyOTP(values?.otp);
    }
  }
  }
  const actionResend = () => {
    if (countDown === 0) {
      if((reCaptchaToken.length === 0 && captchaVisible)){
        notification.error({ message: "reCaptcha is not checked"});
        return;
      } 
      getOTP(getEmail);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Form
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          {typeForm === "Email" && (
            <>
              <FormInput
                label="Enter Email"
                classNameForm="space-input"
                isBorder={true}
                name="email"
                placeholder="Enter Email"
                requiredLabel="email"
                onBlur={(e) => handleCaptcha(e.target.value)}
                isRequired
                rules={[{ type: "email", message: "Please enter valid email" }]}
              />
              {captchaVisible &&(
                <ReCAPTCHA sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY"  onChange={(token)=>setReCaptchaToken(token)}  ref={recapchaRef}/>)}
              <FormButton
                size="large"
                label="Send OTP"
                type="primary"
                htmlType="submit"
                loading={loading}
              />
            </>
          )}
          {typeForm === "otp" && (
            <>
              <FormInput
                label="Enter OTP"
                classNameForm="space-input"
                isBorder={true}
                name="otp"
                placeholder="Enter OTP"
                requiredLabel="OTP"
                type="password"
                isRequired
                rules={[{
                  pattern: NUMBER_REGEX,
                  message: "Please enter valid OTP"
                }]}
              />

              {captchaVisible && (
                <ReCAPTCHA 
                  sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY"  
                  onChange={(token)=>setReCaptchaToken(token)}  
                  ref={recapchaRef}
                />
              )}              

              <Form.Item>
                <span>Didn't receive the OTP? &nbsp;</span>
                <a onClick={actionResend} style={{ color: "#C71C8E" }}>
                  {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}
                  <br />
                </a>
              </Form.Item>
            
             <FormButton
                size="large"
                label="Set New Password"
                type="primary"
                htmlType="submit"
              />
            </>
          )}
        </Form>
      </>
    )
  };

  return (
    <>

      <div className="login-card">
        <div className="logo-style" >
          <img
            src={logo} />
        </div>
        <Title className="logo-style"> Forgot Password </Title>
        {typeForm === "resetPassword" ? (
          <ResetPassword
            getEmail={getEmail}
          />
        ) :
          renderForm()
        }
      </div>
    </>
  );
};

export default ForgotPassword;
