import { Table } from "antd";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import { setPublishListPageNo, setPublishListPageSize } from "../../../shared/redux/publishList-reducer";
import moment from "moment";

interface IPublishHistory {
    decisionData: any;
}

const PublishHistory: React.FunctionComponent<IPublishHistory> = (props: IPublishHistory) => {
    const { decisionData } = props;

    const [loading, setLoading] = useState(false);
    const [publishDataList, setPublishDataList] = useState([] as any);
    const [totalCount, setTotalCount] = useState(0);
    const publishList: any = useSelector((state: RootState) => state.publishList);
    const dispatch = useDispatch();

    useEffect(() => {
        getDecisioningPublishList();
    }, []);

    const getDecisioningPublishList = (
        pageNo: number = 1,
        pageSize: any = 10,
    ) => {
        setLoading(true);
        const params = {
            decisioningMasterIdFilter: decisionData?.id,
            pageNo: pageNo,
            pageSize: pageSize,
        };
        API_SERVICE.getDecisioningPublishList(params)
            .then(({ data }) => {
                setPublishDataList(data?.payload?.content ?? []);
                setTotalCount(data?.payload?.totalElements ?? 0);
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns: any = [
        {
            title: 'User Name',
            dataIndex: "userName",
            key: 'userName',
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Publish Date',
            dataIndex: 'publishDate',
            key: 'publishDate',
            render: (record: any) => (
                moment(record).format("DD MMM YYYY")
            )
        },
        {
            title: 'Publish Version',
            dataIndex: 'publishVersion',
            key: 'publishVersion',
            render: (record: any) => (
                <span>V{record}</span>
            )
        },
        {
            title: 'Last Comment',
            dataIndex: 'publishingComment',
            key: 'publishingComment',
        }
    ]

    const handlePaginationChange = (current: any, pageSize: any) => {
        dispatch(setPublishListPageNo(current));
        dispatch(setPublishListPageSize(pageSize));
        getDecisioningPublishList(current, pageSize);
    };

    return (
        <>
            <div className={"layout-main"}>
                <div>
                    <Table
                        columns={columns}
                        dataSource={publishDataList}
                        size='middle'
                        loading={loading}
                        onChange={(pagination: any) => {
                            handlePaginationChange(pagination.current, pagination.pageSize);
                        }}
                        pagination={{
                            position: ['bottomCenter'],
                            current: publishList.pageNo,
                            pageSize: publishList.pageSize,
                            total: totalCount
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div>
        </>
    );
};

export default PublishHistory;
