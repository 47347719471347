import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import "./FormTextArea.scss";

const { TextArea } = Input;

interface IFormTextArea {
    name: string;
    type?: string;
    label?: string;
    classNameForm?: string;
    placeholder?: string;
    prefix?: string;
    isRequired?: boolean;
    requiredLabel?: string;
    isDisabled?: boolean;
    isBorder?: boolean;
    isUpperCase?: boolean;
    rules?: Rule[] | undefined;
    defaultValue?: string;
    style?: any;
    row?: number;
    onChange?: any;
    wrap?:any;
}

const FormTextArea = (props: IFormTextArea) => {
    const {
        name,
        type,
        label,
        classNameForm,
        isRequired,
        requiredLabel,
        prefix,
        isBorder,
        isDisabled,
        isUpperCase,
        placeholder,
        rules,
        defaultValue,
        style,
        row,
        wrap,
        onChange
    } = props;

    const toInputUppercase = (e: any) => {
        if (isUpperCase) {
            e.target.value = ("" + e.target.value).toUpperCase();
        }
    };

    const typeTmp = type == "number" ? "text" : type;
    return (
        <>
            <Form.Item
                className={`fieldText-bg ${isBorder ? "fieldText-border" : ""} ` + classNameForm}
                style={style}
                name={name}
                label={label}
                rules={[
                    {
                        required: isRequired,
                        message: `Please enter your ${requiredLabel ?? label}`,
                    },
                    ...(rules ?? []),
                ]}
            >
                <TextArea
                    size="middle"
                    placeholder={placeholder}
                    onInput={toInputUppercase}
                    disabled={isDisabled}
                    prefix={prefix}
                    defaultValue={defaultValue}
                    rows={row ?? 3}
                    onChange={onChange}
                    wrap={wrap}
                />
            </Form.Item>
        </>
    );
};

export default FormTextArea;
