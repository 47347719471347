import { configureStore } from "@reduxjs/toolkit";
import propertyReducer from "./property-reducer";
import collectionReducer from "./collection-reducer";
import alertReducer from "./alert-reducer";
import commonReducer from "./common-reducer";
import tenantReducer from "./tenant-reducer";
import productReducer from "./product-reducer";
import documentTypeReducer from "./documentType-reducer";
import productDocumentReducer from "./producDocument-reducer";
import couponsReducer from "./coupons-reducer";
import categoryReducer from "./category-reducer";
import decisioningReducer from "./decisioning-reducer";
import publishListReducer from "./publishList-reducer";
import productPropertyReducer from "./productProperty-reducer";
import documentReducer from "./document-reducer";
import scoreCardReducer from "./scoreCard-reducer";
import publishReducer from "./publish-reducer";
import offerCalculationReducer from "./offerCalculation-reducer";
import offerCalculationPublishReducer from "./offerCalculationPublish-reducer";
import dispositionReducer from "./disposition-reducer";
import journeyReducer from "./journey-reducer";

const store = configureStore({
  reducer: {
    common: commonReducer,
    property: propertyReducer,
    collection: collectionReducer,
    alert: alertReducer,
    tenant: tenantReducer,
    product: productReducer,
    decisioning: decisioningReducer,
    scoreCard: scoreCardReducer,
    publish: publishReducer,
    documentType: documentTypeReducer,
    productDocument: productDocumentReducer,
    coupons: couponsReducer,
    category: categoryReducer,
    publishList: publishListReducer,
    productProperty: productPropertyReducer,
    document: documentReducer,
    offerCalculation: offerCalculationReducer,
    OfferCalculationPublish: offerCalculationPublishReducer,
    disposition: dispositionReducer,
    journey: journeyReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
