import { Spin } from "antd";
import React from "react";
import "./AppLoading.scss";

interface IAppLoading {}

const AppLoading: React.FunctionComponent<IAppLoading> = (
  props: IAppLoading
) => {
  return (
    <div className="loader-view">
      <Spin tip="We are processing your request, please wait" size="large" > </Spin>
    </div>
  );
};

export default AppLoading;
