import React, { useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { Form, Modal, notification, Space } from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";

interface IAssignConnector {
  showAddConnector: boolean;
  setShowAddConnector: any;
  selectedJourney: any;
  mapMasterProductLoanProductDetail: any;
  setComplementorBorrowerDirectJourneys: any;
}

const AssignConnector: React.FC<IAssignConnector> = (
  props: IAssignConnector
) => {
  const {
    showAddConnector,
    setShowAddConnector,
    selectedJourney,
    mapMasterProductLoanProductDetail,
    setComplementorBorrowerDirectJourneys,
  } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = (value: any) => {
    setLoading(true);
    const params = {
      connectorId: value?.connectorId,
      enable: true,
      journeyId: selectedJourney?.id,
    };
    API_SERVICE.mapJourneyConnector(
      mapMasterProductLoanProductDetail?.tenantId,
      mapMasterProductLoanProductDetail?.loanProductId,
      params
    )
      .then(({ data }) => {
        notification.success({message:"Connector map successfully!"})
        setComplementorBorrowerDirectJourneys(data?.payload?.masterProductData?.complementorBorrowerDirectJourneys)
        setShowAddConnector(false);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        title={"Enter Connector Id"}
        open={showAddConnector}
        closable={false}
        footer={false}
        width={400}
      >
        <Form layout="vertical" onFinish={(value) => onFinish(value)}>
          <FormInput
            name="connectorId"
            isBorder
            size="small"
            rules={[
              {
                required: true,
                message: `Please enter connector id`,
              },
            ]}
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "right",
              paddingTop: "15px",
            }}
          >
            <FormButton
              label="Cancel"
              onclick={() => setShowAddConnector(false)}
              style={{ width: "100px", height: "40px" }}
            />
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100px", height: "40px" }}
            />
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AssignConnector;
