import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Input, Row, Space, Typography } from 'antd';
import FormButton from '../../../../shared/component/FormButton/FormButton';
import { setAlertMsg } from '../../../../shared/redux/alert-reducer';
import { useDispatch } from 'react-redux';
import { API_SERVICE } from '../../../../shared/services/api-services';
import FormInput from '../../../../shared/component/FormInput/FormInput';
import dayjs from 'dayjs';
import "../../properties.scss"

const { Title } = Typography;
interface IAddRules {
    selectedStage: number;
    setSelectedStage: Function;
    setAddPropery: any;
    formData1: any;
    formData2: any;
    isEdit: any;
    editPropertyData: any;
    getPropertyList: any;
}

const AddRules: React.FC<IAddRules> = (props: IAddRules) => {
    const {
        selectedStage,
        setSelectedStage,
        setAddPropery,
        formData1,
        formData2,
        isEdit,
        editPropertyData,
        getPropertyList
    } = props

    const [addRuleForm] = Form.useForm() as any;
    const dispatch = useDispatch();
    const [ruleList, setRuleList] = useState([] as any);

    const isValidate = (key: string) => {
        return editPropertyData?.validations?.some((item: any) => item?.validation == key);
    };

    const checkFieldDate = formData2?.fieldType?.toUpperCase() === "DATE"
    const checkDoubleType = formData2?.fieldType?.toUpperCase() === "DOUBLE"

    const numberArray = [
        {
          key: "min",
          value: `Set Min ${
            checkFieldDate
              ? "Date"
              : ["NUMBER", "DOUBLE"].includes(formData2?.fieldType?.toUpperCase())
              ? ""
              : "Char Limit"
          }`,
          isChecked: isValidate("min"),
          children: checkFieldDate ? (
            <Form.Item name="min">
              <DatePicker />
            </Form.Item>
          ) : (
            <FormInput
              name="min"
              type="number"
              rules={[
                () => ({
                    validator(_, value) {
                        if(value < 0){
                            return Promise.reject('please enter valid number')
                        }
                        return Promise.resolve();
                    }
                })
              ]}
              precision={checkDoubleType ? 2 : 0}
              step={checkDoubleType ? 0.01 : 1}
            />
          ),
        },
        {
          key: "max",
          value: `Set Max ${
            checkFieldDate
              ? "Date"
              : ["NUMBER", "DOUBLE"].includes(formData2?.fieldType?.toUpperCase())
              ? ""
              : "Char Limit"
          }`,
          isChecked: isValidate("max"),
          children: checkFieldDate ? (
            <Form.Item name="max">
              <DatePicker />
            </Form.Item>
          ) : (
            <FormInput
              name="max"
              type="number"
              rules={[
                () => ({
                    validator(_, value) {
                        if(value > addRuleForm.getFieldValue('min')){
                            return Promise.resolve();
                        }
                        return Promise.reject('Please enter value grater then min value')
                    }
                })
              ]}
              precision={checkDoubleType ? 2 : 0}
              step={checkDoubleType ? 0.01 : 1}
            />
          ),
        },
      ];

    const renderDataValues = () => (
        <>
            <Form.List name="dataValues">
                {(fields, { add, remove }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <p>key</p>
                            <p>value</p>
                        </div>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, 'key']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                >
                                    <Input placeholder="Key" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'value']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                >
                                    <Input placeholder="value" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <FormButton
                            size="middle"
                            label="Add Field"
                            type="primary"
                            onclick={() => add()}
                        />
                    </>
                )}
            </Form.List>
        </>
    )

    const textArray = [
        {
            key: "minLength",
            value: "Set min char limit",
            isChecked: isValidate("minLength"),
            children: (<FormInput name='minLength' type='number' min={1} />),

        },
        {
            key: "maxLength",
            value: "Set max char limit",
            isChecked: isValidate("maxLength"),
            children: (<FormInput name='maxLength' type='number' min={1} />)
        },
        {
            key: "dataValues",
            value: "Set Key Values",
            isChecked: isValidate("dataValues"),
            children: renderDataValues()
        },
    ]

    const findData = (key: string) => {
        return editPropertyData?.validations?.find((item: any) => item?.validation == key);
    }

    useEffect(() => {
        if (formData2?.fieldType?.toUpperCase() === "TEXT") {
            setRuleList(textArray);
            addRuleForm.setFieldsValue({
                minLength: findData("minLength")?.value,
                maxLength: findData("maxLength")?.value,
                dataValues: findData("dataValues")?.value
            });
        } else if (["NUMBER", "DOUBLE"].includes(formData2?.fieldType?.toUpperCase())) {
            setRuleList(numberArray);
            addRuleForm.setFieldsValue({
                min: findData("min")?.value,
                max: findData("max")?.value
            });
        } else if (checkFieldDate) {
            setRuleList(numberArray);
            addRuleForm.setFieldsValue({
                min: dayjs(findData("min")?.value),
                max: dayjs(findData("max")?.value)
            });
        } else {
            setRuleList([]);
        }
    }, []);

    const addProperty = (payload: any) => {
        API_SERVICE.addProperty(payload)
            .then(({ data }) => {
                if (data) {
                    setAddPropery(false);
                    dispatch(setAlertMsg({ message: 'Property was created successfully.', description: 'Property add in property list.', showAlertMessage: true }));
                    getPropertyList();
                }
            }).catch((e) => {
                API_SERVICE.handleErrors(e);
            })
    };

    const updateProperty = (payload: any) => {
        const id = editPropertyData?.id;
        API_SERVICE.updateProperty(id, payload)
            .then(({ data }) => {
                if (data) {
                    setAddPropery(false);
                    dispatch(setAlertMsg({ message: 'Property was updated successfully.', description: 'Property update in property list.', showAlertMessage: true }));
                    getPropertyList();
                }
            }).catch((e) => {
                API_SERVICE.handleErrors(e);
            });
    }

    const handleCheckboxChange = (e: any) => {
        const tmpCheckboxArray = [...ruleList];
        let index1 = ruleList.findIndex((obj: any) => {
            return obj?.key === e?.target?.value;
        });
        tmpCheckboxArray[index1].isChecked = e?.target?.checked;
        setRuleList(tmpCheckboxArray);
    };

    const onNextClick = (values: any) => {
        const checkedRuleList = ruleList.filter((item: any) => item.isChecked);
        const tmpArray: any = [];
        const tmp = checkedRuleList.map((item: any) => {
            const tmpobj = {
                "validation": item.key,
                "value": checkFieldDate ? dayjs(values[`${item.key}`]).locale('es').format("YYYY-MM-DD") : values[`${item.key}`]
            }
            tmpArray.push(tmpobj);
            return tmpobj;
        })

        const payload = {
            "children": formData2?.propertyList,
            "displayName": formData1?.labelInput,
            "type": formData2?.propertyType === "complex" ? "OBJECT" : formData2?.fieldType?.toUpperCase(),
            "description": formData1?.description,
            "validations": tmpArray?.length == 0 ? null : tmpArray
        };
        if (isEdit) {
            updateProperty(payload);
        } else {
            addProperty(payload);
        }
    }


    return (
        <Form
            form={addRuleForm}
            layout="vertical"
            onFinish={onNextClick}
        >

            <Title level={5} style={{ textAlign: "center" }}><b>{formData1?.labelInput}</b></Title>
            <Title level={5}>Select Property Rules</Title>
            <Title level={5}>Validation Rules</Title>

            {ruleList.map((checkBox: any, i: number) => (
                <Row>
                    <Col>
                        {!checkBox.isShow &&
                            <Checkbox key={i} value={checkBox.key} checked={checkBox.isChecked}
                                onChange={(e: any) => handleCheckboxChange(e)}>
                                {checkBox.value}
                            </Checkbox>

                        }
                        {checkBox.isChecked ? checkBox?.children : null}
                    </Col>
                </Row >
            ))
            }
            <Row className='step-button'>
                <Space >
                    <FormButton
                        size="large"
                        label="Back"
                        type='default'
                        onclick={() => {
                            setSelectedStage(selectedStage - 1);
                        }}
                    />
                    <FormButton
                        size="large"
                        label="Next"
                        type="primary"
                        htmlType="submit"
                    />
                </Space>
            </Row>
        </Form >
    );
};

export default AddRules;