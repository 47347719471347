import { Button, Form, Modal } from "antd";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import Table, { ColumnsType } from "antd/es/table";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

interface IPasteOfferCalculation {
  getOfferCalculationList: any;
}

const PasteOfferCalculation: React.FC<IPasteOfferCalculation> = (
  props: IPasteOfferCalculation
) => {
  const { getOfferCalculationList } = props;
  const [loading, setLoading] = useState(false);
  const [failedResponses, setFailedResponses] = useState([] as any);
  const [validationFailed, setValidationFailed] = useState([] as any);
  const [lenderList, setLenderList] = useState([] as any);
  const [pastObj, setPastObj] = useState({} as any);
  const { productsObject } = useSelector((state: RootState) => state.common);

  const getLenderList = () => {
    // setIsLoading(true);
    API_SERVICE.listAllTheFinanciers()
      .then(({ data }) => {
        const lenders = data?.payload?.map((listVal: any) => {
          return {
            key: listVal?.businessInfoId,
            value: listVal?.businessName,
          };
        });
        setLenderList(lenders);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => {
        // getDraftList();
      });
  };

  const pasteOffer = (values: any) => {
    setFailedResponses([]);
    setLoading(true);
    const payload = { ...values, masterProductId: productsObject.productsId };
    API_SERVICE.offerCalculationPasteJson(payload)
      .then(({ data }) => {
        if (data?.payload?.failedResponses?.length == 0) {
          getOfferCalculationList();
        } else {
          if (lenderList.length === 0) {
            getLenderList();
          }
          setValidationFailed(data?.payload?.validationFailed);
          setFailedResponses(data?.payload?.failedResponses);
        }
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  const onSubmit = async () => {
    const errorMsg =
      "No offer calculations copied. Please copy the offer calculations to enable pasting";
    try {
      const data = await navigator.clipboard.readText();
      const values = JSON.parse(data);
      setPastObj(values);
      pasteOffer(values);
    } catch (e: any) {
      API_SERVICE.handleErrors(errorMsg);
    }
  };

  const columns: ColumnsType<columns> = [
    {
      title: "Lender Name",
      dataIndex: "oldBusinessName",
      key: "businessName",
    },
    {
      title: "Select Lender Name",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any, index: any) => {
        return (
          <FormSelect
            name={`name${record.oldBusinessId}`}
            options={lenderList}
            optionFilterProp="children"
            isBorder
            isRequired
            requiredLabel="Lender Name"
            style={{ minWidth: "100px", maxWidth: "250px" }}
            onChange={(e: any, option: any) => {
              const p = pastObj?.offerCalculationInfoJsons?.map((val: any) => {
                if (
                  val.lenderBusinessId === record.oldBusinessId ||
                  val.oldBusinessId === record.oldBusinessId
                ) {
                  return {
                    ...val,
                    oldBusinessId: val.oldBusinessId ?? val.lenderBusinessId,
                    lenderBusinessId: e,
                    lenderBusinessName: option.children,
                  };
                }
                return val;
              });
              setPastObj({ ...pastObj, offerCalculationInfoJsons: p });
            }}
          />
        );
      },
    },
  ];

  const columnError: ColumnsType<columns> = [
    {
      title: "Offer Calculation Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Error Reason",
      dataIndex: "errorReason",
      key: "action",
      width:700
    },
  ];

  const renderFailedResponses = () => {
    return (
      <Modal
        title={validationFailed ? "Please select correct Lender Name" : "Error"}
        width={validationFailed ?  600 : 1000}
        closable={false}
        open
        footer={null}
      >
        <Form
          onFinish={() => {
            if (validationFailed) {
              pasteOffer(pastObj);
            } else {
              setFailedResponses([]);
            }
          }}
        >
          <Table
            className="paste-error-table"
            columns={validationFailed ? columns : columnError}
            dataSource={failedResponses}
            size="middle"
            scroll={{ x: "max-content" }}
          />
          <div
            style={{ display: "flex", justifyContent: "right", gap: "10px" }}
          >
            <Button
              style={{ width: "100px" }}
              htmlType="button"
              onClick={() => setFailedResponses([])}
            >
              Cancel
            </Button>
            <Button style={{ width: "100px" }} htmlType="submit" type="primary">
              OK
            </Button>
          </div>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {failedResponses.length > 0 && renderFailedResponses()}
      <Button
        type="primary"
        style={{ height: "40px", marginLeft: "10px" }}
        onClick={() => onSubmit()}
        loading={loading}
      >
        Paste Offer Calculation
      </Button>
    </>
  );
};

export default PasteOfferCalculation;
