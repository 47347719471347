import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import search from "../../../assets/images/search.svg";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductPageNo,
  setProductPageSize,
  setProductSearchParam,
} from "../../../shared/redux/product-reducer";
import { RootState } from "../../../shared/redux/store";
import { API_SERVICE } from "../../../shared/services/api-services";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import {
  EllipsisOutlined,
  WarningOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import AddProducts from "./AddProducts";
import PublishDetails from "./PublishDetails";
import "./product.scss";
import Action from "../Action";
import Decisioning from "../Decisioning/Decisioning";
import {
  setProductsObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import DocumentScreen from "../DocumentScreen/DocumentScreen";
import ProductProperty from "../ProductProperty/ProductProperty";
import AddProductJourney from "../ProductJourney/AddProductJourney";
import OfferCalculation from "../OfferCalculation/OfferCalculation";
import Journey from "../ProductJourneyV2/Journey";
import AssignProduct from "./AssignProduct";
import { getEnumValue } from "../../../shared/Utility/Utility";
import ReactJson from "@microlink/react-json-view";
import { delay } from "lodash";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

export type viewTypeProduct =
  | "product"
  | "publish"
  | "actions"
  | "documents"
  | "decisioning"
  | "addProduct"
  | "properties"
  | "journey"
  | "offerCalculation"
  | "assignProduct";

type IProduct = {};

const Products: React.FunctionComponent<IProduct> = () => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [editProductData, setEditProductData] = useState(null as any);
  const [productList, setProductList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isAddDuplicate, setIsAddDuplicate] = useState(false);
  const [productWorkflowId, setProductWorkflowId] = useState(""); 
  const [masterproductId, setMasterProductId] = useState("");
  const [cloneResponse, setCloneResponse] = useState(undefined as any);
  const [viewType, setViewType] = useState<viewTypeProduct>("product");
  const [searchProductForm] = Form.useForm() as any;
  const product: any = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    getProductList(product.pageNo, product.pageSize, product.searchParam);
    decisioningData("");
    getEnumTypes();
  }, []);

  const getEnumTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        localStorage.setItem("enumValues", JSON.stringify(data));
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  useEffect(() => {
    if (viewType == "product") {
      dispatch(setScreenTitle("Product"));
    }
    searchProductForm.setFieldsValue({
      searchParam: product.searchParam ?? "",
    });
  }, [viewType]);

  const createList = (data: any) => {
    if (data?.payload?.content) {
      const productListItems: any = data.payload.content.map((item: any) => {
        return {
          configure: item?.additionalFields?.configSteps,
          productId: item.productId,
          name: item.name,
          additionalFields: item?.additionalFields,
          category: item.productType.category,
          type: item.productType.type,
          description: item.description,
          productWorkflowMappings: item?.productWorkflowMappings,
          mapMasterProductLoanProductDetail: item?.mapMasterProductLoanProductDetail,
        };
      });
      setProductList(productListItems);
    } else {
      setProductList([]);
    }
  };

  const getProductList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, name: searchParam };
    API_SERVICE.getProductList(params)
      .then(({ data }: any) => {
        createList(data);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionEdit = (record: any) => {
    API_SERVICE.getEditProductData(record.productId)
      .then(({ data }: any) => {
        setViewType("addProduct");
        setEditProductData(data?.payload);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMenuClick = (e: any, record: any) => {
    decisioningData(record);
    if (e.key == "documents") {
      dispatch(setScreenTitle("Documents"));
      setViewType("documents");
    } else if (e.key == "actions") {
      dispatch(setScreenTitle("Actions"));
      setViewType("actions");
    } else if (e.key == "decisioning") {
      dispatch(setScreenTitle("Decisioning"));
      setViewType("decisioning");
    } else if (e.key == "properties") {
      dispatch(setScreenTitle("properties"));
      setViewType("properties");
    } else if (e.key == "journey") {
      setEditProductData(record);
      setProductWorkflowId(record?.productWorkflowMappings?.at(0)?.id ?? "");
      setMasterProductId(record?.productId ?? "")
      dispatch(setScreenTitle("Product Journeys"));
      setViewType("journey");
    } else if (e.key == "offer calculation") {
      dispatch(setScreenTitle("Offer Calculation"));
      setViewType("offerCalculation");
    }
  };

  const decisioningData = (data: any) => {
    let param = {
      productsName: data?.name,
      productsCategory: data?.category,
      productsType: data?.type,
      description: data?.description,
      productsId: data?.productId,
    };
    dispatch(setProductsObject(param));
  };

  const columns: ColumnsType<columns> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (val:any) => getEnumValue('MASTER_PRODUCT_CATEGORY', val),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "250px",
    },
    {
      title: "Tenant Name",
      dataIndex:["mapMasterProductLoanProductDetail", "tenantName"],
      key: "tenantName",
    },
    {
      title: "Loan Product Name",
      dataIndex: ["mapMasterProductLoanProductDetail", "loanProductName"],
      key: "loanProductName",
    },
    {
      title: "Configure",
      dataIndex: "configure",
      key: "congigure",
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(e, record)}>
            {_?.map((option: any, i: any) => (
              <Menu.Item
                disabled={!option.active}
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                key={option.key}
              >
                {i + 1} .{" "}
                <span style={{ paddingRight: "8px" }}>
                  {capitalize(option.key)}
                </span>{" "}
                <span
                  style={
                    option.key != "properties"
                      ? {
                          color:
                            option.state == "COMPLETED" ? "inherit" : "orange",
                        }
                      : {}
                  }
                >
                  {option.state == "COMPLETED" ? (
                    <CheckOutlined />
                  ) : (
                    <WarningOutlined />
                  )}
                </span>
              </Menu.Item>
            ))}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              className="ellipsis-button"
              icon={
                <EllipsisOutlined
                  style={{ color: "black", fontSize: "30px" }}
                />
              }
            />
          </Dropdown>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => (
        <div style={{display:'flex'}}>
          {record?.additionalFields?.cloneMasterProductResponse != null ?
            <FormIconButton
              type="info"
              onClick={() => {
                const obj = {...record?.additionalFields}
                delete obj.configSteps;
                setCloneResponse(obj)
              }}
            /> 
            : <div style={{width:"30px"}}></div>
          }
          {(record?.mapMasterProductLoanProductDetail?.tenantName?.length ?? 0) == 0 ?
            <FormIconButton
              type="assignProduct"
              onClick={() => {
                decisioningData(record);
                setEditProductData(record);
                dispatch(setScreenTitle("Assign product"));
                setViewType("assignProduct");
              }}
            /> 
            : <div style={{width:"30px"}}></div>
          }
          <FormIconButton
            type="addDuplicate"
            onClick={() => {
              setIsAddDuplicate(true);
              setIsEdit(false);
              actionEdit(record);
            }}
          />
          <FormIconButton
            type={"edit"}
            onClick={() => {
              actionEdit(record);
              setIsAddDuplicate(false);
              setIsEdit(true);
            }}
          />
          {/* <Button onClick={() => actionStart(record)}>Start</Button> */}
        </div>
      ),
    },
  ];

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setProductPageNo(current));
    dispatch(setProductPageSize(pageSize));
    getProductList(current, pageSize, product.searchParam);
  };

  const handleSearch = (value: any) => {
    dispatch(setProductPageNo(1));
    dispatch(setProductSearchParam(value?.searchParam));
    getProductList(1, product.pageSize, value?.searchParam);
  };

  const renderProduct = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form
            layout="inline"
            form={searchProductForm}
            onFinish={handleSearch}
          >
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search by name"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                setViewType("addProduct");
              }}
              type="primary"
              style={{ height: "40px" }}
            >
              Add New Product
            </Button>
          </div>
        </div>

        <div className={"layout-main"}>
          <Row style={{ padding: "15px" }}>
            <Col xs={24} xl={24} span={13}>
              <Table
                columns={columns}
                dataSource={productList}
                size="middle"
                loading={loading}
                onChange={(pagination: any) => {
                  handlePaginationChange(
                    pagination.current,
                    pagination.pageSize
                  );
                }}
                pagination={{
                  position: ["bottomCenter"],
                  current: product.pageNo,
                  pageSize: product.pageSize,
                  total: totalCount,
                }}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderView = () => {
    if (viewType == "decisioning") {
      return (
        <Decisioning
          setViewType={setViewType}
          getProductList={getProductList}
        />
      );
    } else if (viewType == "documents") {
      return (
        <DocumentScreen
          setViewType={setViewType}
          getProductList={getProductList}
        />
      );
    } else if (viewType == "actions") {
      return (
        <Action setViewType={setViewType} getProductList={getProductList} />
      );
    }
    else if (viewType == "addProduct") {
      return (
        <AddProducts
          getProductList={getProductList}
          setIsEdit={setIsEdit}
          setIsAddDuplicate={setIsAddDuplicate}
          isAddDuplicate={isAddDuplicate}
          editProductData={editProductData}
          isEdit={isEdit}
          setViewType={setViewType}
        />
      );
    } else if (viewType == "properties") {
      return (
        <ProductProperty
          setViewType={setViewType}
          getProductList={getProductList}
        />
      );
    } else if (viewType == "journey") {
      return (
         <Journey productData={editProductData} productWorkflowId={productWorkflowId} masterproductId={masterproductId}  getProductList={getProductList}  setViewType={setViewType} setProductWorkflowId={setProductWorkflowId}/>
        // <AddProductJourney
        //   setViewType={setViewType}
        //   productWorkflowId={productWorkflowId}
        //   setProductWorkflowId={setProductWorkflowId}
        //   getProductList={getProductList}
        // />
      );
    } else if (viewType == "offerCalculation") {
      return (
        <OfferCalculation
          setViewType={setViewType}
          getProductList={getProductList}
        />
      );
    } else if (viewType == "assignProduct") {
      return (
        <AssignProduct setViewType={setViewType} productData={editProductData} getProductList={getProductList}/>
      );
    } else {
      return renderProduct();
    }
  };

  const renderCloneResponse = () => {
    return (
      <Modal
        title="Clone Response"
        open
        onCancel={() => setCloneResponse(undefined)}
        centered
        footer={false}
        width={1000}
      >
        <div
          style={{
            flex: 1,
            margin: "10px",
            maxHeight: "600px",
            overflow: "auto",
          }}
        >
          <ReactJson src={cloneResponse} displayDataTypes={false} />
        </div>
      </Modal>
    );
  };

  return <>{renderView()}{cloneResponse && renderCloneResponse()}</>;
};

export default Products;
