import React, { useEffect, useState } from "react";
import { Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { ReactComponent as Dashboard } from "../../../assets/images/sidebar/dashboard.svg";
import { ReactComponent as DashboardSelected } from "../../../assets/images/sidebar/dashboard-selected.svg";
import { ReactComponent as DataManagement } from "../../../assets/images/sidebar/data-management.svg";
import { ReactComponent as DataManagementSelected } from "../../../assets/images/sidebar/data-management-selected.svg";
import { ReactComponent as Book } from "../../../assets/images/sidebar/book.svg";
import { ReactComponent as BookSelected } from "../../../assets/images/sidebar/book-selected.svg";
import { ReactComponent as BookBlue } from "../../../assets/images/sidebar/book-blue.svg";
import { ReactComponent as Report } from "../../../assets/images/sidebar/report.svg";
import { ReactComponent as ReportSelected } from "../../../assets/images/sidebar/report-selected.svg";
import { ReactComponent as Page } from "../../../assets/images/sidebar/page.svg";
import { ReactComponent as PageSelected } from "../../../assets/images/sidebar/page-selected.svg";
import { ReactComponent as Shield } from "../../../assets/images/sidebar/shield.svg";
import { ReactComponent as ShieldSelected } from "../../../assets/images/sidebar/shield-selected.svg";
import { ReactComponent as Product } from "../../../assets/images/sidebar/product.svg";
import { ReactComponent as ProductSelected } from "../../../assets/images/sidebar/product-selected.svg";
import { ReactComponent as ProductManagementSelceted } from "../../../assets/images/sidebar/product-management-selected.svg";
import { ReactComponent as ProductManagement } from "../../../assets/images/sidebar/prodcut-management.svg";
import { ReactComponent as DecisioningSelected } from "../../../assets/images/sidebar/decisioning-select.svg";
import { ReactComponent as Decisioning } from "../../../assets/images/sidebar/decisioning.svg";
import { ReactComponent as Coupons } from "../../../assets/images/sidebar/coupon.svg";
import { ReactComponent as CouponsSelected } from "../../../assets/images/sidebar/coupon-selected.svg";
import { ReactComponent as DeleteCasesSelected } from "../../../assets/images/sidebar/delete-cases-selected.svg";
import { ReactComponent as DeleteCases } from "../../../assets/images/sidebar/delete-cases.svg";
import { ReactComponent as PricingSelected } from "../../../assets/images/sidebar/pricing-selected.svg";
import { ReactComponent as Pricing } from "../../../assets/images/sidebar/pricing.svg";
import { ReactComponent as DocumentSelected } from "../../../assets/images/sidebar/document-selected.svg";
import { ReactComponent as Document } from "../../../assets/images/sidebar/document.svg";
import { ReactComponent as CategorySelected } from "../../../assets/images/sidebar/category-selected.svg";
import { ReactComponent as Category } from "../../../assets/images/sidebar/category.svg";
import { ReactComponent as DocTypeSelected } from "../../../assets/images/sidebar/doc-type-selected.svg";
import { ReactComponent as DocType } from "../../../assets/images/sidebar/doc-type.svg";
import { ReactComponent as Disposition } from "../../../assets/images/sidebar/disposition.svg";
import { ReactComponent as DispositionSelected } from "../../../assets/images/sidebar/disposition-selected.svg";
import { ReactComponent as DecisioningAudit } from "../../../assets/images/sidebar/dec-audit.svg";
import { ReactComponent as DecisioningAuditSelected } from "../../../assets/images/sidebar/dec-audit-selected.svg";

import { useDispatch } from "react-redux";
import { setScreenTitle } from "../../../shared/redux/common-reducer";
import {
  PATH_APP_CONTENT,
  PATH_COUPONS,
  PATH_DASHBOARD,
  PATH_DC_CONFIGURATION,
  PATH_PRICING,
  PATH_PRODUCT,
  PATH_PROPERTIES,
  PATH_REPORTS,
  PATH_TENANT_ASSIGN,
  PATH_DELETE_CASES,
  PATH_MANAGE_DOCUMENT_TYPE,
  PATH_MANAGE_CATEGORY,
  PATH_DECISIONING,
  PATH_DISPOSITION,
  PATH_DECISIONING_AUDIT,
} from "../RouteConstants";
import "./SideBar.scss";

interface IDesktopNavbar {
  closeNavHandler?: any;
  setShowSiderMobile?: any;
}

type MenuItem = Required<MenuProps>["items"][number];

export default function SideBar(props: IDesktopNavbar) {
  const Navigate = useNavigate();
  const { closeNavHandler, setShowSiderMobile } = props;
  const dispatch = useDispatch();

  const [selectedKeys, setSelectedKeys] = useState(PATH_DASHBOARD);

  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    setSelectedKeys(path);
    setHeaderName(path);
  }, [window.location.href]);

  const setHeaderName = (path: any) => {
    if (path === PATH_TENANT_ASSIGN) {
      dispatch(setScreenTitle("Assign Distribution Channel"));
    } else if (path === PATH_PROPERTIES) {
      dispatch(setScreenTitle("Properties"));
    } else if (path === PATH_REPORTS) {
      dispatch(setScreenTitle("Reports"));
    } else if (path === PATH_APP_CONTENT) {
      dispatch(setScreenTitle("App Content"));
    } else if (path === PATH_DC_CONFIGURATION) {
      dispatch(setScreenTitle("DC Configuration"));
    } else if (path === PATH_DECISIONING) {
      dispatch(setScreenTitle("Decisioning"));
    } else if (path === PATH_PRODUCT) {
      dispatch(setScreenTitle("Products"));
    } else if (path === PATH_COUPONS) {
      dispatch(setScreenTitle("Coupons"));
    } else if (path === PATH_DELETE_CASES) {
      dispatch(setScreenTitle("Delete Case Data"));
    } else if (path === PATH_PRICING) {
      dispatch(setScreenTitle("Pricing"));
    } else if (path === PATH_MANAGE_CATEGORY) {
      dispatch(setScreenTitle("Manage Category"));
    } else if (path === PATH_MANAGE_DOCUMENT_TYPE) {
      dispatch(setScreenTitle("Manage Document Type"));
    } else if (path === PATH_DISPOSITION) {
      dispatch(setScreenTitle("Disposition"));
    } else if (path === PATH_DECISIONING_AUDIT) {
      dispatch(setScreenTitle("Decisioning Audit"));
    } else {
      dispatch(setScreenTitle("Dashboard"));
    }
  };

  const setIcon = (Icon: any) => {
    return <Icon height={18} width={18} />;
  };

  const handelOnClick = (path: string) => {
    setHeaderName(path);
    closeNavHandler && closeNavHandler();
    Navigate(path);
    setShowSiderMobile(false);
  };

  const dashboard = {
    key: PATH_DASHBOARD,
    label: "Dashboard",
    icon: setIcon(
      selectedKeys === PATH_DASHBOARD ? DashboardSelected : Dashboard
    ),
    onClick: () => handelOnClick(PATH_DASHBOARD),
  };

  const dcManagement = {
    key: "dcManagement",
    label: "DC Management",
    icon: setIcon(
      [PATH_TENANT_ASSIGN, PATH_DC_CONFIGURATION].includes(selectedKeys)
        ? DataManagementSelected
        : DataManagement
    ),
    children: [
      {
        key: PATH_TENANT_ASSIGN,
        label: "Assign DC",
        icon: setIcon(
          selectedKeys === PATH_TENANT_ASSIGN ? PageSelected : Page
        ),
        onClick: () => handelOnClick(PATH_TENANT_ASSIGN),
      },
      {
        key: PATH_DC_CONFIGURATION,
        label: "DC Configuration",
        icon: setIcon(
          selectedKeys === PATH_DC_CONFIGURATION ? ShieldSelected : Shield
        ),
        onClick: () => handelOnClick(PATH_DC_CONFIGURATION),
      },
    ],
  };

  const dataManagement = {
    key: "dataManagement",
    label: "Data Management",
    icon: setIcon(
      [PATH_PROPERTIES, PATH_DISPOSITION, PATH_MANAGE_CATEGORY, PATH_MANAGE_DOCUMENT_TYPE].includes(selectedKeys) ? DataManagementSelected : DataManagement
    ),
    children: [
      {
        key: PATH_PROPERTIES,
        label: "Properties",
        icon: setIcon(selectedKeys === PATH_PROPERTIES ? BookBlue : Book),
        onClick: () => handelOnClick(PATH_PROPERTIES),
      },
      {
        key: PATH_DISPOSITION,
        label: "Disposition",
        icon: setIcon(
          selectedKeys === PATH_DISPOSITION ? DispositionSelected : Disposition
        ),
        onClick: () => handelOnClick(PATH_DISPOSITION),
      },
      {
        key: PATH_MANAGE_CATEGORY,
        label: "Manage Category",
        icon: setIcon(
          selectedKeys === PATH_MANAGE_CATEGORY ? CategorySelected : Category
        ),
        onClick: () => handelOnClick(PATH_MANAGE_CATEGORY),
      },
      {
        key: PATH_MANAGE_DOCUMENT_TYPE,
        label: "Manage Doc Type",
        icon: setIcon(
          selectedKeys === PATH_MANAGE_DOCUMENT_TYPE ? DocTypeSelected : DocType
        ),
        onClick: () => handelOnClick(PATH_MANAGE_DOCUMENT_TYPE),
      },
    ],
  };

  const contentManagement = {
    key: "contentManagement",
    label: "Content Management",
    icon: setIcon(selectedKeys === PATH_APP_CONTENT ? BookSelected : Book),
    children: [
      {
        key: PATH_APP_CONTENT,
        label: "App Content",
        icon: setIcon(selectedKeys === PATH_APP_CONTENT ? PageSelected : Page),
        onClick: () => handelOnClick(PATH_APP_CONTENT),
      },
    ],
  };

  const productManagement = {
    key: "productManagement",
    label: "Product Management",
    icon: setIcon(
      selectedKeys === PATH_DECISIONING
        ? ProductManagementSelceted
        : ProductManagement
    ),
    children: [
      {
        key: PATH_DECISIONING,
        label: "Decisioning",
        icon: setIcon(
          selectedKeys === PATH_DECISIONING ? DecisioningSelected : Decisioning
        ),
        onClick: () => handelOnClick(PATH_DECISIONING),
      },
      // {
      //   key: PATH_PRODUCT,
      //   label: "Products",
      //   icon: setIcon(selectedKeys === PATH_PRODUCT ? ProductSelected : Product),
      //   onClick: () => handelOnClick(PATH_PRODUCT),
      // },
    ],
  };

  const products = {
    key: PATH_PRODUCT,
    label: "Product Management",
    icon: setIcon(selectedKeys === PATH_PRODUCT ? ProductSelected : Product),
    onClick: () => handelOnClick(PATH_PRODUCT),
  };

  const coupons = {
    key: PATH_COUPONS,
    label: "Coupons",
    icon: setIcon(selectedKeys === PATH_COUPONS ? CouponsSelected : Coupons),
    onClick: () => handelOnClick(PATH_COUPONS),
  };

  const reports = {
    key: PATH_REPORTS,
    label: "Reports",
    icon: setIcon(selectedKeys === PATH_REPORTS ? ReportSelected : Report),
    onClick: () => handelOnClick(PATH_REPORTS),
  };

  const deleteCases = {
    key: PATH_DELETE_CASES,
    label: "Delete Case Data",
    icon: setIcon(
      selectedKeys === PATH_DELETE_CASES ? DeleteCases : DeleteCasesSelected
    ),
    onClick: () => handelOnClick(PATH_DELETE_CASES),
  };

  const pricing = {
    key: PATH_PRICING,
    label: "Pricing",
    icon: setIcon(selectedKeys === PATH_PRICING ? PricingSelected : Pricing),
    onClick: () => handelOnClick(PATH_PRICING),
  };


  const decisioningAudit = {
    key: PATH_DECISIONING_AUDIT,
    label: "Decisioning Audit",
    icon: setIcon(
      selectedKeys === PATH_DECISIONING_AUDIT
        ? DecisioningAuditSelected
        : DecisioningAudit
    ),
    onClick: () => handelOnClick(PATH_DECISIONING_AUDIT),
  };

  const items: MenuItem[] = [
    dashboard,
    dcManagement,
    dataManagement,
    contentManagement,
    // productManagement,
    reports,
    deleteCases,
    products,
    // coupons,
    // pricing,
    decisioningAudit,
    //document,
  ];

  const setTitles = () => {
    return (
      <Menu
        className="sidebar"
        style={{ background: "#fbfcfd" }}
        theme="light"
        selectedKeys={[selectedKeys]}
        mode="inline"
        items={items}
      />
    );
  };

  return (
    <>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="nav-panel">{setTitles()}</div>
    </>
  );
}
