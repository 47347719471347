import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const alertmsg = {
    message: null,
    showAlertMessage: false,
    description: null,
    type: 'success'
}

export const alertSlice = createSlice({
    name: "alertReducer",
    initialState: { alertmsg },
    reducers: {

        setAlertMsg: (state: any, action) => {
            state.alertmsg.message = action.payload.message;
            state.alertmsg.description = action.payload.description;
            state.alertmsg.showAlertMessage = action.payload.showAlertMessage;
            state.alertmsg.type = action.payload.type;
        }
    }
});

export const { setAlertMsg } = alertSlice.actions;
export default alertSlice.reducer;