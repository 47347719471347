import { Button, Form, Space, Table, Typography } from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { useEffect, useState } from "react";
import { viewTypeProduct } from "./Products";
import { ColumnsType } from "antd/es/table";
import { API_SERVICE } from "../../../shared/services/api-services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import { viewTypeJourneyProduct } from "../ProductJourneyV2/Journey";
import { setJourneyObject } from "../../../shared/redux/common-reducer";
const { Title } = Typography;

interface columns {
  key: React.ReactNode;
  name: string;
}

interface IPublishDetails {
  productWorkflowId: any;
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
  fetchMasterProductList: any;
}

const PublishDetails: React.FC<IPublishDetails> = (props: IPublishDetails) => {
  const { productWorkflowId, setViewTypeJourney, fetchMasterProductList } =
    props;
  const [PublishDetails, setPublishDetails] = useState([] as any);
  const [totalCount, setTotalCount] = useState(0);
  const [publishPageNo, setPublishPageNo] = useState(1);
  const [publishPageSize, setPublishPageSize] = useState(10);
  const dispatch = useDispatch();
  const journey: any = useSelector((state: RootState) => state.journey);

  useEffect(() => {
    getPublishDetails();
  }, []);

  const getPublishDetails = (pageNo: number = 1, pageSize: any = 10) => {
    const params = {
      direction: "DESC",
      pageNo: pageNo,
      pageSize: pageSize,
      productWorkflowId: productWorkflowId,
    };
    API_SERVICE.listProductWorkflowPublish(params)
      .then(({ data }) => {
        setPublishDetails(data?.payload?.content);
        setTotalCount(data?.payload?.totalElements);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPublishPageNo(current);
    setPublishPageSize(pageSize);
    getPublishDetails(current, pageSize);
  };

  const columns: ColumnsType<columns> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Publish Date",
      dataIndex: "publishDate",
      key: "publishDate",
      render: (record: any) => 
        moment(record).format("DD-MMM-YYYY hh:mm A"),
    },
    {
      title: "Publish Version",
      dataIndex: "publishVersion",
      key: "publishVersion",
      render: (record: any) => <span>V{record}</span>,
    },
    {
      title: "Last Comment",
      dataIndex: "publishingComment",
      key: "publishingComment",
      width: 300,
    },
  ];

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={5} style={{ padding: "17px" }}>
          Publish History
        </Title>
        <Button
          type="link"
          onClick={() => {
            setViewTypeJourney("journey");
            dispatch(setJourneyObject(""));
            fetchMasterProductList(
              journey?.pageNo,
              journey?.pageSize,
              journey.searchParam
            );
          }}
          style={{ padding: "15px" }}
        >
          Back to Journey
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={PublishDetails}
        size="middle"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          position: ["bottomCenter"],
          current: publishPageNo,
          pageSize: publishPageSize,
          total: totalCount,
        }}
      />
    </div>
  );
};

export default PublishDetails;
