import { Button, Space, Table, Tooltip, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import { DownOutlined, UpOutlined, DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  setPublishPageNo,
  setPublishPageSize,
} from "../../../shared/redux/publish-reducer";
import { downloadDocument, getEnumList } from "../../../shared/Utility/Utility";

const { Text } = Typography;

interface IPublish {
  currentPublishedId: any;
  eligibleLendersArr: any;
  decisionData: any;
}

const Publish: React.FunctionComponent<IPublish> = (props: IPublish) => {
  const { currentPublishedId, eligibleLendersArr, decisionData } = props;
  const [tmpColumns, setTmpColumns] = useState<any>();
  const [publishData, setPublishData] = useState([] as any);
  const [PublishDetails, setPublishDetails] = useState("" as any);
  const [isShowFullDetils, setIshowFullDetials] = useState(false);
  const publish: any = useSelector((state: RootState) => state.publish);
  const dispatch = useDispatch();

  const configArr: any = [
    {
      key: "TRUE",
      value: "True",
    },
    {
      key: "FALSE",
      value: "False",
    },
  ];

  useEffect(() => {
    getPublishList();
  }, []);

  const getPublishList = () => {
    if (currentPublishedId == null) {
      return;
    }
    API_SERVICE.getScorecardPublisList(currentPublishedId)
      .then(({ data }) => {
        setPublishDetails(data?.payload);
        columnsAndDataDisplay(data?.payload?.publishedPayload);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const columnsAndDataDisplay = (data: any) => {
    const columns: any = [
      {
        title: "Rule Name",
        key: "ruleName",
        dataIndex: ["ruleName"],
        width: decisionData?.decisionType == "OFFER_DECISION" ? 200 : 100,
        fixed: "left",
        render: (value: any) => {
          let tmpValue = value;
          if (decisionData?.decisionType == "OFFER_DECISION") {
            const rules = value?.split("-");
            const eligibleName = eligibleLendersArr?.find(
              (item: any) => item?.key == rules?.at(0)
            );
            const purpose = getEnumList("DECISIONING_PURPOSE")?.find(
              (item: any) => item?.key == rules?.at(-1)
            );
            tmpValue =
              (eligibleName?.value ?? "") + " - " + (purpose?.value ?? "");
          }
          return <Text>{tmpValue}</Text>;
        },
      },
    ];

    data?.conditionMetadata?.map((item: any) => {
      columns.push({
        title: <div>Condition</div>,
        children: [
          {
            title: item?.conditionName,
            children: [
              {
                title: item?.expression,
                key: item?.conditionId,
                width: 300,
                dataIndex: `conditionId_${item?.conditionId}`,
              },
            ],
          },
        ],
      });
    });

    data?.actionMetadata?.map((item: any) => {
      const displayNameListData = item?.categoryInfoList?.map((item: any) => {
        return {
          key: item.id,
          value: `${item.categoryName},${item?.weightage}%`,
        };
      });
      columns.push({
        title: <div>Action</div>,
        children: [
          {
            title: item?.actionName,
            children: [
              {
                title: item?.description?.replaceAll(",", ", "),
                key: item?.actionId,
                dataIndex: `actionId_${item?.actionId}`,
                render: (value: any, record: any) => {
                  if (item?.expression == "SET_CONDITIONAL_CONFIG_VALUE") {
                    let configName = configArr?.find(
                      (item: any) => item?.key == value
                    );
                    return <Text>{configName?.value}</Text>;
                  } else if (item?.expression == "ELIGIBLE_LENDERS") {
                    let eligibleName = eligibleLendersArr?.find(
                      (item: any) => item?.key == value
                    );
                    return <Text>{eligibleName?.value}</Text>;
                  } else {
                    let categoryName = displayNameListData?.find(
                      (item: any) => item?.key == value
                    );

                    const cat = categoryName?.value
                      ? `${categoryName?.value},${record?.scoreOrReject}`
                      : "";
                    return (
                      <Text>
                        {item?.categoryInfoList?.length > 0 ? cat : value}
                      </Text>
                    );
                  }
                },
              },
            ],
          },
        ],
      });
    });

    const tableData: any = [];
    data?.ruleMetadata?.map((item: any) => {
      let getRule = { ruleName: item?.ruleName };
      item?.decisioningConditions?.map((item: any) => {
        const getValue = JSON.parse(
          `{"conditionId_${item?.conditionId}": "${item?.conditionParamterValues}"}`
        );
        getRule = { ...getRule, ...getValue };
      });

      item.decisioningActions?.map((item: any) => {
        let getValue: any;
        if (item?.categoryId?.length > 0) {
          getValue = JSON.parse(
            `{"actionId_${item?.actionId}": "${item?.categoryId}","scoreOrReject": "${item?.scoreOrReject}"}`
          );
        } else {
          getValue = JSON.parse(
            `{"actionId_${item?.actionId}": "${item?.actionParamterValues}"}`
          );
        }
        getRule = { ...getRule, ...getValue };
      });

      tableData.push(getRule);
    });

    setPublishData(tableData);
    setTmpColumns(columns);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setPublishPageNo(current));
    dispatch(setPublishPageSize(pageSize));
  };

  return (
    <>
      {currentPublishedId != null &&
      <div style={{ padding: "15px" }}>
        <Space>
          <p>Publish Details</p>
          <p style={{ width: "202px" }}>
            User Name: {PublishDetails?.userName}
          </p>
          <p>User ID: {PublishDetails?.userId}</p>
        </Space>
        <a
          style={{
            float: "right",
            marginRight: "10px",
            color: "#273896",
          }}
          onClick={() => setIshowFullDetials(!isShowFullDetils)}
        >
          Full Details {isShowFullDetils ? <UpOutlined /> : <DownOutlined />}
        </a>
        <br />
        {isShowFullDetils && (
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <Space
            style={{
              paddingLeft: "99px",
              gap: "50px",
            }}
          >
            <p style={{ width: "161px" }}>
              Publish Date:{" "}
              {(PublishDetails?.publishDate?.length ?? 0) > 0
                ? moment(PublishDetails?.publishDate).format("DD MMM YYYY")
                : ""}
            </p>
            <p style={{ width: "125px" }}>
              Publish Version: {PublishDetails?.publishVersion}
            </p>
            <p>Comment: {PublishDetails?.publishingComment}</p>
          </Space>
          <Space style={{gap:'10px', marginInline:'10px'}}>
            <Button
              type="primary"
              style={{ background: "#20A74E" }}
              onClick={() => downloadDocument(PublishDetails?.documentId)}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>
            <Button
              type="primary"
              onClick={() => { 
                navigator.clipboard.writeText(JSON.stringify(PublishDetails?.publishedPayload))
                notification.success({ message: "Copied to clipboard" });
              }}
              icon={<CopyOutlined />}
            >
              Copy Rules
            </Button>
          </Space>
          </div>
        )}
      </div>
      }
      <Table
        columns={tmpColumns}
        size="small"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          position: ["bottomCenter"],
          current: publish?.pageNo,
          pageSize: publish?.pageSize,
          total: publishData?.length,
        }}
        scroll={{ x: "max-content" }}
        dataSource={publishData}
        className="scorecard-tabel"
      />
    </>
  );
};

export default Publish;
