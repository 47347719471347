export const API_CONFIG = {
    getOtp: 'free/get/otp/email/',
    verifyOtp: 'free/verify/otp',
    setUpdatePassword: 'free/verify-and-update/password',
    listAppContent: 'business-communication/get/free/app_content/',
    listAppContentFAQ: 'business-communication/list/',
    addAppContent: 'business-communication/add/app_content/',
    updateAppContent: 'business-communication/update/app_content',
    deleteAppContent: 'business-communication/delete/bc',
    productFormTemplate: 'product/v1/form-template',
    startProductWorkflow: 'v1/product-workflow/start',
    submitProductWorkflow: 'v1/product-workflow/submit-task',
    getNextTaskProductWorkflow: 'v1/product-workflow/get-task',
    checkCaptchaRequired:'free/check/re-captcha-is-required',


}