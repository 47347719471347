import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const offerCalculationSlice = createSlice({
    name: "OfferCalculationReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null, masterProductFilter: null },
    reducers: {
        setOfferCalculationPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setOfferCalculationPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setOfferCalculationSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        },
       
    }
});

export const { setOfferCalculationPageNo, setOfferCalculationPageSize, setOfferCalculationSearchParam } = offerCalculationSlice.actions;
export default offerCalculationSlice.reducer;