import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Alert,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_SERVICE } from "../../shared/services/api-services";
import FormTextArea from "../../shared/component/FormTextArea/FormTextArea";

const DeleteCases = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isOpenVerify, setIsOpenVerify] = useState(false);

  const cleanLeads = () => {
    let caseIds = form.getFieldValue("caseId");
    caseIds = caseIds.replace(/ /g, "");
    setLoading(true);
    API_SERVICE.cleanLeads(caseIds)
      .then(({ data }: any) => {
        form.resetFields(["caseId"]);
        notification.success({ message: data?.message });
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (contact: any) => {
    setIsOpenVerify(true);
  };

  const renderConfirmation = () => {
    return (
      <Modal
        className="text-center"
        title={`Are your sure, you want to delete these case data ?`}
        open={isOpenVerify}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                cleanLeads();
                setIsOpenVerify(false);
              }}
              style={{ paddingInline: "30px" }}
            >
              Yes
            </Button>
            <Button
              key="back"
              onClick={() => setIsOpenVerify(false)}
              style={{ paddingInline: "30px" }}
            >
              No
            </Button>
          </div>,
        ]}
        width={300}
        centered
        closable={false}
      ></Modal>
    );
  };

  return (
    <>
      {renderConfirmation()}
      <div className={"layout-main"} style={{ marginTop: "20px" }}>
        <div className="select-content">
          <Spin spinning={loading}>
            <Form
              name="deleteCaseForm"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              autoComplete="off"
            >
              <FormTextArea
                name={"caseId"}
                label="Case Ids"
                row={4}
                isRequired
                isBorder
                style={{ width: "400px", maxWidth: "84vw" }}
              />
              <Form.Item>
                <Space
                  direction="horizontal"
                  size={12}
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    size="large"
                    htmlType="reset"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Clear
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Delete
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default DeleteCases;
