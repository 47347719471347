import React from "react";
import "./App.scss";
import { AuthConsumer, AuthProvider } from "./shared/contexts/AuthContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./screens/authentication/SignIn";
import ForgotPassword from "./screens/authentication/ForgotPassword";
import AppLayout from "./screens/layout/AppLayout";
import "./shared/styles/_antd.scss";
import { PATH_EMBEDDING_IFRAME } from "./screens/layout/RouteConstants";
import EmbeddingIframe from "./screens/EmbeddingIframe";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AuthConsumer>
          {({ isAuth, apiCreds }) => {
            return isAuth ? (
              <AppLayout />
            ) : (
              <Routes>
                <Route path={PATH_EMBEDDING_IFRAME} element={<EmbeddingIframe />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/*" element={<Navigate to={"/sign-in"} />} />
              </Routes>
              // <AppLayout />
            );
          }}
        </AuthConsumer>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
