import { Table } from "antd";
import {
  setOfferCalculationPublishPageNo,
  setOfferCalculationPublishPageSize,
} from "../../../shared/redux/offerCalculationPublish-reducer";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";

interface IOfferCalculationPublishHistory {
  offerCalcData: any;
}

const OfferCalculationPublishHistory: React.FC<
  IOfferCalculationPublishHistory
> = (props: IOfferCalculationPublishHistory) => {
  const { offerCalcData } = props;
  const [publishHistoryData, setPublishHistoryData] = useState([] as any);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const OfferCalculationPublish: any = useSelector(
    (state: RootState) => state.OfferCalculationPublish
  );

  useEffect(() => {
    publishDetails();
  }, []);

  const publishHistory = [
    {
      title: "UserName",
      dataIndex: ["createdUser", "firstName"],
      key: "userName",
    },
    {
      title: "User ID",
      dataIndex: ["createdUser", "userId"],
      key: "userId",
    },
    {
      title: "Publish Date",
      dataIndex: "lastUpdatedDate",
      key: "publishDate",
    },
    {
      title: "Publish Version",
      dataIndex: "version",
      key: "publishVersion",
      render: (record: any) => <span>v{record}</span>,
    },
    {
      title: "LastComment",
      dataIndex: "publishComments",
      key: "lastComment",
      width: 300,
    },
  ];

  const publishDetails = (pageNo: number = 1, pageSize: number = 10) => {
    const payload = {
      direction: "ASC",
      masterProductId: offerCalcData?.masterProductId,
      offerCalculationId: offerCalcData?.offerCalculationId,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    API_SERVICE.offerCalculationPublishDetails(payload)
      .then(({ data }) => {
        setPublishHistoryData(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setOfferCalculationPublishPageNo(current));
    dispatch(setOfferCalculationPublishPageSize(pageSize));
    publishDetails(current, pageSize);
  };

  return (
    <div>
      <Table
        columns={publishHistory}
        dataSource={publishHistoryData}
        size="small"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          position: ["bottomCenter"],
          current: OfferCalculationPublish?.pageNo,
          pageSize: OfferCalculationPublish?.pageSize,
          total: totalCount,
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default OfferCalculationPublishHistory;
