import React, { useState, useEffect } from "react";
import { Form, Space, Typography, notification } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import { getEnumList } from "../../../shared/Utility/Utility";

interface IAddOfferCalculation {
  setShowAddOfferCalculation: any;
  getOfferCalculationList: any;
  isEdit: any;
  editedData: any;
}

const { Title } = Typography;

const AddOfferCalculation: React.FC<IAddOfferCalculation> = (
  props: IAddOfferCalculation
) => {
  const {
    setShowAddOfferCalculation,
    getOfferCalculationList,
    isEdit,
    editedData,
  } = props;
  const [masterProductTypes, setMasterProductTypes] = useState([] as any);
  const [lenderList, setLenderList] = useState([] as any);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const [offerCalculationForm] = Form.useForm() as any;

  useEffect(() => {
    getMasterProductType();
    listAllTheFinanciers();
    offerCalculationForm.setFieldsValue({
      masterProduct: productsObject?.productsId,
    });
    if (isEdit) {
      offerCalculationForm?.setFieldsValue({
        offerCalculationName: editedData?.name,
        lender: editedData?.lenderBusinessId,
        offerCalculationType: editedData?.offerCalculationType,
        purpose: editedData?.purpose,
        description: editedData?.description,
      });
    }
  }, []);

  const listAllTheFinanciers = () => {
    API_SERVICE.listAllTheFinanciers().then(({ data }: any) => {
      const displayNameList = data?.payload?.map((listVal: any) => {
        return {
          key: listVal?.businessInfoId,
          value: listVal?.businessName,
        };
      });
      setLenderList(displayNameList);
    });
  };
  const getMasterProductType = () => {
    const parms = {
      pageSize: 100
    }
    API_SERVICE.getMasterProductList(parms)
      .then(({ data }) => {
        const displayList = data?.payload?.content?.map((item: any) => {
          return {
            key: item?.productId,
            value: item?.name,
          };
        });
        setMasterProductTypes(displayList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const addOfferCalculation = (payload: any) => {
    API_SERVICE.addOfferCalculation(payload)
      .then(() => {
        notification.success({ message: "Added Successfully" });
        getOfferCalculationList();
        setShowAddOfferCalculation(false);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateOfferCalculation = (payload: any) => {
    const tmpPayload = { ...payload };
    tmpPayload.offerCalculationId = editedData?.offerCalculationId;
    API_SERVICE.updateOfferCalculation(tmpPayload)
      .then(({ data }) => {
        setShowAddOfferCalculation(false);
        getOfferCalculationList();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onSubmit = (values: any) => {
    const payload = {
      lenderBusinessId: values?.lender,
      masterProductId: values?.masterProduct,
      offerCalculationType: values?.offerCalculationType,
      purpose: values?.purpose,
      name: values?.offerCalculationName,
      description: values?.description,
    };
    if (isEdit) {
      updateOfferCalculation(payload);
    } else {
      addOfferCalculation(payload);
    }
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ padding: "17px" }}>
        {isEdit ? "Edit Offer Calculation" : "Add Offer Calculation"}
      </Title>
      <div className="decisioning-form-layout">
        <Form
          layout="vertical"
          form={offerCalculationForm}
          className="decisioning-form"
          onFinish={onSubmit}
        >
          <FormInput
            name="offerCalculationName"
            label="Name"
            placeholder="Enter offer calculation name"
            isBorder
            isRequired
          />
          <FormSelect
            name="lender"
            label="Lender"
            placeholder="Select lender"
            optionFilterProp="children"
            isBorder
            isRequired
            options={lenderList}
            disabled={isEdit}
          />
          <FormSelect
            name="masterProduct"
            label="Master Product"
            placeholder="Select master product"
            disabled
            isBorder
            isRequired
            options={masterProductTypes}
          />
          <FormSelect
            name="offerCalculationType"
            label="Offer Calculation Type"
            placeholder="Select offer calculation type"
            isBorder
            disabled={isEdit}
            options={getEnumList("OFFER_CALCULATION_TYPE")}
          />
          <FormSelect
            name="purpose"
            label="Purpose"
            placeholder="Select purpose"
            isBorder
            options={getEnumList("DECISIONING_PURPOSE")}
            disabled={isEdit}
          />
          <FormTextArea
            name="description"
            label="Description"
            isBorder
            row={3}
          />
          <Space>
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "150px" }}
            />
            <FormButton
              label="Cancel"
              onclick={() => {
                setShowAddOfferCalculation(false);
              }}
              style={{ height: "50px", width: "150px" }}
            />
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default AddOfferCalculation;
