import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Table, Tooltip, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setOfferCalculationPageNo,
  setOfferCalculationPageSize,
  setOfferCalculationSearchParam
} from "../../../shared/redux/offerCalculation-reducer";
import {
  setOfferCalculationObject,
  setProductsObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import { ColumnsType } from "antd/es/table";
import search from "../../../assets/images/search.svg";
import info from "../../../assets/images/info.svg";
import OfferCalculationTabs from "./OfferCalculationTabs";
import FormButton from "../../../shared/component/FormButton/FormButton";
import AddOfferCalculation from "./AddOfferCalculation";
import { API_SERVICE } from "../../../shared/services/api-services";
import { viewTypeProduct } from "../Products/Products";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import PasteOfferCalculation from "./PasteOfferCalculation";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

interface IOfferCalculation {
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  getProductList: any;
}

const OfferCalculation: React.FC<IOfferCalculation> = (
  props: IOfferCalculation
) => {
  const { setViewType, getProductList } = props;

  const [showAddOfferCalculation, setShowAddOfferCalculation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [offerCalculationList, setOfferCalculationList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showOfferCalculationTabs, setShowOfferCalculationTabs] =
    useState(false);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const [offerCalcData, setOfferCalcData] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editedData, setEditedData] = useState(null as any);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const offerCalculation: any = useSelector(
    (state: RootState) => state.offerCalculation
  );
  const product: any = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();
  const [searchOfferForm] = Form.useForm() as any;


  const handleSearch = (values: any) => {
    dispatch(setOfferCalculationPageNo(1));
    dispatch(setOfferCalculationSearchParam(values?.searchParam));
    getOfferCalculationList(1, offerCalculation?.pageSize, values.searchParam);
  };

  useEffect(() => {
    dispatch(setOfferCalculationPageNo(1));
    getOfferCalculationList();
  }, []);

  useEffect(() => {
    searchOfferForm.setFieldsValue({
      searchParam: offerCalculation.searchParam ?? "",
    });
  }, []);

  const getOfferCalculationList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    setLoading(true);
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParameter: searchParam,
      masterProductId: productsObject.productsId,
    };
    API_SERVICE.getOfferCalculationList(payload)
      .then(({ data }: any) => {
        data.payload.content.map((offer: any) => {
          offer.key = offer.offerCalculationId;
        })
        setOfferCalculationList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setOfferCalculationPageNo(current));
    dispatch(setOfferCalculationPageSize(pageSize));
    getOfferCalculationList(current, pageSize, offerCalculation?.searchParam);
  };

  const backproduct = () => {
    setViewType("product");
    dispatch(setScreenTitle("Products"));
    dispatch(setProductsObject({}));
    getProductList(product?.pageNo, product?.pageSize, product.searchParam);
  };

  const onEdit = (rec: any) => {
    API_SERVICE.getOfferCalculationById(rec?.offerCalculationId)
      .then(({ data }) => {
        setIsEdit(true);
        setShowAddOfferCalculation(true);
        setEditedData(data?.payload);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const offerCalculationData = (data: any) => {
    let parms = {
      offerCalculationName: data?.name,
      lenderBusinessName: data?.lenderBusinessName,
      offerCalculationType: data?.offerCalculationType,
      currentFileName: data?.currentFileName,
    };
    dispatch(setOfferCalculationObject(parms));
  };

  const copyOffers = () => {
    setLoadingCopy(true);
    API_SERVICE.offerCalculationCopyJson({offerCalculationIds: selectedRowKeys})
      .then(({ data }) => {
        setSelectedRowKeys([]);
        navigator.clipboard.writeText(JSON.stringify(data?.payload))
        notification.success({ message: "Offer calculations copied to clipboard. This is only active for 15 minutes." });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoadingCopy(false));
  }

  //Offer-calculation Table Start
  const columns: ColumnsType<columns> = [
    {
      title: "Offer Calculation Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any) => (
        <>
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setShowOfferCalculationTabs(true);
              setOfferCalcData(record);
              offerCalculationData(record);
            }}
          >
            {record?.name}
          </a>
        </>
      ),
    },
    {
      title: "Lender Business Name",
      dataIndex: "lenderBusinessName",
      key: "lenderBusinessName",
    },
    {
      title: "Offer Calculation Type",
      dataIndex: "offerCalculationType",
      key: "offerCalculationType",
    },
    {
      title: "Master Product Name",
      dataIndex: "masterProductName",
      key: "masterProductName",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "Published File Name",
      dataIndex: "publishedFileName",
      key: "publishedFileName",
    },
    {
      title: "Created Date",
      dataIndex: "creationDate",
      key: "creationDate",
    },
    {
      title: "Created By",
      dataIndex: ["createdUser", "fullName"],
      key: "fullName",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => {
        return <FormIconButton type="edit" onClick={() => onEdit(record)} />;
      },
    },
  ];
  //Offer-calculation Table End

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderOfferCalculation = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearch} form={searchOfferForm}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <FormButton
              label="Search"
              htmlType="submit"
              style={{ height: "40px" }}
              type="primary"
            />
          </Form>
          <div>
            <Button type="link" onClick={backproduct}>
              Back to product
            </Button>
            <Button
              type="primary"
              style={{ height: "40px" }}
              onClick={() => {
                setIsEdit(false);
                setShowAddOfferCalculation(true);
              }}
            >
              Add Offer Calculation
            </Button>
            <PasteOfferCalculation getOfferCalculationList={getOfferCalculationList} />
          </div>
        </div>
        <div className={"layout-main"}>
          <Table
            columns={columns}
            dataSource={offerCalculationList}
            rowSelection={rowSelection}
            size="middle"
            loading={loading}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: offerCalculation.pageNo,
              pageSize: offerCalculation.pageSize,
              total: totalCount,
              showSizeChanger: true,
            }}
            scroll={{ x: "max-content" }}
          />
          <Row gutter={[8,8]} style={{ marginTop: "-40px", paddingBottom: '15px', paddingLeft: "15px" }}>
            <Col>
              <Button
                type="primary"
                disabled={selectedRowKeys?.length == 0}
                onClick={copyOffers}>
                Copy Offer Calculations
              </Button>
            </Col>
            <Col style={{marginTop:"3px"}}>
              <Tooltip title="Select Offer To Enable Copy Offer Calculations"><img src={info} alt="" style={{ cursor: "pointer" }} /> </Tooltip>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  return (
    <>
      {showOfferCalculationTabs ? (
        <OfferCalculationTabs
          getOfferCalculationList={getOfferCalculationList}
          setShowOfferCalculationTabs={setShowOfferCalculationTabs}
          offerCalcData={offerCalcData}
        />
      ) : (
        <>
          {showAddOfferCalculation ? (
            <AddOfferCalculation
              setShowAddOfferCalculation={setShowAddOfferCalculation}
              isEdit={isEdit}
              editedData={editedData}
              getOfferCalculationList={getOfferCalculationList}
            />
          ) : (
            renderOfferCalculation()
          )}
        </>
      )}
    </>
  );
};
export default OfferCalculation;
