import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const publishListSlice = createSlice({
  name: "PublishListReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null, parentId: null },
  reducers: {
    setPublishListPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPublishListPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    }
  }
});

export const { setPublishListPageNo, setPublishListPageSize } = publishListSlice.actions;
export default publishListSlice.reducer;