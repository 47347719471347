import { Button, Form, Input, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import search from "../../../assets/images/search.svg";
import { ColumnsType } from "antd/es/table";
import AddDocumentType from "./AddDocumentType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setDocumentTypePageNo,
  setDocumentTypePageSize,
  setDocumentTypeSearchParam,
} from "../../../shared/redux/documentType-reducer";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { API_SERVICE } from "../../../shared/services/api-services";

interface IColumns {
  key: React.ReactNode;
  id: string;
  documentName: string;
  documentType: string;
  category: string;
}

type IManageDocumentType = {};

const ManageDocumentType: React.FunctionComponent<IManageDocumentType> = () => {
  const [docTypeList, setDocTypeList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [editDocumentData, setEditDocumentData] = useState({} as any);

  const documentType: any = useSelector(
    (state: RootState) => state.documentType
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getDocumentTypeList();
  }, []);

  const getDocumentTypeList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    setLoading(true);
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParameter: searchParam,
    };

    API_SERVICE.getDocumentTypes(params)
      .then((data) => {
        setDocTypeList(data?.data?.payload?.content ?? []);
        setTotalCount(data?.data?.payload?.totalElements);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (e: any) => {
    dispatch(setDocumentTypePageNo(1));
    dispatch(setDocumentTypeSearchParam(e.searchParam));
    getDocumentTypeList(1, documentType.pageSize, e.searchParam);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDocumentTypePageNo(current));
    dispatch(setDocumentTypePageSize(pageSize));
    getDocumentTypeList(current, pageSize);
  };

  const actionEdit = (record: any) => {
    setIsEdit(true);
    setOpenAdd(true);
    setEditDocumentData(record);
  };

  const switchChange = (e: any, record: any) => {
    const status = record.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    API_SERVICE.updateStatusOfDocumentType(record.id, status)
      .then((data) => {
        getDocumentTypeList(
          documentType.pageNo,
          documentType.pageSize,
          documentType.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const columns: ColumnsType<IColumns> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span style={{ color: "#4285F4" }}>{text}</span>,
    },
    {
      title: "Document Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Category",
      dataIndex: "documentCategoryResponseList",
      key: "documentCategoryResponseList",
      render: (_: any, record: any) => (
        <>
          {record?.documentCategoryResponseList
            ?.map((item: any) => item.name)
            .join(", ")}
        </>
      ),
    },
    {
      title: "Rule",
      dataIndex: "maximumSizeInMB",
      key: "maximumSizeInMB",
      render: (_: any, record: any) => (
        <>Max Size: {record.maximumSizeInMB}MB</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: number, record: any) => (
        <Switch
          checked={record.status === "ACTIVE"}
          onClick={(e) => switchChange(e, record)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_: any, record: any) => (
        <>
          <FormIconButton type={"edit"} onClick={() => actionEdit(record)} />
          {/* <FormIconButton
            type={"delete"}
            isShowPopconfirm
            onClick={() => actionDelete(record?.id)}
          /> */}
        </>
      ),
    },
  ];

  const renderView = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearch}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                setOpenAdd(true);
                setEditDocumentData({});
              }}
              type="primary"
              style={{ height: "40px" }}
            >
              Create New Doc Type
            </Button>
          </div>
        </div>
        <div className={"layout-main"}>
          <Table
            columns={columns}
            dataSource={docTypeList}
            size="middle"
            loading={loading}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: documentType.pageNo,
              pageSize: documentType.pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {openAdd ? (
        <div
          className={"layout-main"}
          style={{
            minHeight: "70px",
            marginTop: "20px",
            paddingInline: "30px",
          }}
        >
          <AddDocumentType
            openAdd={openAdd}
            setOpenAdd={setOpenAdd}
            isEdit={isEdit}
            editDocumentData={editDocumentData}
            getDocumentTypeList={getDocumentTypeList}
          />
        </div>
      ) : (
        renderView()
      )}
    </>
  );
};

export default ManageDocumentType;
