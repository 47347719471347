import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import { API_SERVICE } from "../../../shared/services/api-services";
import FormButton from "../../../shared/component/FormButton/FormButton";
import searchIcon from "../../../assets/images/search.svg";
import { DownOutlined } from "@ant-design/icons";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import AppUpload from "../../../shared/component/AppUpload/AppUpload";
import "../OfferCalculation/offerCalculationDraft.scss";

interface IOfferCalculationDraft {
  offerCalcData: any;
  setIsShowTest: any;
  setIsShowPublishComment: any;
  setIsTestDisabled: any;
  isTestDisabled: any;
  isPublishDisabled: any;
  setIsPublishDisabled: any;
}

const OfferCalculationDraft: React.FC<IOfferCalculationDraft> = (
  props: IOfferCalculationDraft
) => {
  const {
    offerCalcData,
    setIsShowTest,
    setIsShowPublishComment,
    isTestDisabled,
    setIsTestDisabled,
    isPublishDisabled,
    setIsPublishDisabled,
  } = props;

  const [currentOfferCalculationData, setCurrentOfferCalculationData] =
    useState({} as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [propertiesIndex, setPropertiesIndex] = useState(Number);
  const [isPropertyListModal, setIsPropertyListModal] = useState(false);
  const [propertiesList, setPropertiesList] = useState([] as any);
  const [propertiesListFilter, setPropertiesListFilter] = useState([] as any);
  const [columnType, setColumnType] = useState({} as any);
  const [columnsDisplay, setColumnsDisplay] = useState([] as any);
  const [uploadedDocumentName, setUploadedDocumentName] = useState("");
  const [clearFileList, setClearFileList] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [offerCalculationForm] = Form.useForm() as any;

  useEffect(() => {
    getOfferCalculationById();
    getPropertiesList();
  }, []);

  const getOfferCalculationById = () => {
    API_SERVICE.getOfferCalculationById(offerCalcData?.offerCalculationId)
      .then(({ data }) => {
        setIsTestDisabled(!data?.payload?.tested);
        setIsPublishDisabled(!data?.payload?.tested);
        setCurrentFileName(data?.payload?.currentFileName);
        setCurrentOfferCalculationData(
          data?.payload?.currentOfferCalculationConfigurationPayload ?? {}
        );
        columnsAndDataDisplay(
          data?.payload?.currentOfferCalculationConfigurationPayload
            ?.inputVariableInfo ?? []
        );
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  useEffect(() => {
    columnsAndDataDisplay(currentOfferCalculationData?.inputVariableInfo ?? []);
  }, [currentOfferCalculationData]);

  const uploadDocumentBtn = () => {
    if ((Object?.keys(imageUrl ?? {})?.length ?? 0) > 0) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      API_SERVICE.uploadOfferCalculation(
        offerCalcData?.offerCalculationId,
        formData
      )
        .then(({ data }: any) => {
          setCurrentOfferCalculationData(
            data?.payload?.currentOfferCalculationConfigurationPayload ?? {}
          );
          columnsAndDataDisplay(
            data?.payload?.currentOfferCalculationConfigurationPayload
              ?.inputVariableInfo ?? []
          );
          setIsTestDisabled(true);
          setIsPublishDisabled(true);
          setUploadedDocumentName(data?.payload?.uploadedDocumentFileName);
          setImageUrl(null);
          setClearFileList(true);
          setCurrentFileName(data?.payload?.uploadedDocumentFileName);
          setTimeout(() => {
            setClearFileList(false);
          }, 1000);
        })
        .catch((e: any) => API_SERVICE.handleErrors(e));
    } else {
      notification.error({ message: "Please Upload file!" });
    }
  };

  const getPropertiesList = () => {
    const params = { screenForProductProperty: "OFFER_CALCULATION_SCREEN" };
    API_SERVICE.getPropertiesList(offerCalcData?.masterProductId, params)
      .then(({ data }) => {
        const displayNameListData = data.payload?.map((item: any) => {
          return {
            path: item.path,
            displayName: item.displayName,
            children: item?.children,
          };
        });
        setPropertiesList(displayNameListData);
        setPropertiesListFilter(displayNameListData);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const columnsAndDataDisplay = (data: any) => {
    const columns: any = [];
    data?.map((item: any, index: number) => {
      columns.push({
        title: item?.inputVariableNameFromExcel,
        dataIndex: item?.inputVariableNameFromExcel,
        key: item?.inputVariableNameFromExcel,
        render: () => {
          offerCalculationForm.setFieldsValue({
            [`${item?.inputVariableNameFromExcel}`]:
              item?.inputVariablePropertyPath,
            [`${item?.inputVariableNameFromExcel}-month`]:
              item?.inputVariableMonths,
          });
          return (
            <>
              <div
                className="fields-width"
                style={{ display: "flex", gap: "5px", marginTop: "10px" }}
              >
                <Tooltip title={item?.inputVariablePropertyPath}>
                  <Form.Item
                    name={item?.inputVariableNameFromExcel}
                    className="field-bg field-border p-0"
                  >
                    <Input
                      suffix={<DownOutlined />}
                      style={{ border: "none" }}
                      onChange={(e: any) => {
                        const tmpObj = { ...currentOfferCalculationData };
                        tmpObj.inputVariableInfo[index] = {
                          ...item,
                          inputVariablePropertyPath:
                            e?.target?.value?.length == 0
                              ? null
                              : e?.target?.value,
                        };
                        setCurrentOfferCalculationData(tmpObj);
                      }}
                      placeholder={`Select ${item?.inputVariableNameFromExcel}`}
                      onClick={() => {
                        setColumnType(item);
                        setPropertiesIndex(index);
                        setIsPropertyListModal(true);
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Tooltip>
                <Form.Item
                  name={`${item?.inputVariableNameFromExcel}-month`}
                  className="field-bg field-border p-0"
                  style={{ width: "100px" }}
                >
                  <Input
                    onBlur={(e: any) => {
                      const tmpObj = { ...currentOfferCalculationData };
                      tmpObj.inputVariableInfo[index] = {
                        ...item,
                        inputVariableMonths:
                          e?.target?.value?.length == 0
                            ? null
                            : e?.target?.value,
                      };
                      setCurrentOfferCalculationData(tmpObj);
                    }}
                    placeholder={`Month`}
                    autoComplete="off"
                    type="number"
                  />
                </Form.Item>
              </div>
            </>
          );
        },
      });
    });
    setColumnsDisplay(columns);
  };

  const renderList = (
    item: any,
    index: number,
    children: any,
    isChild = false
  ) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="child-view">
            {isChild && <div className="line-horizontal" />}
            <span>{item.displayName}</span>
          </div>
          {children?.length > 0 ? (
            ""
          ) : (
            <FormIconButton
              type={"clickFinger"}
              onClick={() => {
                const tmpObj = { ...currentOfferCalculationData };
                tmpObj.inputVariableInfo[propertiesIndex] = {
                  inputVariableNameFromExcel:
                    columnType?.inputVariableNameFromExcel,
                  inputVariablePropertyPath: item?.path,
                };
                setCurrentOfferCalculationData(tmpObj);
                setIsPropertyListModal(false);
              }}
            />
          )}
        </div>

        {item?.children?.length > 0 &&
          children?.map((data: any, index: number) => {
            const isLast = index == children?.length - 1;

            return (
              <div
                style={{
                  marginLeft: isChild ? "20px" : "10px",
                  display: "flex",
                }}
              >
                <div
                  className="line-vertical"
                  style={isLast ? { height: "22px" } : {}}
                />
                <p
                  style={{
                    marginTop: "5px",
                    marginBottom: "0px",
                    width: "100%",
                  }}
                >
                  {renderList(data, index, data?.children, true)}
                </p>
              </div>
            );
          })}
      </>
    );
  };

  const updateOfferCalculation = () => {
    const payload = {
      offerCalculationConfigurationPayload: currentOfferCalculationData,
      masterProductId: offerCalcData?.masterProductId,
      offerCalculationId: offerCalcData?.offerCalculationId,
      uploadedDocumentFileName: uploadedDocumentName,
    };
    API_SERVICE.updateOfferCalculation(payload)
      .then(({ data }) => {
        setIsTestDisabled(false);
        setCurrentOfferCalculationData(
          data?.payload?.currentOfferCalculationConfigurationPayload
        );
        notification.success({ message: "Offer-calculation update" });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const renderTableForm = () => {
    return (
      <Form form={offerCalculationForm} onFinish={updateOfferCalculation}>
        <div className={"layout-main"}>
          <Table
            columns={columnsDisplay}
            size="small"
            dataSource={[{}]}
            pagination={false}
          />
          <div className="btns-tables" style={{ marginTop: "15px" }}>
            <FormButton
              label="Save"
              style={{ background: "#20A74E" }}
              type="primary"
              htmlType="submit"
            />
            <FormButton
              label="Test"
              style={
                isTestDisabled
                  ? { background: "#0000000a" }
                  : { background: "#C71C8E" }
              }
              type="primary"
              disabled={isTestDisabled}
              onclick={() => setIsShowTest(true)}
            />
            <FormButton
              label="Publish"
              type="primary"
              disabled={isPublishDisabled}
              onclick={() => setIsShowPublishComment(true)}
            />
          </div>
        </div>
      </Form>
    );
  };

  const filterData = (items: any, filterText: any) => {
    return items
      .map((item: any) => {
        const filteredItem = { ...item };

        if (item.children) {
          filteredItem.children = filterData(item.children, filterText);
        }

        return filteredItem;
      })
      .filter(
        (item: any) =>
          item.displayName.toLowerCase().includes(filterText.toLowerCase()) ||
          (item.children && item.children.length > 0)
      );
  };

  const renderPropertiesList = () => {
    return (
      <Modal
        title="Properties List"
        width={660}
        open={isPropertyListModal}
        onCancel={() => setIsPropertyListModal(false)}
        footer={false}
      >
        <Card style={{ width: "100%", height: 370, overflow: "auto" }}>
          <Input
            size="large"
            className="search-input"
            style={{ marginBottom: "10px" }}
            prefix={<img src={searchIcon} alt="" />}
            placeholder="Search"
            allowClear
            onChange={(e: any) => {
              const filteredData = filterData(propertiesList, e?.target?.value);
              // const filterSearch = propertiesList?.filter((item: any) => {
              //   return item?.displayName
              //     ?.toLowerCase()
              //     ?.includes(e?.target?.value?.toLowerCase());
              // });
              setPropertiesListFilter(filteredData);
            }}
          />
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={propertiesListFilter}
            itemLayout="horizontal"
            renderItem={(item: any, index: number) => (
              <List.Item style={{ marginBottom: "4px" }}>
                {renderList(item, index, item?.children)}
              </List.Item>
            )}
          />
        </Card>
      </Modal>
    );
  };

  return (
    <>
      <Row gutter={24}>
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="upload-document-modal" style={{}}>
            <AppUpload
              className="upload-wrapper"
              acceptType={["xls", "xlsx"]}
              showUploadList={true}
              setImageUrl={setImageUrl}
              isCallBackFile={true}
              clearFileList={clearFileList}
              callBack={(value: any) => {
                setUploadedFile(value);
                setIsTestDisabled(true);
              }}
            >
              <div className="upload-wrapper">
                <p style={{ color: "#273896", marginBottom: "0px" }}>
                  Drag or click to upload file{" "}
                </p>
                <p style={{ color: "gray", marginBottom: "0px" }}>
                  (Only xlsx files)
                </p>
              </div>
            </AppUpload>
            <Button
              type="primary"
              className="upload-btn-submit"
              onClick={uploadDocumentBtn}
            >
              Upload
            </Button>
          </div>

          {Object?.keys(currentOfferCalculationData ?? {})?.length > 0 && (
            <div
              style={{
                color: "#1677ff",
                width: "455px",
                marginBottom: "30px",
              }}
            >
              {currentFileName}
            </div>
          )}
        </Col>
      </Row>
      {Object?.keys(currentOfferCalculationData ?? {})?.length > 0 &&
        renderTableForm()}
      {renderPropertiesList()}
    </>
  );
};
export default OfferCalculationDraft;
