import { Button, Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import parameters from "../shared/parameters";
const EmbeddingIframe = () => {
  const [connectorId, setConnectorId] = useState("");
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(600);

  const ref: any = useRef(null);

  const getIFrameUrl = () => {
    if(parameters.ApiUrlCommon?.includes("uat")) {
      return "https://uat-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("dev")) {
      return "https://dev-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("dev1")) {
      return "https://dev1-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("dev2")) {
      return "https://dev2-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("dev3")) {
      return "https://dev3-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("dev4")) {
      return "https://dev4-comp-widget.oneflotest.in"
    } else if(parameters.ApiUrlCommon?.includes("common.oneflo.in")) {
      return "https://complementor.oneflo.in/"
    }
    return "https://preprod-comp-widget.oneflotest.in"
    // return "http://localhost:3014"
  }

  useEffect(() => {
    setTimeout(() => {
      let iframe = document.getElementById('complementory-widget') as any;
      iframe?.contentWindow?.postMessage({href: window.location.href}, getIFrameUrl());
    }, 5000);
  },[])

  // const checkIfream = (time: number) => {
  //   setTimeout(() => {
  //     const iframe = document.getElementById('complementory-widget') as any;
  //       if (iframe) {
  //           iframe.contentWindow.postMessage({href: window.location.href}, 'https://complementor.oneflo.in/');
  //       } else {
  //         checkIfream(time)
  //       }
  //   }, time);
  // }

  return (
    <>
      <div
        className={"layout-main-top"}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          layout="inline"
          onFinish={(values) => {
            setConnectorId(values?.connectorId);
            setWidth(values?.width ?? 400);
            setHeight(values?.height ?? 600);
          }}
        >
          <Form.Item name="connectorId">
            <Input
              size="small"
              className="search-input"
              placeholder="Connector Id"
              allowClear
            />
          </Form.Item>
          <Form.Item name="width" initialValue={400}>
            <Input
              size="small"
              className="search-input"
              placeholder="Width"
              type="number"
              min={400}
              max={770}
            />
          </Form.Item>
          <Form.Item name="height" initialValue={600}>
            <Input
              size="small"
              className="search-input"
              placeholder="Height"
              type="number"
              min={500}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" style={{ height: "40px" }} htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div
        className={"layout-main"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ border: "1px solid lightgray", padding: "1px" }}>
          <iframe
            ref={ref}
            src={`${getIFrameUrl()}/bil/${connectorId}`}
            title="Complementory Widget"
            width={`${width}px`}
            height={`${height}px`}
            id="complementory-widget"
          />
        </div>
      </div>
    </>
  );
};

export default EmbeddingIframe;
