import { Form, Modal, Radio, Select } from "antd";
import FormButton from "../../../shared/component/FormButton/FormButton";

interface IDecisionModal {
    isDecisionModal: any;
    checkJourneyBuilderConfiguration: any;
    displayConfigDecisionList: any;
    configValue: any;
    configResultVal: any;
    cancelClick: () => void;
    saveClick: () => void;
    selectChange: (key: any) => void;
    radioChange: (key: any) => void;
}
const DecisionModel: React.FC<IDecisionModal> = (props: IDecisionModal) => {
    const { isDecisionModal,
        checkJourneyBuilderConfiguration,
        displayConfigDecisionList,
        configValue,
        configResultVal,
        cancelClick,
        saveClick,
        selectChange,
        radioChange
    } = props;
    
    return (
        <>
            <Modal
                // title="Select Journey Config Decision"
                title="Enable This Stage/Substage"
                open={isDecisionModal}
                width={300}
                closable={false}
                footer={[
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: "35px",
                        }}
                    >
                        <FormButton
                            label="Cancel"
                            onclick={cancelClick}
                            style={{ width: "110px", height: "40px" }}
                        />
                        <FormButton
                            label="Save"
                            type="primary"
                            disabled={
                                checkJourneyBuilderConfiguration?.stepRunTypeDisabled ??
                                false
                            }
                            onclick={saveClick}
                            style={{ width: "110px", height: "40px" }}
                        />
                    </div>,
                ]}
            >
                <div className="newPopup" style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Form.Item label="Stage Config Decision Table" style={{ marginBottom: '0px' }}>
                    </Form.Item>
                    <Select
                        className="select-loan"
                        // aria-label="Stage Config Decision Table"
                        // label={'Stage Config Decision Table'}
                        disabled={
                            checkJourneyBuilderConfiguration?.stepRunTypeDisabled ??
                            false
                        }
                        value={configValue}
                        style={{ width: "100%" }}
                        options={displayConfigDecisionList}
                        onChange={(key: any) => selectChange(key)}
                    />
                    <Form.Item label="Returns" style={{ marginBottom: '0px' }}>
                    </Form.Item>
                    <Radio.Group
                        onChange={(e) => radioChange(e?.target?.value)}
                        value={configResultVal}
                        disabled={
                            checkJourneyBuilderConfiguration?.stepRunTypeDisabled ??
                            false
                        }
                    >
                        <Radio value={true}>True</Radio>
                        <Radio value={false}>False</Radio>
                    </Radio.Group>
                </div>
            </Modal>
        </>
    )
};

export default DecisionModel;