import { Col, DatePicker, Drawer, Form, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../shared/component/FormInput/FormInput";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import FormButton from "../../shared/component/FormButton/FormButton";
import FormDatePicker from "../../shared/component/FormDatePicker/FormDatePicker";

interface IAddCoupons {
    openCouponsDrawer: any;
    setOpenCouponsDrawer: any;
    editCouponsData: any;
    isEdit: any;
};

const AddCoupons: React.FunctionComponent<IAddCoupons> = (props: IAddCoupons) => {
    const { openCouponsDrawer, setOpenCouponsDrawer, editCouponsData, isEdit } = props

    const [couponForm] = Form.useForm() as any;
    return (
        <>
            <Drawer
                title={isEdit ? "EDIT COUPON" : "ADD NEW COUPON"}
                footer={false}
                open={openCouponsDrawer}
                width={500}
                onClose={() => setOpenCouponsDrawer(false)}
            >
                <Form
                    form={couponForm}
                    layout="vertical"
                // onFinish={onFinalClick}
                >
                    <FormInput
                        label="Code Name"
                        classNameForm="space-input"
                        isBorder={true}
                        name="codeName"
                        placeholder="Enter Product Name"
                        requiredLabel="Product Name"
                        isRequired
                        isDisabled={isEdit}
                    />
                    <FormInput
                        label="Min Purchase Price"
                        classNameForm="space-input"
                        isBorder={true}
                        name="purchasePrice"
                        placeholder="Enter Price Range"
                        type="number"
                        isRequired
                        isDisabled={isEdit}

                    />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <FormInput
                            label="Discount Applicable"
                            classNameForm="space-input"
                            isBorder={true}
                            name="discountApplicable"
                            placeholder="Enter Discount %"
                            type="number"
                            isDisabled={isEdit}
                        />
                        <FormInput
                            label="Mix Discount Applicable"
                            classNameForm="space-input"
                            isBorder={true}
                            name="maxDisApplicable"
                            placeholder="Enter Mix Discount Applicable"
                            type="number"
                            isDisabled={isEdit}
                        />
                    </div>
                    <FormDatePicker
                        label="Start Date"
                        classNameForm="space-input"
                        isBorder={true}
                        name="startDate"
                        placeholder="Select Date"
                        isDisabled={isEdit}
                    />

                    <FormDatePicker
                        label="End Date"
                        classNameForm="space-input"
                        isBorder={true}
                        name="endDate"
                        placeholder="Select Date"
                    />

                    <FormInput
                        label="Reusable Time"
                        classNameForm="space-input"
                        isBorder={true}
                        name="reusableTime"
                        placeholder="Enter eusable Time"
                        type="number"
                        isDisabled={isEdit}
                    />

                    <FormSelect
                        label="User Type"
                        classNameForm="space-input"
                        isBorder={true}
                        name="userType"
                        placeholder="select User Type"
                        disabled={isEdit}
                    />

                    <FormInput
                        label="Discription"
                        classNameForm="space-input"
                        isBorder={true}
                        name="discription"
                        placeholder="Enter Discription"
                        isDisabled={isEdit}
                    />

                    <label>Status</label><br />
                    <span>
                        <Switch />
                    </span>

                    <div className='step-button'>
                        <FormButton
                            size="large"
                            label="Publish"
                            type="primary"
                            htmlType="submit"
                        />
                    </div>
                </Form>
            </Drawer>
        </>
    )
};

export default AddCoupons;