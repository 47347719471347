import { Form, Space, Typography, notification } from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import "./decisioning.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useEffect, useState } from "react";
import { getEnumList, getEnumValue } from "../../../shared/Utility/Utility";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";

interface IAddDecisioning {
  setShowAddDecisioning: any;
  getDecisioningList: any;
  editdecisioningData: any;
  setIsEdit: any;
  isEdit: any;
  setIsAddDuplicate: any;
  isAddDuplicate: any;
}

const { Title } = Typography;

const AddDecisioning: React.FC<IAddDecisioning> = (props: IAddDecisioning) => {
  const {
    setShowAddDecisioning,
    getDecisioningList,
    editdecisioningData,
    setIsEdit,
    isEdit,
    setIsAddDuplicate,
    isAddDuplicate,
  } = props;
  const [masterProductTypes, setMasterProductTypes] = useState([] as any);
  const [isPurposeRequired, setIsPurposeRequired] = useState(false);
  const [isLenderField, setIsLenderField] = useState(false);
  const [selectedDecision, setSelectedDecision] = useState("");
  const [eligibleLendersArr, setEligibleLendersArr] = useState([] as any);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const dsaBusinessId = JSON.parse(
    localStorage.getItem("user") as any
  )?.dsaBusinessId;
  const [addDecisioningForm] = Form.useForm() as any;

  useEffect(() => {
    getEnumTypes();
    getMasterProductType();
    if (isEdit || isAddDuplicate) {
      setIsLenderField(editdecisioningData?.decisionType == "SCORECARD");
      setIsPurposeRequired(
        ["DECISION_TABLE", "SCORECARD"].includes(
          editdecisioningData?.decisionType
        )
      );
      if (editdecisioningData?.decisionType == "SCORECARD") {
        listAllTheFinanciers();
      }
      addDecisioningForm.setFieldsValue({
        decisioningName: editdecisioningData?.name,
        decisionType: editdecisioningData?.decisionType,
        purpose: editdecisioningData?.purpose,
        description: editdecisioningData?.description,
        masterProduct: editdecisioningData?.masterProductId,
        lender: editdecisioningData?.lenderBusinessId,
      });
    } else {
      addDecisioningForm.setFieldsValue({
        masterProduct: productsObject?.productsId,
      });
    }
  }, []);

  const getEnumTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        localStorage.setItem("enumValues", JSON.stringify(data));
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const getMasterProductType = () => {
    const params = {
      pageSize: 100,
    };
    API_SERVICE.getMasterProductList(params)
      .then(({ data }) => {
        const displayList = data?.payload?.content?.map((item: any) => {
          return {
            key: item?.productId,
            value: item?.name,
          };
        });
        setMasterProductTypes(displayList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const addDecisioning = (payload: any) => {
    API_SERVICE.addDecisioning(payload)
      .then(() => {
        setShowAddDecisioning(false);
        getDecisioningList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateDecisioning = (payload: any) => {
    API_SERVICE.updateDecisioningProfile(editdecisioningData?.id, payload)
      .then(({ data }) => {
        setIsEdit(false);
        setShowAddDecisioning(false);
        getDecisioningList();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const duplicateDecisioning = (payload: any) => {
    // const payload = {
    //   description: values?.description,
    //   name: values?.decisioningName,
    //   purpose: values?.purpose,
    // };
    if (editdecisioningData?.name !== payload?.name) {
      API_SERVICE.duplicateDecisioning(editdecisioningData?.id, payload)
        .then((data) => {
          setIsAddDuplicate(false);
          setShowAddDecisioning(false);
          getDecisioningList();
        })
        .catch((e: any) => API_SERVICE.handleErrors(e));
    } else {
      notification.error({ message: "Please Change Decisioning Name" });
    }
  };

  const listAllTheFinanciers = () => {
    API_SERVICE.listAllTheFinanciers()
      .then(({ data }) => {
        const displayNameList = data?.payload?.map((listVal: any) => {
          return {
            key: listVal?.businessInfoId,
            value: listVal?.businessName,
          };
        });
        setEligibleLendersArr(displayNameList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onSubmit = (values: any) => {
    const payload = {
      decisionType: values?.decisionType,
      description: values?.description,
      // dsaBusinessId: dsaBusinessId,
      masterProductId: values?.masterProduct,
      name: values?.decisioningName,
      purpose: values?.purpose,
      lenderBusinessId: isLenderField ? values?.lender : "",
    };
    if (isEdit) {
      updateDecisioning(payload);
    } else if (isAddDuplicate) {
      duplicateDecisioning(payload);
    } else {
      addDecisioning(payload);
    }
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ padding: "17px" }}>
        {isAddDuplicate
          ? "Duplicate Decisioning"
          : isEdit
          ? "Edit Decisioning"
          : "Create Decisioning"}
      </Title>
      <div className="decisioning-form-layout">
        <Form
          layout="vertical"
          form={addDecisioningForm}
          className="decisioning-form"
          onFinish={onSubmit}
        >
          <FormInput
            name="decisioningName"
            label="Decisioning Name"
            placeholder="Enter decisioning name"
            isBorder
            isRequired
          />
          <FormSelect
            name="masterProduct"
            label="Master Product"
            placeholder="Select master product"
            disabled
            isBorder
            isRequired
            options={masterProductTypes}
          />
          <FormSelect
            name="decisionType"
            label="Decision Type"
            placeholder="Select decision type"
            disabled={isEdit || isAddDuplicate}
            isBorder
            isRequired
            options={getEnumList("DECISIONING_TYPE")}
            onChange={(e: any) => {
              setSelectedDecision(e);
              setIsLenderField(e == "SCORECARD");
              setIsPurposeRequired(["DECISION_TABLE", "SCORECARD"].includes(e));
              if (e == "SCORECARD") {
                listAllTheFinanciers();
              }
            }}
          />
          {isLenderField && (
            <FormSelect
              name="lender"
              label="Lender"
              placeholder="Select lender"
              optionFilterProp="children"
              isRequired
              isBorder
              disabled={isEdit}
              options={eligibleLendersArr}
            />
          )}
          {selectedDecision != "OFFER_DECISION" && (
            <FormSelect
              name="purpose"
              label="Purpose"
              placeholder="Select purpose"
              isBorder
              isRequired={isPurposeRequired}
              options={getEnumList("DECISIONING_PURPOSE")}
            />
          )}
          <FormTextArea
            name="description"
            label="Description"
            isBorder
            isRequired
            row={3}
          />
          <Space>
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "150px" }}
            />
            <FormButton
              label="Cancel"
              onclick={() => {
                setShowAddDecisioning(false);
                setIsEdit(false);
              }}
              style={{ height: "50px", width: "150px" }}
            />
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default AddDecisioning;
