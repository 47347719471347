import React from "react";
import { Drawer } from "antd";
import "./pricing.scss";

interface IEditProfile {
    isEdit : any,
}

const EditPricing: React.FC<IEditProfile> = (props: IEditProfile) => {
const {isEdit} = props
  return (
   <div>
     <Drawer
                title={`${isEdit ? "EDIT" : "CREATE A NEW"} PROPERTY`}
                footer={false}
                // open={showAddPropery}
                // onClose={() => setShowAddPropery(false)}
            >

            </Drawer>
   </div>
  );
};

export default EditPricing;
