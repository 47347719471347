import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import search from "../../assets/images/search.svg";
import { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import {
  setDispositionPageNo,
  setDispositionPageSize,
  setDispositionSearchParam,
} from "../../shared/redux/disposition-reducer";
import { RootState } from "../../shared/redux/store";
import FormInput from "../../shared/component/FormInput/FormInput";
import { API_SERVICE } from "../../shared/services/api-services";
import FormIconButton from "../../shared/component/FormIconButton/FormIconButton";
import FormButton from "../../shared/component/FormButton/FormButton";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
// import AddDisposition from "./AddDisposition";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

const { Text } = Typography;

const Disposition = () => {
  const [loading, setLoading] = useState(false);
  const [isAddOrEditDisable, setIsAddOrEditDisable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [fieldIndex] = useState([] as any);
  const [stageList, setStageList] = useState([] as any);
  const [dispositionData, setDispositionData] = useState([] as any);
  const [isAddClick, setIsAddClick] = useState(false);
  const disposition: any = useSelector((state: RootState) => state.disposition);
  const dispatch = useDispatch();
  const [dispositionForm] = Form.useForm() as any;

  useEffect(() => {
    getDispositionList();
    getMasterWorkflowList();
  }, []);

  const getDispositionList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = {
      direction: "ASC",
      name: searchParam,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    API_SERVICE.getDispositionList(params)
      .then(({ data }) => {
        setTotalCount(data?.payload?.totalElements);
        setDispositionData(data?.payload?.content ?? []);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const getMasterWorkflowList = () => {
    API_SERVICE.getDefaultMasterWorkflow()
      .then(({ data }) => {
        const stageDisplayList = data?.payload?.config?.stages?.map(
          (item: any) => {
            return {
              key: item?.key,
              value: item?.name,
              steps: item?.steps,
            };
          }
        );
        setStageList(stageDisplayList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDispositionPageNo(current));
    dispatch(setDispositionPageSize(pageSize));
    fieldIndex.length = 0;
    setIsAddOrEditDisable(false);
    setIsEdit(false);
    getDispositionList(current, pageSize, disposition.searchParam);
  };

  const handleSearch = (values: any) => {
    dispatch(setDispositionSearchParam(values?.searchParam?.trim()));
    getDispositionList(1, 10, values?.searchParam?.trim());
  };

  const onAddDisposition = () => {
    dispositionForm.setFieldsValue({
      stage: null,
      dispositionLevel1: "",
      dispositionLevel2: "",
    });
    let newDisposition = {
      stage: {
        key: "",
        name: "",
      },
      dispositionLevel1: "",
      dispositionLevel2: "",
    };
    const tmp = [...dispositionData];
    tmp.unshift(newDisposition);
    setDispositionData(tmp);
    setIsAddClick(true);
    setIsAddOrEditDisable(true);
    // getDispositionList();
    dispatch(setDispositionPageNo(1));
    dispatch(setDispositionPageSize(10));
  };

  useEffect(() => {
    if (isAddClick) {
      fieldIndex.push(0);
    }
    setIsAddClick(false);
  }, [isAddClick]);

  const onEdit = (record: any, index: number) => {
    setIsAddOrEditDisable(true);
    setIsEdit(true);
    setEditId(record?.id);
    fieldIndex.push(index);
    dispositionForm.setFieldsValue({
      stage: record?.stage?.key,
      dispositionLevel1: record?.dispositionLevel1,
      dispositionLevel2: record?.dispositionLevel2,
    });
  };

  const columns: any = [
    {
      title: "Stage",
      dataIndex: ["stage", "name"],
      key: "stage",
      width: 250,
      render: (value: any, record: any, index: number) => {
        if (fieldIndex.includes(index)) {
          return <FormSelect name="stage" options={stageList} isBorder />;
        } else {
          return <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Disposition Level 1",
      dataIndex: "dispositionLevel1",
      key: "dispositionLevel1",
      render: (value: any, record: any, index: number) => {
        if (fieldIndex?.includes(index)) {
          return (
            <FormInput
              name="dispositionLevel1"
              size="small"
              defaultValue={value}
              isBorder
            />
          );
        } else {
          return <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Disposition Level 2",
      dataIndex: "dispositionLevel2",
      key: "dispositionLevel2",
      render: (value: any, record: any, index: number) => {
        if (fieldIndex?.includes(index)) {
          return (
            <FormInput
              name="dispositionLevel2"
              size="small"
              defaultValue={value}
              isBorder
            />
          );
        } else {
          return <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <>
          {" "}
          <FormIconButton
            type="edit"
            isDisabled={isAddOrEditDisable}
            onClick={() => onEdit(record, index)}
          />
        </>
      ),
    },
  ];

  const saveData = (values: any) => {
    const payload: any = {
      dispositionLevel1: values?.dispositionLevel1,
      dispositionLevel2: values?.dispositionLevel2,
      stageKey: values?.stage,
    };
    if (isEdit) {
      payload["id"] = editId;
    }
    API_SERVICE.updateDisposition(payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        getDispositionList();
        fieldIndex.length = 0;
        setIsAddOrEditDisable(false);
        setIsEdit(false);
      })
      .catch((e: any) => API_SERVICE?.handleErrors(e));
  };

  const renderDisposition = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form layout="inline" onFinish={handleSearch}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ height: "40px" }}
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          <Space>
            <Button
              disabled={isAddOrEditDisable}
              onClick={onAddDisposition}
              type="primary"
              style={{ height: "40px" }}
            >
              Add Disposition
            </Button>
          </Space>
        </div>
        <div className={"layout-main"}>
          <Row style={{ padding: "15px" }}>
            <Col xs={24} xl={24} span={13}>
              <Form form={dispositionForm} onFinish={saveData}>
                <Table
                  columns={columns}
                  dataSource={dispositionData}
                  size="middle"
                  loading={loading}
                  onChange={(pagination: any) => {
                    handlePaginationChange(
                      pagination.current,
                      pagination.pageSize
                    );
                  }}
                  pagination={{
                    position: ["bottomCenter"],
                    current: disposition.pageNo,
                    pageSize: disposition.pageSize,
                    total: totalCount,
                  }}
                  scroll={{ x: "max-content" }}
                />
                <div className="btns-tables" style={dispositionData?.length > 0 ? { marginTop: '-45px' } : {}}>
                  <FormButton
                    disabled={!isAddOrEditDisable}
                    label="Save"
                    htmlType="submit"
                    type="primary"
                    style={{
                      width: "130px",
                    }}
                  />
                  {fieldIndex?.length > 0 && (
                    <FormButton
                      label="Cancel"
                      style={{ height: "40px" }}
                      onclick={() => {
                        fieldIndex.length = 0;
                        setIsEdit(false);
                        setIsAddOrEditDisable(false);
                        setIsAddClick(false);
                        getDispositionList(
                          disposition.pageNo,
                          disposition.pageSize,
                          disposition.searchParam
                        );
                      }}
                    />
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  return renderDisposition();
};
export default Disposition;
