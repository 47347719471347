import { Button, Form, Space, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { viewTypeProduct } from "../Products/Products";
import ProductJourney from "./ProductJourney";
import { API_SERVICE } from "../../../shared/services/api-services";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import JourneyDetails from "../ProductJourneyV2/JourneyDetails";
import { viewTypeJourneyProduct } from "../ProductJourneyV2/Journey";

type IAddProductJourney = {
  productWorkflowId: any;
  masterproductId?: any;
  fetchMasterProductList: any;
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
};

const { Title } = Typography;

const AddProductJourney: React.FunctionComponent<IAddProductJourney> = (
  props: IAddProductJourney
) => {
  const {
    productWorkflowId,
    masterproductId,
    setViewTypeJourney,
    fetchMasterProductList,
  } = props;
  const [addProductJourney] = Form.useForm() as any;
  const [masterProductTypes, setMasterProductTypes] = useState([] as any);
  const [workflowTypes, setWorkflowTypes] = useState([] as any);
  const [masterWorkflowId, setMasterWorkflowId] = useState("");
  const { productsObject } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    // getMasterProductType();
    getWorkflowLitelist();
    addProductJourney.setFieldsValue({
      selectProduct: productsObject.productsId,
    });
    if (productWorkflowId != "") {
      //setIsShowCreate(false);
    }
    setMasterProductTypes([
      {
        key: productsObject?.productsId,
        value: productsObject?.productsName,
      },
    ]);
  }, []);

  const getWorkflowLitelist = () => {
    API_SERVICE.getWorkflowLitelist()
      .then(({ data }) => {
        const workFlowList = data?.payload?.map((item: any) => {
          return {
            key: item?.workflowId,
            value: item?.workflowName,
          };
        });
        setWorkflowTypes(workFlowList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onFinish = (values: any) => {
    const payload = {
      masterWorkflowId: masterWorkflowId,
      description: values?.description,
      masterProductId: masterproductId,
      name: values?.journeyName,
    };
    //API_SERVICE.addProductJourney(payload)
    API_SERVICE.addWorkFlowProduct(payload)
      .then(({data}) => {
        notification.success({ message: data?.message });
        fetchMasterProductList(1, 10);
        handleCloseAddJourney();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const handleWorkflowChange = (value: any) => {
    setMasterWorkflowId(value);
  };

  const handleCloseAddJourney = () => {
    setViewTypeJourney("journey");
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ padding: "17px" }}>
        Create Product Journeys
      </Title>
      <div className="decisioning-form-layout">
        <Form
          layout="vertical"
          form={addProductJourney}
          className="decisioning-form"
          onFinish={onFinish}
        >
          <FormInput
            name="journeyName"
            label="Journey Name"
            placeholder="Enter Journey name"
            isBorder
            isRequired
          />
          <FormSelect
            name="selectProduct"
            label="Select Product"
            placeholder="Select product"
            isBorder
            isRequired
            disabled
            options={masterProductTypes}
          />
          <FormSelect
            name="selectWorkflow"
            label="Select Work Flow"
            placeholder="Select workflow"
            isBorder
            isRequired
            options={workflowTypes}
            onChange={handleWorkflowChange}
          />
          <FormTextArea
            name="description"
            label="Description"
            isBorder
            isRequired
          />
          <Space style={{ marginTop: "20px" }}>
            <FormButton
              label="Continue"
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "150px" }}
            />
            <FormButton
              label="Cancel"
              style={{ height: "50px", width: "150px" }}
              onclick={handleCloseAddJourney}
            />
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default AddProductJourney;
