import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "ProductReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setProductPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setProductPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setProductSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setProductPageNo, setProductPageSize, setProductSearchParam } = productSlice.actions;
export default productSlice.reducer;