import {
  Button,
  Form,
  Input,
  Popconfirm,
  Space,
  Typography,
  message,
  notification,
} from "antd";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { useEffect, useRef, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import { MinusCircleOutlined } from "@ant-design/icons";
import FormInput from "../../../shared/component/FormInput/FormInput";
import { NUMBER_REGEX } from "../../../shared/AppConstants";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import FormItem from "antd/es/form/FormItem";
import { useDispatch } from "react-redux";
import { setAlertMsg } from "../../../shared/redux/alert-reducer";
import NotificationMessage from "../../../shared/component/NotificationMessage/NotificationMessage";

const { Title } = Typography;

interface IAddAction {
  decisioningType: string;
  setShowAddAction: any;
  getDraftList: any;
  decisionData: any;
  currentPayloadData: any;
  isEditAction: any;
  editActionData: any;
  editActionIndex: any;
}

const AddAction: React.FC<IAddAction> = (props: IAddAction) => {
  const {
    decisioningType,
    setShowAddAction,
    getDraftList,
    decisionData,
    currentPayloadData,
    isEditAction,
    editActionData,
    editActionIndex,
  } = props;
  const [actionList, setActionList] = useState([] as any);
  const [typeInput] = useState([] as any);
  const [sumValue, setSumValue] = useState(0);
  const [isInputType, setIsInputType] = useState(false);
  const [decisioningActionData, setDecisioningActionData] = useState({} as any);
  const [decisioningDescription, setDecisioningDescription] = useState("");
  const [descriptionDisplay, setDescriptionDisplay] = useState("");
  const [addActionForm] = Form.useForm() as any;
  const ref: any = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getActionList();
    if (isEditAction) {
      setSumValue(100);
      addActionForm.setFieldsValue({
        selectAction: editActionData?.expression,
        fields: editActionData?.categoryInfoList,
      });
    }
  }, []);

  const getActionList = () => {
    API_SERVICE.getActionList(decisioningType)
      .then(({ data }) => {
        const displayList = data?.payload.map((item: any) => {
          return {
            key: item?.action,
            value: item?.action,
            description: item?.description,
            decisioningActionPlaceholderInfo:
              item?.decisioningActionPlaceholderInfo,
          };
        });
        if (isEditAction) {
          setDecisioningActionData(
            displayList?.find(
              (item: any) => item.key == editActionData?.expression
            )
          );
          setIsInputType(true);
        }
        setActionList(displayList);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  useEffect(() => {
    setDescriptionDisplay(decisioningActionData?.description);
    let description: any = [];
    if (
      (decisioningActionData?.decisioningActionPlaceholderInfo?.length ?? 0) > 0
    ) {
      decisioningActionData?.decisioningActionPlaceholderInfo.map(
        (item: any) => {
          typeInput.push(item?.inputType);
          description.push(item?.description);
        }
      );
    } else {
      description = [];
    }
    setDecisioningDescription(description?.toString());
    setIsInputType(false);
  }, [isInputType]);

  const order = {
    items: [{}],
  };
  const itemInputs = order.items.map((item: any) => {
    return {
      categroyName: item.categroyName,
      weightAge: item.weightAge,
    };
  });

  const onDelete = () => {
    let tmpArr = currentPayloadData?.actionMetadata;
    const remove = tmpArr.splice(editActionIndex, 1);

    const ruleData = currentPayloadData?.ruleMetadata?.map((rule: any) => {
      const ind = rule?.decisioningActions.findIndex(
        (dec: any) => dec?.actionId == remove?.at(0)?.actionId
      );
      rule?.decisioningActions?.splice(ind, 1);
      return rule;
    });

    const deletePayload = {
      currentPayload: {
        ...currentPayloadData,
        actionMetadata: tmpArr,
        ruleMetadata: ruleData,
      },
      decisionType: decisionData?.decisionType,
      description: decisionData?.description,
      masterProductId: decisionData?.masterProductId,
      dsaBusinessId: decisionData?.id,
      name: decisionData?.name,
      purpose: decisionData?.purpose,
    };
    addAction(deletePayload);
  };

  const addAction = (payload: any) => {
    const id = decisionData?.id;
    API_SERVICE.updateDecisioning(id, payload)
      .then(({ data }) => {
        setShowAddAction(false);
        getDraftList();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onSubmit = (values: any) => {
    let isActionExsits = currentPayloadData?.actionMetadata?.some(
      (item: any) => {
        return item?.expression == values?.selectAction;
      }
    );
    if (isEditAction) {
      if (
        isActionExsits &&
        values?.selectAction != editActionData?.expression
      ) {
        notification.error({ message: "Action already exsits" });
        return;
      }
    } else {
      if (isActionExsits) {
        notification.error({ message: "Action already exsits" });
        return;
      }
    }

    let actionSelected = actionList?.find(
      (item: any) => item?.value == values?.selectAction
    );
    const actionMetadata = currentPayloadData?.actionMetadata ?? [];

    const newCon = {
      actionId: isEditAction ? editActionData?.actionId : "",
      actionName: values?.selectAction,
      categoryInfoList: values?.fields,
      description: decisioningDescription,
      expression: values?.selectAction,
    };

    if (isEditAction) {
      const ind = actionMetadata.findIndex(
        (dec: any) => dec?.actionId == editActionData?.actionId
      );
      actionMetadata[ind] = newCon;
    } else {
      actionMetadata?.push(newCon);
    }

    const payload = {
      currentPayload: {
        ...currentPayloadData,
        actionMetadata: actionMetadata,
      },
      decisionType: decisionData?.decisionType,
      description: decisionData?.description,
      masterProductId: decisionData?.masterProductId,
      dsaBusinessId: decisionData?.id,
      name: decisionData?.name,
      purpose: decisionData?.purpose,
    };
    if (typeInput.includes("DROPDOWN") && decisioningType == "SCORECARD") {
      if (sumValue != 100) {
        dispatch(
          setAlertMsg({
            message: "Total Weightage must be equal to 100% required.",
            description: "Please change weightage(%)",
            showAlertMessage: true,
          })
        );
      } else {
        addAction(payload);
      }
    } else {
      addAction(payload);
    }
  };

  const weightAgeval = () => {
    let sum = 0;
    addActionForm.getFieldsValue()?.fields?.map((item: any) => {
      sum += item?.weightage ?? 0;
    });
    setSumValue(sum);
  };

  return (
    <>
      <Title level={5} style={{ padding: "17px" }}>
        {isEditAction ? "Edit Action" : "Add Action"}
      </Title>
      <NotificationMessage />
      <div>
        <div className="decisioning-form-layout">
          <Form
            form={addActionForm}
            className="decisioning-form"
            layout="vertical"
            onFinish={onSubmit}
          >
            <p style={{ padding: "10px", fontSize: "18px" }}>
              {descriptionDisplay}
            </p>
            <FormSelect
              name="selectAction"
              label="Select Action"
              isBorder
              isRequired
              options={actionList}
              onChange={(e: any) => {
                setDecisioningActionData(
                  actionList?.find((item: any) => item.key == e)
                );
                setIsInputType(true);
              }}
            />
            {typeInput.includes("DROPDOWN") &&
              decisioningType == "SCORECARD" && (
                <>
                  <Form.List name="fields" initialValue={itemInputs}>
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map(({ key, name }) => {
                            return (
                              <Space>
                                <FormInput
                                  name={[name, "categoryName"]}
                                  label="Category Name"
                                  isBorder
                                  style={{ width: "165px" }}
                                  isRequired
                                />
                                <FormInput
                                  name={[name, "weightage"]}
                                  label="weightage(%)"
                                  type="number"
                                  onBlur={(e: any) => {
                                    weightAgeval();
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      pattern: /^\d*(\.)?(\d{0,2})?$/,
                                      message: "Please enter vaild weightage",
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (value > 100) {
                                          return Promise.reject(
                                            "Please enter vaild weightage"
                                          );
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    },
                                  ]}
                                  isBorder
                                  style={{ width: "145px" }}
                                />
                                {key === 0 ? (
                                  <FormIconButton
                                    type={"plus"}
                                    onClick={() => {
                                      add();
                                      ref.current?.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }}
                                  />
                                ) : (
                                  <FormIconButton
                                    type={"minus"}
                                    onClick={() => {
                                      remove(name);
                                      weightAgeval();
                                      // weightAgeArr.splice(name, 1);
                                      // weightAgeArr.map((item: any) => {
                                      //   setSumValue((sum += parseFloat(item)));
                                      // });
                                    }}
                                  />
                                )}
                              </Space>
                            );
                          })}
                        </div>
                      );
                    }}
                  </Form.List>
                  <p
                    style={
                      sumValue == 100 ? { color: "green" } : { color: "red" }
                    }
                  >
                    Total Weightage: {sumValue}%
                  </p>
                </>
              )}
            <Space style={{ marginTop: "20px" }} ref={ref}>
              <FormButton
                label="Save"
                type="primary"
                htmlType="submit"
                style={{ height: "50px", width: "150px" }}
              />
              {isEditAction &&
                currentPayloadData?.actionMetadata?.length > 0 && (
                  <Form.Item>
                    <Popconfirm
                      title="If you delete this condition, your rule data will also disappear. Are you sure to delete?"
                      okText="Yes"
                      cancelText="No"
                      className="remove"
                      onConfirm={() => {
                        onDelete();
                      }}
                    >
                      <Button
                        htmlType={"button"}
                        style={{
                          height: "50px",
                          width: "150px",
                          fontSize: "16px",
                        }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              <FormButton
                label={"Cancel"}
                onclick={() => {
                  setShowAddAction(false);
                }}
                style={{ height: "50px", width: "150px" }}
              />
            </Space>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddAction;
