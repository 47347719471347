import React, { useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @ts-ignore
import { Form } from "react-formio";

type IFormJosnDataList = {
    fileData: any
};

const FormJosnDataList: React.FunctionComponent<IFormJosnDataList> = (props: IFormJosnDataList) => {
    const { fileData } = props
    return (
        <>
            <Form
                key={"jsonForm"}
                form={fileData}
                jsonSchema={fileData}
            />
        </>
    )
};

export default FormJosnDataList;
