import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const collectionSlice = createSlice({
  name: "CollectionReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setCollectionPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setCollectionPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setCollectionSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

export const { setCollectionPageNo, setCollectionPageSize, setCollectionSearchParam } = collectionSlice.actions;
export default collectionSlice.reducer;