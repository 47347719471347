import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const publishSlice = createSlice({
  name: "DecisioningReducer",
  initialState: { pageNo: 1, pageSize: 10 },
  reducers: {
    setPublishPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPublishPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

export const { setPublishPageNo, setPublishPageSize } = publishSlice.actions;
export default publishSlice.reducer;
