import { Button, Form, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import FormButton from "../../../shared/component/FormButton/FormButton";
import search from "../../../assets/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setProductPropertyPageNo,
  setProductPropertyPageSize,
  setProductPropertySearchParam,
} from "../../../shared/redux/productProperty-reducer";
import AddProductProperty from "./AddProductProperty/AddProductProperty";
import { viewTypeProduct } from "../Products/Products";
import { API_SERVICE } from "../../../shared/services/api-services";
import { setProductsObject, setScreenTitle } from "../../../shared/redux/common-reducer";
interface IProductProperty {
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  getProductList: any;
}

const ProductProperty: React.FunctionComponent<IProductProperty> = (
  props: IProductProperty
) => {
  const { setViewType, getProductList } = props;
  const [loading, setLoading] = useState(false);
  const [showAddProductProperty, setShowAddProductProperty] = useState(false);
  const [propertyList, setPropertyList] = useState([] as any);
  const [totalCount, setTotalCount] = useState(Number as any);
  const { productsObject } = useSelector((state: RootState) => state.common);
  const ProductProperty: any = useSelector(
    (state: RootState) => state.productProperty
  );
  const product: any = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    productPropertiesList();
  }, []);

  const columns: any = [
    {
      title: "Property Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Property Category",
      dataIndex: "category",
      key: "category",
    },
  ];

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setProductPropertyPageNo(current));
    dispatch(setProductPropertyPageSize(pageSize));
    productPropertiesList(current, pageSize, ProductProperty.searchParam);
  };

  const productPropertiesList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = {
      direction: "ASC",
      pageNo: pageNo,
      pageSize: pageSize,
      name: searchParam,
      productId: productsObject?.productsId,
    };
    API_SERVICE.productPropertiesList(params)
      .then(({ data }) => {
        setPropertyList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const renderProductProperty = () => {
    return (
      <>
        <div
          className={"layout-main-top"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Form
            layout="inline"
            onFinish={(value: any) => {
              dispatch(setProductPropertySearchParam(value?.searchParam));
              dispatch(setProductPropertyPageNo(1));
              productPropertiesList(
                1,
                ProductProperty.pageSize,
                value?.searchParam
              );
            }}
          >
            <Form.Item name="searchParam">
              <Input
                size="large"
                className="search-input"
                prefix={<img src={search} alt="" />}
                placeholder="Search"
                allowClear
              />
            </Form.Item>
            <FormButton
              label="Search"
              htmlType="submit"
              style={{ height: "40px" }}
              type="primary"
            />
          </Form>
          <div>
            <Button
              type="link"
              onClick={() => {
                setViewType("product");
                dispatch(setScreenTitle("Products"));
                dispatch(setProductsObject({}));
                getProductList(product?.pageNo, product?.pageSize, product.searchParam);
              }}
            >
              Back to product
            </Button>
            <Button
              type="primary"
              style={{ height: "40px" }}
              onClick={() => setShowAddProductProperty(true)}
            >
              Add Property
            </Button>
          </div>
        </div>

        <div className={"layout-main"}>
          <Table
            columns={columns}
            dataSource={propertyList}
            size="middle"
            loading={loading}
            onChange={(pagination: any) => {
              handlePaginationChange(pagination.current, pagination.pageSize);
            }}
            pagination={{
              position: ["bottomCenter"],
              current: ProductProperty.pageNo,
              pageSize: ProductProperty.pageSize,
              total: totalCount,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </>
    );
  };
  return (
    <>
      {showAddProductProperty ? (
        <AddProductProperty
          setShowAddProductProperty={setShowAddProductProperty}
          productPropertiesList={productPropertiesList}
        />
      ) : (
        renderProductProperty()
      )}
    </>
  );
};

export default ProductProperty;
