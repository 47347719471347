import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const productPropertySlice = createSlice({
  name: "ProductPropertyReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setProductPropertyPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setProductPropertyPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setProductPropertySearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    },
  },
});

export const { setProductPropertyPageNo, setProductPropertyPageSize, setProductPropertySearchParam } =
  productPropertySlice.actions;
export default productPropertySlice.reducer;
