import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const scoreCardSlice = createSlice({
  name: "DecisioningReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setScoreCardPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setScoreCardPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setScoreCardSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  },
});

export const { setScoreCardPageNo, setScoreCardPageSize, setScoreCardSearchParam } =
  scoreCardSlice.actions;
export default scoreCardSlice.reducer;
