import React, { useEffect, useState } from 'react';
import { Form, Row, Space, Typography } from 'antd';
import FormButton from '../../../../shared/component/FormButton/FormButton';
import FormSelect from '../../../../shared/component/FormSelect/FormSelect';
import { API_SERVICE } from '../../../../shared/services/api-services';
import "../../properties.scss"

const { Text } = Typography;

interface IAddFieldType {
    selectedStage: number;
    setSelectedStage: Function;
    setFormData2: any;
    formData2: any;
    setObjectList: any;
    objectList: any;
    isEdit: boolean
}

const AddFieldType: React.FC<IAddFieldType> = (props: IAddFieldType) => {
    const { selectedStage, setSelectedStage, setFormData2, formData2, setObjectList, objectList, isEdit } = props

    const [propertyTypeValue, setPropertyTypeValue] = useState(formData2?.propertyType);
    const [fieldTypeValue, setFieldTypeValue] = useState(formData2?.fieldType);
    const [fieldTypeForm] = Form.useForm() as any;

    const defaultPropertys = formData2?.children?.map((obj: any) => obj?.id ?? obj?.key)

    useEffect(() => {
        fieldTypeForm.setFieldsValue({
            propertyType: formData2?.propertyType,
            fieldType: formData2?.fieldType?.toLowerCase(),
            propertyList: defaultPropertys,
        });
    }, []);

    const getPropertyOption = () => {
        const propertyOption = formData2?.children?.map((obj: any) => {
            return {
                key: obj?.id ?? obj?.key,
                value: obj?.displayName ?? obj?.value
            }
        });
        return propertyOption ?? []
    }

    const getPropertyList = (name = null) => {
        const param = { parentExists: false, name: name, pageSize: 100 };
        API_SERVICE.getPropertyList(param)
            .then(({ data }: any) => {
                const displayNameListData = data?.payload?.content?.map((item: any) => {
                    return { key: item.id, value: item.displayName };
                });
                const arr = displayNameListData?.filter((item: any) => !defaultPropertys?.includes(item?.key));
                setObjectList(arr)
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    const propertyType: any = [
        {
            key: "simple",
            value: "Simple"
        },
        {
            key: "complex",
            value: "Complex"
        }
    ];

    const fieldType: any = [
        {
            key: "TEXT",
            value: "Text"
        },
        {
            key: "NUMBER",
            value: "Number"
        },
        {
            key: "DATE",
            value: "Date"
        },
        {
            key: "DOUBLE",
            value: "Double"
        },
        {
            key: "BOOLEAN",
            value: "Boolean"
        }
    ]

    const onPropertySearch = (value: any = null) => {
        getPropertyList(value);
    }

    const onPropertyTypeChange = (value: any) => {
        setPropertyTypeValue(value);
        if (value === "complex") {
            getPropertyList();
        }
    }

    const onFieldTypeChange = (value: any) => {
        setFieldTypeValue(value)
    }

    const onNextClick = (record: any) => {
        setSelectedStage(3);
        const filterProperty = [...objectList, ...getPropertyOption()]?.filter((item:any) => record?.propertyList?.includes(item?.key))
        const submitData = {
            ...formData2,
            propertyType: record?.propertyType,
            fieldType: record?.fieldType,
            children: filterProperty,
            propertyList: record?.propertyList
        }
        setFormData2(submitData)
    }

    return (

        <Form
            form={fieldTypeForm}
            layout="vertical"
            onFinish={onNextClick}>

            <FormSelect
                label="Property Type"
                name="propertyType"
                placeholder="Select Property Type"
                classNameForm="space-input"
                isBorder
                options={propertyType}
                onChange={onPropertyTypeChange}
                disabled={isEdit}
                isRequired
            />
            {(propertyTypeValue === "simple") ?
                <>
                    <FormSelect
                        label="Field Type"
                        name="fieldType"
                        classNameForm="space-input"
                        placeholder="Enter Field Type"
                        isBorder
                        disabled={isEdit}
                        options={fieldType}
                        onChange={onFieldTypeChange}
                        isRequired
                    />
                    <div className='preview-box'>
                        <div className="preview-name-div">
                            <label >Preview</label>
                        </div>

                        <div className='preview-marging'>
                            <Text className='text-color'>
                                {fieldTypeValue?.toLowerCase() === "text" && "Name"}
                                {fieldTypeValue?.toLowerCase() === "number" && "0000"}
                                {fieldTypeValue?.toLowerCase() === "boolean" && "True/False"}
                                {fieldTypeValue?.toLowerCase() === "date" && "DD/MM/YYYY"}
                                {fieldTypeValue?.toLowerCase() === "double" && "0.00"}
                            </Text>
                        </div>
                        <label className='preview-lable' >{fieldTypeValue}</label>
                    </div>

                </> : (propertyTypeValue === "complex") ?
                    <>
                        <FormSelect
                            name="propertyList"
                            optionFilterProp="children"
                            placeholder="Property List"
                            mode="multiple"
                            isBorder
                            maxTagCount="responsive"
                            onFocus={()=>{onPropertySearch()}}
                            onSearch={onPropertySearch}
                            options={[...objectList, ...getPropertyOption()]}
                        />
                    </> : ""
            }
            <Row className='step-button'>
                <Space >
                    <FormButton
                        size="large"
                        label="Back"
                        type='default'
                        onclick={(e) => {
                            setSelectedStage(selectedStage - 1);
                            if (!isEdit) {
                                setFormData2(e)
                            }
                        }}
                    />
                    <FormButton
                        size="large"
                        label="Next"
                        type="primary"
                        htmlType="submit"
                    />
                </Space>
            </Row>
        </Form >

    );
};

export default AddFieldType;