import { Button, Col, Row, Space, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DownOutlined, UpOutlined, DownloadOutlined } from "@ant-design/icons";
import { RootState } from "../../../shared/redux/store";
import {
  saveByteArray,
  base64ToArrayBuffer,
} from "../../../shared/Utility/Utility";
import {
  setOfferCalculationPublishPageNo,
  setOfferCalculationPublishPageSize,
} from "../../../shared/redux/offerCalculationPublish-reducer";
import { API_SERVICE } from "../../../shared/services/api-services";

interface IOfferCalculationPublish {
  offerCalcPublishId: any;
}

const OfferCalculationPublish: React.FC<IOfferCalculationPublish> = (
  props: IOfferCalculationPublish
) => {
  const { offerCalcPublishId } = props;
  const [isShowFullDetils, setIshowFullDetials] = useState(false);
  const [publishData, setPublishData] = useState(null as any);
  const [publishColumns, setPublishColumns] = useState([] as any);

  useEffect(() => {
    if ((offerCalcPublishId?.length ?? 0) > 0) {
      publishDetailsById();
    }
  }, []);

  const publishDetailsById = () => {
    API_SERVICE.publishOfferCalculationById(offerCalcPublishId)
      .then(({ data }) => {
        setPublishData(data?.payload);
        columnsAndDataDisplay(
          data?.payload?.publishOfferCalculationConfigurationPayload
            ?.inputVariableInfo ?? []
        );
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const columnsAndDataDisplay = (data: any) => {
    const columns: any = [];
    data?.map((item: any, index: number) => {
      columns.push({
        title: item?.inputVariableNameFromExcel,
        dataIndex: item?.inputVariableNameFromExcel,
        key: item?.inputVariableNameFromExcel,
        // align: "center",
        render: () => {
          if (item?.inputVariableMonths) {
            return (
              <div>{`${item?.inputVariablePropertyPath ?? ""},${
                item?.inputVariableMonths ?? ""
              }`}</div>
            );
          } else {
            return <div>{`${item?.inputVariablePropertyPath ?? ""}`}</div>;
          }
        },
      });
    });
    setPublishColumns(columns);
  };

  const downloadDocument = () => {
    let id = publishData?.publishDocumentId;
    API_SERVICE.getUploadDocument(id)
      .then(({ data }: any) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray(
          [bytesData],
          data?.payload?.fileName + "." + data?.payload?.fileExtn
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <>
      <div style={{ padding: "15px" }}>
        <Space>
          <p>Publish Details</p>
          <p style={{ width: "202px" }}>
            User Name: {publishData?.createdUser?.firstName}
          </p>
          <p>User ID: {publishData?.createdUser?.userId}</p>
        </Space>
        <a
          style={{
            float: "right",
            marginRight: "10px",
            color: "#273896",
          }}
          onClick={() => setIshowFullDetials(!isShowFullDetils)}
        >
          Full Details {isShowFullDetils ? <UpOutlined /> : <DownOutlined />}
        </a>
        <br />
        {isShowFullDetils && (
          <Space
            style={{
              paddingLeft: "99px",
              gap: "50px",
            }}
          >
            <p style={{ width: "161px" }}>
              Publish Date: {publishData?.lastUpdatedDate}
            </p>
            <p style={{ width: "109px" }}>
              Publish Version: {publishData?.version}
            </p>
            <p>Comment: {publishData?.publishComments}</p>
          </Space>
        )}
      </div>
      <div style={{ height: "250px" }}>
        <Table
          columns={publishColumns}
          dataSource={[{}]}
          size="small"
          pagination={false}
          scroll={{ x: "max-content" }}
        />
        {(offerCalcPublishId?.length ?? 0) > 0 && (
          <Row gutter={24}>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
                color: "#1677ff",
              }}
            >
              {publishData?.publishFileName}
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <Button
                type="primary"
                style={{ background: "#20A74E" }}
                onClick={downloadDocument}
              >
                <DownloadOutlined
                  style={{ height: "18px", width: "16px", marginTop: "-5px" }}
                />
                Download
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default OfferCalculationPublish;
