import { Divider, Form, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
const { Title } = Typography;

interface IPublishComment {
  setIsShowPublishComment: any;
  offerCalcData: any;
  setOfferCalcPublishId: any;
  setTabKey: any;
}

const PublishComment: React.FunctionComponent<IPublishComment> = (
  props: IPublishComment
) => {
  const {
    setIsShowPublishComment,
    offerCalcData,
    setOfferCalcPublishId,
    setTabKey,
  } = props;
  const [publishCommentForm] = Form.useForm() as any;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const onPublishComment = (value: any) => {
    setIsLoading(true);
    let payload = {
      publishingComment: value.commentPublish,
      comments: value.commentPublish,
      offerCalculationId: offerCalcData?.offerCalculationId,
    };
    API_SERVICE.publishOfferCalculation(payload)
      .then(({ data }) => {
        setOfferCalcPublishId(data?.payload?.currentPublishedId ?? "");
        setTabKey("2");
        setIsShowPublishComment(false);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={"layout-main"} style={{ marginTop: "20px" }}>
        <Title level={3} className="condition-title">
          Publish Comment
        </Title>
        <Divider style={{ margin: "0" }} />
        <div className="decisioning-form">
          <Form
            form={publishCommentForm}
            layout="vertical"
            onFinish={onPublishComment}
          >
            <FormTextArea
              label="Comment"
              classNameForm="space-input"
              isBorder={true}
              row={4}
              name="commentPublish"
              placeholder="Publish Comment..."
              isRequired
            />

            <Space style={{ marginTop: "20px" }}>
              <FormButton
                size="large"
                label="Save"
                type="primary"
                htmlType="submit"
              />
              <FormButton
                size="large"
                label="Cancel"
                onclick={() => setIsShowPublishComment(false)}
              />
            </Space>
          </Form>
        </div>
        {isLoading && <AppLoading />}
      </div>
    </>
  );
};

export default PublishComment;
