import { Form, Progress,Space,Typography } from "antd";
import { useNavigate } from "react-router-dom";
import FormInput from "../../shared/component/FormInput/FormInput";
import checkedGreyCircle from "../../assets/images/Group 16324.svg";
import checkedGreenCircle from "../../assets/images/Group 16322.svg";
import "./SignIn.scss";
import FormButton from "../../shared/component/FormButton/FormButton";
import { useState } from "react";
import { PASSWORD_REGEX } from "../../shared/AppConstants";
import { API_SERVICE } from "../../shared/services/api-services";

interface IResetPassword {
  getEmail: string;
}

const ResetPassword: React.FC<IResetPassword> = ( props: IResetPassword ) => {
    const { getEmail } = props;
    const navigate = useNavigate();
  const [password1, setPassword1] = useState("");
  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordDigit, setPasswordDigit] = useState(false)
  const [passwordUppercase, setPasswordUppercase] = useState(false)
  const [passwordLowercase, setPasswordLowercase] = useState(false)
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)
  const [progressStatus, setProgressStatus] = useState(false) 
  

  const handleSubmit = (values: any) => {
    const payload = {
     "email": getEmail,
     "password": values?.newPassword
    };
    API_SERVICE.setUpdatePassword(payload)
      .then(({ data }) => {
        if (data) {
          navigate("/sign-in");
        }
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };
  const handlePasswordPolicy = (e:any) =>{
    setProgressStatus(e.target.value.length > 0 ? true  : false)
    setPasswordLength(e.target.value.length > 7 ? true:false)
    setPasswordDigit(/\d/.test(e.target.value))
    setPasswordUppercase(/(?=[A-Z])[A-Z]/.test(e.target.value))
    setPasswordLowercase(/(?=[a-z])[a-z]/.test(e.target.value))
    setPasswordSpecialChar(/[$@$!%*#?&]/.test(e.target.value))   
    handleProgressBar(e)
}


  const handleProgressBar = (e:any) =>{
    let password = new Array();
    password.push("[$@$!%*#?&]");
    password.push("[A-Z]"); 
    password.push("[0-9]"); 
    password.push("[a-z]"); 

  var progress = 0;
  for (var i = 0; i < password.length; i++) {
      if (new RegExp(password[i]).test(e.target.value)) {
        progress++;
      }
  }
  if(progress > 2 && e.target.value.length > 7){
    progress++;
  }
  
  switch (progress) {
      case 0:
      setProgressPercent(0);
      break;
      case 1: 
      setProgressPercent(20);
      break;
      case 2:
        setProgressPercent(40);
        break; 
      case 3:
        setProgressPercent(60);
          break;
      case 4:
        setProgressPercent(80);
          break;
      case 5:
        setProgressPercent(100);
          break;
    }
  }


  return (
    <div className="scroll-container" style={{paddingBottom:"20px"}}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <FormInput
          label="Set New Password"
          classNameForm="space-input"
          isBorder={true}
          name="password"
          requiredLabel="Password"
          placeholder="Enter Your Password"
          type="password"
          isRequired
          onChange={(e)=>handlePasswordPolicy(e)}
          rules={[
                               {
                                   pattern: PASSWORD_REGEX,
                                 message: "Please enter valid password",
                             },
                         ]}
        />
              <div className="passwordPolicy">
               <div style={{paddingBottom:'10px'}} >
                <img src = {passwordLength ? checkedGreenCircle : checkedGreyCircle}  className="imagePasswrodPolicy" style={{paddingRight:"10px"}} />
                  Length should be greater than 8
              </div>
              <div style={{paddingBottom:'10px'}}>
              <img src = {passwordDigit ? checkedGreenCircle : checkedGreyCircle} style={{paddingRight:"10px"}} className="imagePasswrodPolicy"/>
                  Minimum 1 digit in password
              </div>
              <div style={{paddingBottom:'10px'}}>
              <img src = {passwordUppercase ? checkedGreenCircle : checkedGreyCircle} style={{paddingRight:"10px"}} className="imagePasswrodPolicy"/>
                  Minimun 1 uppercase letter in password
              </div>
              <div style={{paddingBottom:'10px'}}>
              <img src = {passwordLowercase ? checkedGreenCircle : checkedGreyCircle} style={{paddingRight:"10px"}} className="imagePasswrodPolicy"/>
                  Minimun 1 lowercase letter in password
              </div>
              <div style={{paddingBottom:'10px'}}>
              <img src = {passwordSpecialChar ? checkedGreenCircle : checkedGreyCircle} style={{paddingRight:"10px"}} className="imagePasswrodPolicy"/>
                    Minimun 1 special character in password <span style={{paddingLeft:'30px'}}>like @,#,$</span> 
              </div>
              </div>
              {progressStatus &&
          <Space>
          <Typography.Title level = {5}>Password Strength</Typography.Title>
          <Typography.Title level = {5} style={{paddingLeft:"90px",
            
            color : (progressPercent === 100 )?"rgba(52,168,83,1)":"rgba(189,0,0,1"
          }}> {(progressPercent === 100) ? "Strong" : "Weak" }</Typography.Title>
          </Space>
        }
         {progressStatus &&
          <Progress
            percent={progressPercent}
            showInfo={false}
            strokeColor={progressPercent === 100 ? "rgba(52,168,83,1)" : "rgba(189,0,0,1"}
            style={{
              marginBottom:"10px",
            }}>
          </Progress>
         }

        <FormInput
          label="Re-enter New Password"
          classNameForm="space-input"
          isBorder={true}
          name="newPassword"
          requiredLabel="Re-enter new password"
          placeholder="Re-enter new Password"
          type="password"
          rules={[
            {
              required: true,
              message: `Please re-enter your new password`,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        />
        <FormButton
          size="large"
          label="Save"
          type="primary"
          htmlType="submit"
        />
      </Form>
    </div>
  );
};

export default ResetPassword;
