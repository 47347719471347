import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import OfferCalculationDraft from "./OfferCalculationDraft";
import OfferCalculationPublish from "./OfferCalculationPublish";
import TestOfferCalculation from "./TestOfferCalculation";
import PublishComment from "./PublishComment";
import { useDispatch, useSelector } from "react-redux";
import { setOfferCalculationObject } from "../../../shared/redux/common-reducer";
import { RootState } from "../../../shared/redux/store";
import { setOfferCalculationSearchParam } from "../../../shared/redux/offerCalculation-reducer";
import OfferCalculationPublishHistory from "./OfferCalculationPublishHistory";

interface IOfferCalculationTabs {
  getOfferCalculationList: any;
  setShowOfferCalculationTabs: any;
  offerCalcData: any;
}

const OfferCalculationTabs: React.FC<IOfferCalculationTabs> = (
  props: IOfferCalculationTabs
) => {
  const {
    getOfferCalculationList,
    setShowOfferCalculationTabs,
    offerCalcData,
  } = props;
  const [isShowTest, setIsShowTest] = useState(false);
  const [isShowPublishComment, setIsShowPublishComment] = useState(false);
  const [offerCalcPublishId, setOfferCalcPublishId] = useState(
    offerCalcData?.currentPublishedId ?? ""
  );
  const [tabKey, setTabKey] = useState("1");
  const [isTestDisabled, setIsTestDisabled] = useState(false);
  const [isPublishDisabled, setIsPublishDisabled] = useState(false);
  const offer: any = useSelector((state: RootState) => state.offerCalculation);
  const dispatch = useDispatch();

  const addButtons = (
    <div className="add-btns">
      <a
        style={{
          textDecoration: "underline",
          color: "#1677ff",
          padding: "8px",
          fontSize: "medium",
        }}
        onClick={() => {
          setShowOfferCalculationTabs(false);
          dispatch(setOfferCalculationObject({}));
          dispatch(setOfferCalculationSearchParam(null));
          getOfferCalculationList(
            offer?.pageNo,
            offer?.pageSize,
            offer.searchParam
          );
        }}
      >
        Back To OfferCalculation
      </a>
    </div>
  );

  return (
    <div className={"layout-main"} style={{ marginTop: "20px" }}>
      {isShowTest ? (
        <TestOfferCalculation
          setIsShowTest={setIsShowTest}
          offerCalcData={offerCalcData}
          setIsPublishDisabled={setIsPublishDisabled}
        />
      ) : isShowPublishComment ? (
        <PublishComment
          setIsShowPublishComment={setIsShowPublishComment}
          offerCalcData={offerCalcData}
          setOfferCalcPublishId={setOfferCalcPublishId}
          setTabKey={setTabKey}
        />
      ) : (
        <Tabs
          tabBarExtraContent={addButtons}
          className="scorecard-tabs"
          activeKey={tabKey}
          type="card"
          onChange={(key) => setTabKey(key)}
        >
          <Tabs.TabPane key="1" tab={<span className="tabActive">Drafts</span>}>
            <OfferCalculationDraft
              offerCalcData={offerCalcData}
              setIsShowTest={setIsShowTest}
              setIsShowPublishComment={setIsShowPublishComment}
              setIsPublishDisabled={setIsPublishDisabled}
              isPublishDisabled={isPublishDisabled}
              setIsTestDisabled={setIsTestDisabled}
              isTestDisabled={isTestDisabled}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            key="2"
            tab={<span className="tabActive">Publish</span>}
          >
            <OfferCalculationPublish
              offerCalcPublishId={offerCalcPublishId}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="3"
            tab={<span className="tabActive">Publish History</span>}
          >
            <OfferCalculationPublishHistory
              offerCalcData={offerCalcData}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};
export default OfferCalculationTabs;
