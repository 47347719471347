import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "CommonReducer",
  initialState: {
    screenTitle: "Dashboard",
    decisioningObject: { "decisionName": "", "masterProductId": undefined, "decisionType": undefined, "purpose": undefined, "lenderName": undefined },
    productsObject: { "productsName": "", "productsCategory": undefined, "productsType": undefined, "description": undefined, "productsId": "" },
    offerCalculationObject: {"offerCalculationName": "", "lenderBusinessName": "", "offerCalculationType": "", "currentFileName": ""},
    journeyObject: { "name": "",  "productsName": undefined, "description": undefined},
  },
  reducers: {
    setScreenTitle: (state, action: PayloadAction<string>) => {
      state.screenTitle = action.payload;
    },
    setDecisioningObject: (state, action: PayloadAction<any>) => {
      state.decisioningObject = action.payload;
    },
    setProductsObject: (state, action: PayloadAction<any>) => {
      state.productsObject = action.payload;
    },
    setOfferCalculationObject: (state, action: PayloadAction<any>) => {
      state.offerCalculationObject = action.payload;
    },
    setJourneyObject: (state, action: PayloadAction<any>) => {
      state.journeyObject = action.payload;
    }
  },
});

export const { setScreenTitle, setDecisioningObject, setProductsObject, setOfferCalculationObject ,setJourneyObject } = commonSlice.actions;
export default commonSlice.reducer;
