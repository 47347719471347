import { Col, Divider, List, Row, Space } from "antd";
import React, { useState } from "react";
import EditPricing from "./EditPricing";
import FormIconButton from "../../shared/component/FormIconButton/FormIconButton";
import "./pricing.scss";

const Pricing: React.FC = () => {
  const [showEditPricing, setShowEditPricing] = useState(false);

  const data1 = [
    <div style={{ width: "100%" }}>
      <div className="lists">
        <span>Base Cost</span>
        <Space>
          <div className="bold-text">Rs. 50 per lead</div>
          <FormIconButton
            type={"edit"}
            onClick={() => setShowEditPricing(true)}
          />
        </Space>
      </div>
      <Divider />
      <p className="lists">
        <span className="bold-text">Location</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>All</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>Metro</span>
        <span className="bold-text">+ Rs.20 per lead</span>
      </p>
      <p className="lists">
        <span>Non-Metro</span>
        <span className="bold-text">- Rs.20 per lead</span>
      </p>
      <Divider />
      <p className="lists">
        <span className="bold-text">Exclusive Lead</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>Yes</span>
        <span className="bold-text">3X of CPL</span>
      </p>
      <p className="lists">
        <span>No</span>
        <span className="bold-text">No Change</span>
      </p>
      <Divider />
      <p className="lists">
        <span className="bold-text">Verified Number</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>Yes</span>
        <span className="bold-text">+ Rs.20 per lead</span>
      </p>
      <p className="lists">
        <span>No</span>
        <span className="bold-text">- Rs.20 per lead</span>
      </p>
      <Divider />
      <p className="lists">
        <span className="bold-text">Age</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>{"> 18"}</span>
        <span className="bold-text">- Rs.10 per lead</span>
      </p>
      <p className="lists">
        <span>{"> 21"}</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>{"> 25"}</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>{"> 30"}</span>
        <span className="bold-text">+ Rs.30 per lead</span>
      </p>
      <Divider />
      <p className="lists">
        <span className="bold-text">Employment Type</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>Salaried</span>
        <span className="bold-text">+ 50 per lead</span>
      </p>
      <p className="lists">
        <span>Self-employed</span>
        <span className="bold-text">- 20 per lead</span>
      </p>
    </div>,
  ];

  const data2 = [
    <div style={{ width: "100%" }}>
      <p className="lists">
        <span className="bold-text">Monthly Salary</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>15K & Above</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>25K & Above</span>
        <span className="bold-text">+ Rs.50 per lead</span>
      </p>
      <p className="lists">
        <span>30K & Above</span>
        <span className="bold-text">+ Rs.50 per lead</span>
      </p>
      <p className="lists">
        <span>50K & Above</span>
        <span className="bold-text">+ Rs.100 per lead</span>
      </p>
      <Divider />
      <p className="lists">
        <span className="bold-text">Annual Turnover</span>
        <FormIconButton
          type={"edit"}
          onClick={() => { }}
        />
      </p>
      <p className="lists">
        <span>{"< 10L"}</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>10 to 25L</span>
        <span className="bold-text">No Change</span>
      </p>
      <p className="lists">
        <span>25 to 50L</span>
        <span className="bold-text">+ Rs.50 per lead</span>
      </p>
      <p className="lists">
        <span>50L to 3Cr</span>
        <span className="bold-text">+ Rs.75 per lead</span>
      </p>
      <p className="lists">
        <span>{"> 3Cr"}</span>
        <span className="bold-text">+ Rs.200 per lead</span>
      </p>
    </div>,
  ];

  return (
    <div style={{ paddingTop: "30px" }}>
      <div className={"layout-main"}>
        <Row style={{ padding: "20px" }} gutter={[12, 12]}>
          <Col span={12}>
            <List
              size="small"
              header={false}
              footer={false}
              bordered
              dataSource={data1}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
          <Col span={12}>
            <List
              size="small"
              header={false}
              footer={false}
              bordered
              dataSource={data2}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
        </Row>
      </div>
      {showEditPricing &&
        <EditPricing
          isEdit={showEditPricing}
        />}
    </div>
  );
};

export default Pricing;
