import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setAlertMsg } from "../../redux/alert-reducer";
import { useEffect } from "react";


const NotificationMessage = () => {

    const dispatch = useDispatch();
    const { alertmsg } = useSelector((state: RootState) => state.alert);

    useEffect(() => {
        if (alertmsg.showAlertMessage) {
            setTimeout(() => {
                dispatch(setAlertMsg(false));
            }, 3000);
        }
    }, [alertmsg.showAlertMessage]);

    return (
        <>
            {alertmsg.showAlertMessage && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Alert
                        style={{ position: "fixed", zIndex: 9999, top: "30%", verticalAlign: "center", width: '40%' }}
                        message={alertmsg.message}
                        description={alertmsg.description}
                        type={"success"}
                        closeIcon />
                </div>
            )}

        </>
    );
};

export default NotificationMessage;
