import { Form, Space, Tabs, Typography, notification } from "antd";
import SimpleComplexeProperty from "./SimpleComplexeProperty";
import React, { useState } from "react";
import FormButton from "../../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../../shared/services/api-services";
import CollectionProperty from "./CollectionProperty";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/redux/store";

interface IAddProductProperty {
  setShowAddProductProperty: any;
  productPropertiesList:any;
}

const { Title } = Typography;

const AddProductProperty: React.FC<IAddProductProperty> = (
  props: IAddProductProperty
) => {
  const { setShowAddProductProperty, productPropertiesList } = props;
  const [tabChangeKey, setTabChangeKey] = useState("SIMPLE");
  const [isTabChange, setIsTabChange] = useState(false);
  const [selectedSimpleProperty, setSelectedSimpleProperty] = useState(
    [] as any
  );
  const [selectedComplexProperty, setSelectedComplexProperty] = useState(
    [] as any
  );
  const [selectedCollectionProperty, setSelectedCollectionProperty] = useState(
    [] as any
  );
  const { productsObject } = useSelector((state: RootState) => state.common);

  const tabOnChange = (key: any) => {
    setTabChangeKey(key);
    setIsTabChange(true);
  };

  const addProduct = () => {
    const payload = {
      collectionIds:
        (selectedCollectionProperty?.length ?? 0) > 0
          ? selectedCollectionProperty
          : null,
      complexMasterPropertiesIds:
        (selectedComplexProperty?.length ?? 0) > 0
          ? selectedComplexProperty
          : null,
      simpleMasterPropertiesIds:
        (selectedSimpleProperty?.length ?? 0) > 0
          ? selectedSimpleProperty
          : null,
    };
    API_SERVICE.addProductProperty(productsObject?.productsId, payload)
      .then(({ data }) => {
        setShowAddProductProperty(false);
        productPropertiesList();
        notification.success({ message: data?.payload });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ padding: "17px" }}>
        Add Properties
      </Title>
      <Form
        name="simpleForm"
        layout="vertical"
        onFinish={() => {
          addProduct();
        }}
        autoComplete="off"
        style={{ width: "100%" }}
      >
        <div className="decisioning-form-layout" style={{ padding: "15px" }}>
          <Tabs defaultActiveKey="1" onChange={tabOnChange}>
            <Tabs.TabPane
              key="SIMPLE"
              tab={<span className="tabActive">Simple Property</span>}
            >
              <SimpleComplexeProperty
                tabChangeKey={tabChangeKey}
                setSelectedComplexProperty={setSelectedComplexProperty}
                selectedComplexProperty={selectedComplexProperty}
                setSelectedSimpleProperty={setSelectedSimpleProperty}
                selectedSimpleProperty={selectedSimpleProperty}
                setIsTabChange={setIsTabChange}
                isTabChange={isTabChange}
                productId={productsObject?.productsId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="COMPLEX"
              tab={<span className="tabActive">Complex Property</span>}
            >
              <SimpleComplexeProperty
                tabChangeKey={tabChangeKey}
                setSelectedComplexProperty={setSelectedComplexProperty}
                setSelectedSimpleProperty={setSelectedSimpleProperty}
                selectedComplexProperty={selectedComplexProperty}
                selectedSimpleProperty={selectedSimpleProperty}
                setIsTabChange={setIsTabChange}
                isTabChange={isTabChange}
                productId={productsObject?.productsId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="COLLECTION"
              tab={<span className="tabActive">Collection</span>}
            >
              <CollectionProperty
                setSelectedCollectionProperty={setSelectedCollectionProperty}
                selectedCollectionProperty={selectedCollectionProperty}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <Space style={{marginLeft:"40px"}}>
          <FormButton
            label="Save"
            type="primary"
            htmlType="submit"
            style={{ height: "50px", width: "150px" }}
          />
          <FormButton
            label="Cancel"
            onclick={() => {
              setShowAddProductProperty(false);
            }}
            style={{ height: "50px", width: "150px" }}
          />
        </Space>
      </Form>
    </div>
  );
};

export default AddProductProperty;
