import {
  Button,
  Form,
  Modal,
  Radio,
  Space,
  Typography,
  notification,
} from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import { viewTypeProduct } from "./Products";
import "./product.scss";
import addIcon from "../../../assets/images/addDocument.svg";
import AppUpload from "../../../shared/component/AppUpload/AppUpload";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import ReactJson from "@microlink/react-json-view";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import { getEnumList } from "../../../shared/Utility/Utility";
const { Title } = Typography;

interface IAddProduct {
  getProductList: any;
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  isEdit: any;
  editProductData: any;
  setIsAddDuplicate: any;
  isAddDuplicate: any;
  setIsEdit: any;
}

const AddProducts: React.FC<IAddProduct> = (props: IAddProduct) => {
  const {
    setViewType,
    isEdit,
    editProductData,
    isAddDuplicate,
    setIsAddDuplicate,
    setIsEdit,
    getProductList,
  } = props;
  const [addProductForm] = Form.useForm() as any;
  const [productTypes, setProductTypes] = useState([] as any);
  const [typeAndCategories, setTypeAndCategories] = useState([] as any);
  const [documentData, setDocumentData] = useState(null as any);
  const [docType, setDocType] = useState(null as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [displayImage, SetDisplayImage] = useState(null as any);
  const [fileData, setfileData] = useState([] as any);
  const [openUpload, setOpenUpload] = useState(false);
  const [productIconDoctId, setProductIconDocId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cloneResponse, setCloneResponse] = useState(undefined as any);
  const user = JSON?.parse(localStorage.getItem("user") as string);
  const product: any = useSelector((state: RootState) => state.product);

  useEffect(() => {
    getProductTypes();
    if (isEdit || isAddDuplicate) {
      addProductForm.setFieldsValue({
        productName: isAddDuplicate ? "" : editProductData?.name,
        productCategory: editProductData?.productType.category,
        productType: editProductData?.productType.id,
        description: isAddDuplicate ? "" : editProductData?.description,
      });
      if (editProductData?.productIconDocumentId) {
        getDocUploadById();
      }
    }
  }, []);

  const getDocUploadById = () => {
    let id = editProductData?.productIconDocumentId;
    API_SERVICE.getUploadDocument(id)
      .then(({ data }) => {
        setDocumentData(data?.payload?.documentDetails);
        setDocType(data?.payload?.fileExtn);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const createProductTypes = (
    category: any,
    tmpTypeAndCategories: any = null
  ) => {
    const array =
      (typeAndCategories?.length ?? 0) == 0
        ? tmpTypeAndCategories
        : typeAndCategories;
    const productTypes = array.find((i: any) => i.category === category);
    if (productTypes) {
      return productTypes.types.map((i: any) => {
        return {
          key: i.id,
          value: i.type,
        };
      });
    }

    return [];
  };

  const getProductTypes = () => {
    API_SERVICE.getProductTypes()
      .then(({ data }: any) => {
        setTypeAndCategories(data?.payload);
        if (isEdit || isAddDuplicate) {
          setProductTypes(
            createProductTypes(
              editProductData?.productType.category ?? "",
              data?.payload
            )
          );
        }
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
        // setLoading(false);
      });
  };

  const addDocument = () => {
    const payload = {
      documentURL: fileData?.data,
      documentName: fileData?.file?.name,
      documentType: "OTHERS",
      documentSubType: "MASTER_PRODUCT_ICON",
    };
    if (fileData?.data) {
      API_SERVICE.addDocument(user?.businessId, payload)
        .then(({ data }) => {
          SetDisplayImage(imageUrl);
          setProductIconDocId(data?.payload?.businessDocumentId);
          setfileData([]);
          setOpenUpload(false);
        })
        .catch((e: any) => API_SERVICE.handleErrors(e));
    } else {
      notification.error({ message: "Please Upload Icon" });
    }
  };

  const addProduct = (payload: any) => {
    API_SERVICE.addProduct(payload)
      .then(() => {
        setViewType("product");
        getProductList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateProduct = (payload: any) => {
    API_SERVICE.updateProduct(editProductData?.productId, payload)
      .then(({ data }) => {
        setIsEdit(false);
        setViewType("product");
        getProductList(product.pageNo, product.pageSize, product.searchParam);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const duplicateProduct = (payload: any) => {
    let values = addProductForm.getFieldsValue();
    const params = {
      masterProductIdToCloneFrom: editProductData?.productId,
      masterProductName: values?.productName,
      masterProductDescription: values?.description,
      cloneAllDecisioningAndEnable: values?.cloneAllDecisioningAndEnable,
      shouldCloneNonPublishedDecisioning:
        values?.shouldCloneNonPublishedDecisioning,
      cloneAllOfferCalculationExcels: values?.cloneAllOfferCalculationExcels,
    };
    setLoading(true);
    API_SERVICE.productClone(params)
      .then(({ data }) => {
        setCloneResponse(data);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  const onSubmit = (values: any) => {
    const payload = {
      productType: values?.productType,
      description: values?.description,
      name: values?.productName,
      productIconDocumentId: productIconDoctId,
    };
    if (isEdit) {
      updateProduct(payload);
    } else if (isAddDuplicate) {
      duplicateProduct(values);
    } else {
      addProduct(payload);
    }
  };

  const renderUploadIcon = () => (
    <Modal
      title="Upload Icon"
      open={openUpload}
      onCancel={() => setOpenUpload(false)}
      closable={false}
      footer={false}
      width={350}
    >
      <div>
        <AppUpload
          acceptType={["svg"]}
          showUploadList={true}
          setImageUrl={setImageUrl}
          className="upload-wrapper"
          maxSize={2}
          callBack={(value: any) => {
            setfileData(value);
          }}
        >
          <div>
            <img src={addIcon} style={{ height: "25px" }} />
            Select Icon
          </div>
        </AppUpload>
        <p style={{ textAlign: "center", fontWeight: "500" }}>
          Recommended size is 48 x 48 pixels
        </p>
      </div>
      <FormButton
        type="primary"
        label="Upload"
        style={{ height: "47px", background: "#273896" }}
        onclick={() => addDocument()}
      />
      <FormButton
        label="Cancel"
        style={{ height: "47px" }}
        onclick={() => {
          setOpenUpload(false);
        }}
      />
    </Modal>
  );

  return (
    <div className={"layout-main"} style={{ marginTop: "15px" }}>
      <Title level={5} style={{ padding: "17px" }}>
        {isAddDuplicate
          ? "Duplicate Product"
          : isEdit
          ? "Edit Product"
          : "Add Product"}
      </Title>
      <div className="decisioning-form-layout" style={{ display: "flex" }}>
        <Form
          layout="vertical"
          form={addProductForm}
          className="decisioning-form"
          onFinish={onSubmit}
          disabled={cloneResponse}
        >
          <FormInput
            name="productName"
            label="Product Name"
            placeholder="Enter product name"
            isBorder
            isRequired
          />
          <FormSelect
            name="productCategory"
            label="Product Category"
            placeholder="Select product category"
            onChange={(e) => {
              setProductTypes(createProductTypes(e));
              addProductForm.setFieldsValue({
                productType: null,
              });
            }}
            disabled={isAddDuplicate}
            isBorder
            isRequired
            options={getEnumList("MASTER_PRODUCT_CATEGORY")}
          />
          <FormSelect
            name="productType"
            label="Product Type"
            placeholder="Select product type"
            disabled={isAddDuplicate}
            isBorder
            isRequired
            options={productTypes}
          />
          {!isAddDuplicate && (
            <>
              <Form.Item
                name="selectIcon"
                label="Select Icon"
                className="field-bg field-border"
                style={{ margin: "0", borderRadius: "10px 10px 0px 0px" }}
              >
                <img
                  style={{ width: "30px" }}
                  alt=""
                  src={
                    displayImage
                      ? URL?.createObjectURL(displayImage)
                      : "data:image/" +
                        docType +
                        "+xml;" +
                        ";base64," +
                        documentData
                  }
                />
              </Form.Item>
              <div className="iconList-box">
                <Button
                  type="text"
                  style={{ width: "100%" }}
                  onClick={() => setOpenUpload(true)}
                >
                  Upload SVG
                </Button>
              </div>
            </>
          )}
          <FormTextArea
            name="description"
            label="Description"
            isBorder
            isRequired
            row={3}
          />
          {isAddDuplicate && (
            <>
              <Form.Item
                name="cloneAllDecisioningAndEnable"
                label="Clone All Decisioning And Enable"
                initialValue={true}
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: true,
                    message: `Clone All Decisioning And Enable is required`,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="shouldCloneNonPublishedDecisioning"
                label="Should Clone Non Published Decisioning"
                initialValue={true}
                style={{ marginBottom: "15px" }}
                rules={[
                  {
                    required: true,
                    message: `Should Clone Non Published Decisioning is required`,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="cloneAllOfferCalculationExcels"
                label="Clone All Offer Calculation Excels"
                initialValue={true}
                style={{ marginBottom: "0px" }}
                rules={[
                  {
                    required: true,
                    message: `Clone All Offer Calculation Excels is required`,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          <Space style={{ paddingTop: "20px" }}>
            <FormButton
              label="Save"
              type="primary"
              htmlType="submit"
              style={{ height: "50px", width: "150px" }}
              disabled={cloneResponse}
            />
            <FormButton
              label={cloneResponse ? "Back" : "Cancel"}
              disabled={false}
              onclick={() => {
                setViewType("product");
                setIsEdit(false);
                setIsAddDuplicate(false);
                if (cloneResponse) {
                  getProductList(
                    product.pageNo,
                    product.pageSize,
                    product.searchParam
                  );
                }
              }}
              style={{ height: "50px", width: "150px" }}
            />
          </Space>
        </Form>
        {cloneResponse && (
          <div
            style={{
              flex: 1,
              margin: "10px",
              maxHeight: "600px",
              overflow: "auto",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Clone Response:</p>
            <p>{"Message: " + cloneResponse?.message ?? ""}</p>
            <ReactJson src={cloneResponse?.payload} displayDataTypes={false} />
          </div>
        )}
      </div>
      {renderUploadIcon()}
      {loading && <AppLoading />}
    </div>
  );
};

export default AddProducts;
