import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const offerCalculationPublishSlice = createSlice({
    name: "OfferCalculationPublishReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null, masterProductFilter: null },
    reducers: {
        setOfferCalculationPublishPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setOfferCalculationPublishPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setOfferCalculationPublishSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        },
       
    }
});

export const { setOfferCalculationPublishPageNo, setOfferCalculationPublishPageSize, setOfferCalculationPublishSearchParam } = offerCalculationPublishSlice.actions;
export default offerCalculationPublishSlice.reducer;