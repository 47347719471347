import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Space, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import { RootState } from "../../../shared/redux/store";
import {
  setDocumentTypePageNo,
  setDocumentTypePageSize,
} from "../../../shared/redux/documentType-reducer";

const { Title } = Typography;

interface AddDocumentScreen {
  setAddDocumentScreenOpen: any;
  getDocumentList: any;
}

const AddDocumentScreen: React.FC<AddDocumentScreen> = (
  props: AddDocumentScreen
) => {
  const { setAddDocumentScreenOpen, getDocumentList } = props;

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [docTypeList, setDocTypeList] = useState<any>([]);
  const [mandatoryIds, setMandatoryIds] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const { productsObject } = useSelector((state: RootState) => state.common);
  const documentType: any = useSelector(
    (state: RootState) => state.documentType
  );
  const document: any = useSelector((state: RootState) => state.document);

  useEffect(() => {
    getDocumentTypeList();
  }, []);

  const getDocumentTypeList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    setLoading(true);
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParameter: searchParam,
    };

    API_SERVICE.getDocumentTypes(params)
      .then(({ data }) => {
        const docList = data.payload.content.map((doc: any) => {
          doc.key = doc.id;
          return doc;
        });
        setDocTypeList(docList ?? []);
        setTotalCount(data?.payload?.totalElements);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: any = [
    {
      title: "Document",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "documentCategoryResponseList",
      key: "documentCategoryResponseList",
      render: (_: any, record: any) => (
        <>
          {record?.documentCategoryResponseList
            ?.map((item: any) => item.name)
            .join(", ")}
        </>
      ),
    },
    {
      title: "Mandatory",
      dataIndex: "mandatory",
      key: "mandatory",
      render: (_: any, record: any) => (
        <>
          {
            <Checkbox
              checked={mandatoryIds?.some((id:any) => id == record?.id)}
              onChange={(e) => {
                let tmp = [...mandatoryIds];
                if (mandatoryIds?.includes(record?.id)) {
                  tmp = mandatoryIds.filter((item: any) => item != record?.id);
                } else {
                  tmp.push(record?.id);
                }
                setMandatoryIds(tmp);
              }}
            />
          }
        </>
      ),
    },
  ];

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setDocumentTypePageNo(current));
    dispatch(setDocumentTypePageSize(pageSize));
    getDocumentTypeList(current, pageSize);
  };

  const onSave = () => {
    const ids = selectedRowKeys?.map((item) => {
      return {
        documentTypeId: item,
        mandatory: mandatoryIds?.includes(item),
      };
    });

    const params = {
      documentTypeMapRequest: ids,
      mandatory: true,
      masterProductId: productsObject.productsId,
    };

    setLoadingSave(true);
    API_SERVICE.createDocumentProduct(params)
      .then((data) => {
        dispatch(setDocumentTypePageNo(1));
        dispatch(setDocumentTypePageSize(10));
        setAddDocumentScreenOpen(false);
        getDocumentList(
          document.pageNo,
          document.pageSize,
          document.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingSave(false);
        }, 500);
      });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Title level={5} style={{ paddingTop: "17px", marginLeft: "10px" }}>
        Add new document
      </Title>
      <Divider />

      <Table
        rowSelection={rowSelection}
        bordered
        dataSource={docTypeList}
        columns={columns}
        loading={loading}
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          position: ["bottomCenter"],
          current: documentType.pageNo,
          pageSize: documentType.pageSize,
          total: totalCount,
        }}
        scroll={{ x: "max-content" }}
      />
      <Space
        style={{ display: "flex", justifyContent: "end", padding: "10px" }}
      >
        <FormButton
          size="large"
          label="Save"
          type="primary"
          htmlType="button"
          loading={loadingSave}
          onclick={() => onSave()}
        />
        <FormButton
          size="large"
          label={"Cancel"}
          type="default"
          onclick={() => {
            dispatch(setDocumentTypePageNo(1));
            dispatch(setDocumentTypePageSize(10));
            setAddDocumentScreenOpen(false);
          }}
        />
      </Space>
    </div>
  );
};

export default AddDocumentScreen;
