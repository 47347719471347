import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const couponsSlice = createSlice({
    name: "CouponsReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setCouponsPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setCouponsPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setCouponsSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setCouponsPageNo, setCouponsPageSize, setCouponsSearchParam } = couponsSlice.actions;
export default couponsSlice.reducer;