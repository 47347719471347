import { Button, Col, Form, Input, Row, Table, Modal, Tabs } from "antd";
import React, { useState } from "react";
import search from "../../../src/assets/images/search.svg";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import {
  setPageNo,
  setPageSize,
  setSearchParam,
} from "../../../src/shared/redux/decisioning-audit-reducer";
import { RootState } from "../../../src/shared/redux/store";
import { API_SERVICE } from "../../../src/shared/services/api-services";
import Publish from "./PublishID";
import ReactJson from "@microlink/react-json-view";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

type IDecisioningAudit = {};

const DecisioningAudit: React.FunctionComponent<IDecisioningAudit> = () => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [descAuditList, setDecAuditList] = useState([]);
  const decaudit: any = useSelector((state: RootState) => state.product);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpenTitle, setModalOpenTitle] = useState("");
  const [displayJson, setDisplayJson] = useState({} as any);
  const [caseId, setCaseId] = useState("");
  const [publishedId, setPublishedId] = useState("");
  const [openPublish, setOpenPublish] = useState(false);
  const [outputData, setOutputData] = useState<any>({});
  const [decisionData, setDecisionData] = useState({} as any);

  const [searchCaseForm] = Form.useForm() as any;
  const dispatch = useDispatch();

  const columns: ColumnsType<columns> = [
    {
      title: "Decision Data ID",
      dataIndex: "decisioningDataId",
      key: "decisioningDataId",
    },
    {
      title: "Decision Master Name",
      dataIndex: "decisioningMasterName",
      key: "decisioningMasterName",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Journey Name",
      dataIndex: "journeyName",
      key: "journeyName",
    },
    {
      title: "Input",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setDisplayJson(record.inputData);
              showModal("Input");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Output",
      align: "center",
      render: (record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setDisplayJson(record.outputData);
              showModal("Output");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Decentro",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              getDecentroFinbox(caseId, val.decisioningDataId, "GET_BUREAU");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Finbox",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              getDecentroFinbox(
                caseId,
                val.decisioningDataId,
                "BANK_STATEMENT_ANALYSIS"
              );
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Publish ID",
      dataIndex: "decisioningPublishedId",
      key: "decisioningPublishedId",
      align: "center",
      render: (text: string, record: any) => {
        return (
          <>
            <a
              style={{ color: "#008dff" }}
              onClick={() => {
                setDecisionData(record);
                setOutputData(record?.outputData);
                setPublishedId(text);
                getPublishDetails(text);
              }}
            >
              {text}
            </a>
          </>
        );
      },
    },
  ];

  const getPublishDetails = (publishId: any) => {
    setLoading(true);
    const params = { decisioningPublishedId: publishId };
    API_SERVICE.getPublishDetails(params)
      .then(({ data }: any) => {
        console.log(data);
        setOpenPublish(true);
        // const displayNameList =
        //   data?.payload?.publishedPayload?.ruleMetadata?.map((listVal: any) => {
        //     return {
        //       key: listVal?.businessInfoId,
        //       value: listVal?.businessName,
        //     };
        //   });
        // console.log("log", displayNameList);
        // setEligibleLendersArr(displayNameList);
        setLoading(false);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDecentroFinbox = (
    caseId: any,
    decisioningDataId: any,
    vasType: any
  ) => {
    setLoading(true);
    const params = {
      caseId: caseId,
      decisioningDataId: decisioningDataId,
      vasType: vasType,
    };
    API_SERVICE.getDecentroFinbox(params)
      .then(({ data }: any) => {
        if (data?.payload?.decentroResponse) {
          setDisplayJson(data?.payload?.decentroResponse);
          showModal("Decentro");
        }

        if (data?.payload?.finboxResponse) {
          setDisplayJson(data?.payload?.finboxResponse);
          showModal("Finbox");
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDecisionAuditList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = { pageNo: pageNo, pageSize: pageSize, caseId: searchParam };
    API_SERVICE.getDecisioningAuditList(params)
      .then(({ data }: any) => {
        setDecAuditList(data.payload.content);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setPageNo(current));
    dispatch(setPageSize(pageSize));
    getDecisionAuditList(current, pageSize, decaudit.searchParam);
  };

  const handleSearch = (value: any) => {
    setOpenPublish(false);
    setCaseId(value?.searchParam);
    dispatch(setPageNo(1));
    dispatch(setSearchParam(value?.searchParam));
    getDecisionAuditList(1, decaudit.pageSize, value?.searchParam);
  };

  const showModal = (title: any) => {
    setIsModalOpen(true);
    setModalOpenTitle(title);
  };

  const showJsonData = (displayJson: any) => {
    return <pre>{JSON.stringify(displayJson, null, 2)}</pre>;
  };

  const modalView = (modaltitle: string) => {
    return (
      <Modal
        title={modaltitle}
        centered
        width={900}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        closeIcon={true}
        footer={null}
      >
        <div style={{ height: "80vh", overflow: "auto" }}>
          {/* {showJsonData(displayJson)} */}
          <ReactJson
            src={displayJson}
            displayDataTypes={false}
            collapsed={true}
          />
        </div>
      </Modal>
    );
  };

  const backDecisioning = () => {
    setOpenPublish(false);
  };

  const addButtons = (
    <div className="add-btns">
      <Button
        type="text"
        style={{ color: "#1677ff" }}
        onClick={backDecisioning}
      >
        Back
      </Button>
    </div>
  );

  const renderTabView = () => {
    return (
      <Tabs
        tabBarExtraContent={addButtons}
        className="scorecard-tabs"
        defaultActiveKey="1"
        type="card"
      >
        <Tabs.TabPane key="2" tab={<span className="tabActive">Publish</span>}>
          <Publish
            currentPublishedId={publishedId}
            decisionData={decisionData}
            outputData={outputData}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  return (
    <>
      {modalView(modalOpenTitle)}
      <div
        className={"layout-main-top"}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form layout="inline" form={searchCaseForm} onFinish={handleSearch}>
          <Form.Item
            name="searchParam"
            label="Case ID"
            rules={[
              {
                required: true,
                message: "Please enter caseId",
              },
            ]}
          >
            <Input
              size="large"
              className="search-input"
              prefix={<img src={search} alt="" />}
              placeholder="Search"
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" style={{ height: "40px" }} htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>

      {!openPublish ? (
        <div className={"layout-main"}>
          <Row style={{ padding: "15px" }}>
            <Col xs={24} xl={24} span={13}>
              <Table
                columns={columns}
                dataSource={descAuditList}
                size="middle"
                loading={loading}
                onChange={(pagination: any) => {
                  handlePaginationChange(
                    pagination.current,
                    pagination.pageSize
                  );
                }}
                pagination={{
                  position: ["bottomCenter"],
                  current: decaudit.pageNo,
                  pageSize: decaudit.pageSize,
                  total: totalCount,
                }}
                scroll={{ x: "max-content" }}
              />
            </Col>
          </Row>
        </div>
      ) : (
        renderTabView()
      )}
    </>
  );
};

export default DecisioningAudit;
