import { notification } from "antd";
import { API_SERVICE } from "../services/api-services";

export const numberWithCommas = (x: any) => {
  if (x == null) {
    return 0;
  }
  let value = parseFloat(x)?.toFixed(2);
  return value?.toString().split(".")[0].length > 3
    ? value
        ?.toString()
        .substring(0, x?.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        value?.toString().substring(x?.toString().split(".")[0].length - 3)
    : value?.toString();
};

export const fileUploadCommon = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file?.originFileObj ?? file);
  try {
    const { data } = await API_SERVICE.fileUpload(formData);
    return { file, data };
  } catch (e) {
    notification.error({ message: API_SERVICE.handleErrors(e) });
    return { file, data: null };
  }
};

export const saveByteArray = (function () {
  var a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data: any, name: any) {
    var blob = new Blob(data, { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const base64ToArrayBuffer = (base64: any) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const downloadDocument = (id: any) => {
  API_SERVICE.getUploadDocument(id)
    .then(({ data }) => {
      console.log(data);
      let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
      saveByteArray([bytesData], data.payload.documentName);
    })
    .catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
};

export const getEnumList = (propertyName: string) => {
  let enumValues = localStorage.getItem("enumValues") as any;
  if (enumValues) {
    enumValues = JSON.parse(enumValues);
    const enumValue = enumValues.find(
      (item: any) => item.propertyName === propertyName
    );
    return enumValue?.keyAndDisplayValueList;
  }
  return [];
};

export const getEnumValue = (propertyName: string, key?: string) => {
  const value = getEnumList(propertyName)?.find((item: any) => {
    return item?.key === key;
  });
  return value?.value ? value?.value : key ?? "NA";
};
