import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "CategoryReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setCategoryPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setCategoryPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setCategorySearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

export const { setCategoryPageNo, setCategoryPageSize, setCategorySearchParam } = categorySlice.actions;
export default categorySlice.reducer;