import {
    Col,
    Form,
    notification,
    Row,
    Space,
    Spin,
    Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_SERVICE } from "../../shared/services/api-services";
import FormButton from "../../shared/component/FormButton/FormButton";
import FormTextArea from "../../shared/component/FormTextArea/FormTextArea";
import FormInput from "../../shared/component/FormInput/FormInput";
import FormIconButton from "../../shared/component/FormIconButton/FormIconButton";

const AppContent: React.FC = () => {
    const [faq, setFaq] = useState([]);
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    const [tNc, setTNc] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [faqLoading, setFaqLoading] = useState(false);
    const [privacyPolicyLoading, setPrivacyPolicyLoading] = useState(false);
    const [tNcLoading, setTNcLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getFAQ();
        getPrivacyPolicy();
        getTermsNCondition();
    }, []);

    const onChangeStatus = (event: any, id: any, title: any, desc: any) => {
        // update API
        const payload = {
            active: event,
            title: title,
            description: desc,
            type: "FAQ",
        };
        API_SERVICE.updateAppContent(payload, id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                }
                getFAQ();
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    };
    // Get API
    const getFAQ = (params?: any) => {
        setFaqLoading(true);
        // API_SERVICE.getAppContent("FAQ", params)
        API_SERVICE.getAppContentFAQ("FAQ", params)
            .then(({ data }: any) => {
                if (data?.payload?.content?.length > 0) {
                    setFaq(data.payload.content);
                } else {
                    setFaq([]);
                    notification.error({ message: "No App Content found." });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setFaqLoading(false);
            });
    };

    // Get Privacy_policy
    const getPrivacyPolicy = (params?: any) => {
        setPrivacyPolicyLoading(true);
        API_SERVICE.getAppContent("PRIVACY_POLICY", params)
            .then(({ data }: any) => {
                if (data.payload.length > 0) {
                    setPrivacyPolicy(data.payload);
                } else {
                    notification.error({ message: "No Privacy Policy found." });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setPrivacyPolicyLoading(false);
            });
    };

    // Get Terms and condition
    const getTermsNCondition = (params?: any) => {
        setTNcLoading(true);
        API_SERVICE.getAppContent("TERMS_AND_CONDITIONS", params)
            .then(({ data }: any) => {
                console.log("terms--", data)
                if (data.payload.length > 0) {
                    setTNc(data.payload);
                } else {
                    notification.error({
                        message: "No Terms & Conditions found.",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setTNcLoading(false);
            });
    };

    // payload
    const buildPayload = (formData: any) => {
        let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }

        let desc = formData.answer;
        if ((formData?.videoURL?.length ?? 0) > 0) {
            desc = formData.videoURL + "~" + formData.answer;
        }

        const payload = {
            active: true,
            title: formData.question,
            description: desc,
            type: "FAQ",
        };
        return payload;
    };

    // onSubmit
    const onFinish = (values: any) => {
        // Post API
        const payload = buildPayload(values);
        API_SERVICE.addAppContent(payload)
            .then(({ data }) => {
                setLoading(true);
                if (data) {
                    setLoading(false)
                    notification.success({ message: data.message });
                    setShowForm(false);
                    setFaq([]);
                    getFAQ();
                }
            })
            .catch((e: any) => {
                setLoading(false);
                API_SERVICE.handleErrors(e);
            });
    };

    const onSave = (values: any, id: any) => {
        // update API
        const payload = {
            active: true,
            description: values.privacyPolicy,
            title: "Privacy Policy",
            type: "PRIVACY_POLICY",
        };
        API_SERVICE.updateAppContent(payload, id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                }
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    };

    const onSaveTC = (values: any, id: any) => {
        // update API
        const payload = {
            active: true,
            description: values.termsAndConditions,
            title: "Terms & Conditions",
            type: "TERMS_AND_CONDITIONS",
        };
        API_SERVICE.updateAppContent(payload, id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                }
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    };

    const onSaveFaq = (values: any, id: any) => {
        // update API
        let desc = values.answer;
        if ((values?.videoURL?.length ?? 0) > 0) {
            desc = values.videoURL + "~" + values.answer;
        }

        const payload = {
            active: true,
            description: desc,
            title: values.question,
            type: "FAQ",
        };
        API_SERVICE.updateAppContent(payload, id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                    getFAQ();
                }
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    };

    const deleteHandler = (id: any) => {

        API_SERVICE.deleteAppContent(id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                }
                setFaq([]);
                getFAQ();
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    };

    return (
        <div style={{ paddingTop: '10px' }}>
            {privacyPolicy.length !== 0 ? (
                privacyPolicy.map((p: any, index) => {
                    return (
                        <div style={{ marginBottom: "10px" }}>
                            <p style={{ opacity: "0.6", marginBottom: '-10px' }}>{p.title}</p>
                            <Form layout="vertical" initialValues={{ privacyPolicy: `${p.description}` }} autoComplete="off"
                                name="privacyPolicy" key={index} onFinish={(values) => onSave(values, p.busCommId)}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <FormTextArea
                                            classNameForm="space-input"
                                            name="privacyPolicy"
                                            row={7}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                                    <Col>
                                        <FormButton
                                            size="large"
                                            label="Save"
                                            type="primary"
                                            htmlType="submit"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    );
                })
            ) : (
                <div className="example">{privacyPolicyLoading && <Spin />}</div>
            )}

            {/* TAndC list form */}
            {
                tNc.length !== 0 ? (
                    tNc.map((t: any, index) => {
                        return (
                            <div style={{ marginBottom: "10px" }}>
                                <p style={{ opacity: "0.6", marginBottom: '-10px' }}>{t.title}</p>
                                <Form layout="vertical" initialValues={{ termsAndConditions: `${t.description}` }} autoComplete="off"
                                    name="termsAndConditions" key={index} onFinish={(values) => onSaveTC(values, t.busCommId)}>
                                    <Row gutter={[16, 16]} >
                                        <Col span={24}>
                                            <FormTextArea
                                                classNameForm="space-input"
                                                name="termsAndConditions"
                                                row={7}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                                        <Col>
                                            <FormButton
                                                size="large"
                                                label="Save"
                                                type="primary"
                                                htmlType="submit"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        );
                    })
                ) : (
                    <div className="example">{tNcLoading && <Spin />}</div>
                )
            }

            {/* FAQ SECTION */}
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <span style={{ opacity: "0.6" }}>Help</span>
                </Col>
            </Row>

            {/* FAQ List */}
            {faq.length !== 0 ? (
                faq.map((f: any, index) => {
                    return (
                        <div >
                            <Form layout="vertical" initialValues={{
                                question: `${f.title}`,
                                videoURL: `${f.description}`.split("~").length >= 2 ? (`${f.description}`.split("~")[0].startsWith("https://vimeo.com") ? `${f.description}`.split("~")[0] : ``) : ``,
                                answer: `${f.description}`.split("~").length >= 2 ? `${f.description}`.split("~")[1] : `${f.description}`,
                            }} autoComplete="off" name="faq" key={index} onFinish={(values) => onSaveFaq(values, f.busCommId)}>
                                <Row gutter={[24, 24]}>
                                    <Col span={12}>
                                        <FormInput
                                            name={"question"}
                                            placeholder="Enter your question"
                                            isRequired
                                            requiredLabel="question"
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <FormInput
                                            name={"videoURL"}
                                            placeholder="Enter your video URL"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <FormTextArea
                                            classNameForm="space-input"
                                            name="answer"
                                            isRequired
                                            requiredLabel="answer"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} justify="end">
                                    <Col span={1} style={{ justifyContent: "end" }}>
                                        <FormIconButton
                                            type={"delete"}
                                            isShowPopconfirm
                                            onClick={() => deleteHandler(f.busCommId)}
                                        />
                                    </Col>
                                    <Col span={1} style={{ justifyContent: "end" }}>
                                        <Switch defaultChecked={f.active} style={{ marginTop: "5px" }} onChange={(event) => onChangeStatus(event, f.busCommId, f.title, f.description)} />
                                    </Col>
                                    <Col span={3} style={{ display: "flex", justifyContent: "end" }}>
                                        <FormButton
                                            size="large"
                                            label="Save"
                                            type="primary"
                                            htmlType="submit"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    );
                })
            ) : (
                <div className="example">{faqLoading && <Spin />}</div>
            )
            }

            {/* Add New FAQ Button */}
            {showForm !== true && (
                <Row gutter={[24, 24]} style={{ display: "flex" }}>
                    <Col>
                        <FormButton
                            size="large"
                            label="Add New FAQ Item"
                            type="primary"
                            htmlType="submit"
                            onclick={() => setShowForm(true)}
                        />
                    </Col>
                </Row>
            )
            }

            {/* Add New FAQ */}
            {showForm && (
                <Form layout="vertical" title="Help" autoComplete="off" name="FAQ" onFinish={onFinish}>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <FormInput
                                name={"question"}
                                placeholder="Enter your question"
                                isRequired
                                requiredLabel="question"
                            />
                        </Col>
                        <Col span={12}>
                            <FormInput
                                name={"videoURL"}
                                placeholder="Enter your video URL"
                            />
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <FormTextArea
                                classNameForm="space-input"
                                name="answer"
                                placeholder="Enter your answer"
                                isRequired
                                requiredLabel="answer"
                            />
                        </Col>
                    </Row>
                    <Space className='step-button'>
                        <FormButton
                            size="large"
                            type="default"
                            label="Cancel"
                            onclick={() => setShowForm(false)}
                        />
                        <FormButton
                            size="large"
                            type="primary"
                            htmlType="submit"
                            label="Save"
                            loading={loading}
                        />
                    </Space>
                </Form >
            )
            }
        </div >
    );
};

export default AppContent;
