import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Col, Form, Input, notification, Select, Row, Table } from "antd";
import { debounce } from "lodash";
import { ColumnsType } from "antd/es/table/interface";
import search from "../../assets/images/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../shared/redux/store";
import { API_SERVICE } from "../../shared/services/api-services";
import { setTenantPageNo, setTenantPageSize, } from "../../shared/redux/tenant-reducer";
import "./AssignDC.scss";

const AssignDC: React.FC = () => {
    const [tenantList, setTenantList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [assignToData, setAssignToData] = useState([]);
    const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const tenant: any = useSelector((state: RootState) => state.tenant);
    const dispatch = useDispatch();

    useEffect(() => {
        getTenantList();
    }, []);

    const getTenantList = (
        pageNo: number = 1,
        pageSize: any = 10,
        searchVal: any = null
    ) => {
        setLoading(true);
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            searchParam: searchVal,
        };
        API_SERVICE.getTenantList(params)
            .then(({ data }: any) => {
                if ((data?.payload?.content?.length ?? 0) > 0) {
                    data.payload.content.map((connector: any) => {
                        connector.key = connector.userDetails.id;
                    });
                }
                setTenantList(data?.payload?.content ?? []);
                setTotalCount(data?.payload?.totalElements ?? 0);
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePaginationChange = (current: any, pageSize: any) => {
        dispatch(setTenantPageNo(current));
        dispatch(setTenantPageSize(pageSize));
        getTenantList(current, pageSize, tenant.searchParam);
    };

    const columns: ColumnsType<any> = [
        {
            title: "Name",
            dataIndex: ["userDetails", "fullName"],
            key: "fullName",
            render: (text: string) => <span style={{ color: "#4285F4" }}>{text}</span>,
        },
        {
            title: "Mobile No",
            dataIndex: ["userDetails", "phoneNumber"],
            key: "phoneNumber",
        },
        {
            title: "Email",
            dataIndex: ["userDetails", "emailId"],
            key: "emailId",
        },
        {
            title: "City",
            dataIndex: ["address", "city"],
            key: "city",
        },
        {
            title: "Pin Code",
            dataIndex: ["address", "pinCode"],
            key: "pinCode",
        },
        {
            title: "State",
            dataIndex: ["address", "state"],
            key: "state",
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectAssignTo = (item: any, id: any) => {
        setSelectedAssignTo(id.businessInfoId);
    };

    const searchAssignTo = (ev: any) => {
        setSelectedAssignTo(null);
        const params = { filterNameParam: ev };
        API_SERVICE.assignToSearch(params)
            .then(({ data }) => {
                setAssignToData(data?.payload);
            })
            .catch((e: any) => {
            API_SERVICE.handleErrors(e);
            });
    };

    const assignDataList = (selectedRowKeys: any) => {
        API_SERVICE.assignDataList(selectedRowKeys, selectedAssignTo)
            .then(({ data }: any) => {
                setSelectedRowKeys([]);
                notification.success({ message: data.message });
                getTenantList(tenant.pageNo, tenant.pageSize, tenant.searchParam);
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            });
    }

    const tenantAssignToConnector = (values: any) => {
        if ((selectedRowKeys?.length ?? 0) === 0) {
            notification.error({ message: "Please select connector" });
            return;
        }
        if ((selectedAssignTo?.length ?? 0) === 0) {
            notification.error({ message: "Please select assign to" });
            return;
        }
        selectedRowKeys?.map((id: any) => {
            assignDataList(id)
        })

    };

    const handleSearchParam = (values: any) => {
        getTenantList(1, 10, values.searchParam)
    }

    return (
        <>
            <div className="layout-main-top">
                <Form onFinish={handleSearchParam} layout="inline">
                    <Form.Item name='searchParam'>
                        <Input
                            size="large"
                            className="search-input"
                            prefix={<img src={search} alt=""/>}
                            placeholder="Search"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" style={{ height: '40px' }} htmlType="submit">Search</Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={"layout-main"}>
                <div>
                    <Table
                        columns={columns}
                        rowSelection={rowSelection}
                        dataSource={tenantList}
                        loading={loading}
                        onChange={(pagination: any) => {
                            handlePaginationChange(pagination.current, pagination.pageSize);
                        }}
                        pagination={{
                            position: ["bottomCenter"],
                            current: tenant.pageNo,
                            pageSize: tenant.pageSize,
                            total: totalCount,
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div>
            <Form
                onFinish={tenantAssignToConnector}
                style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "10px",
                    gap: "10px",
                }}
            >
                <Form.Item label="Assign To:" name="assignTo" className="assign-title">
                    <AutoComplete
                        className="custom-auto-compleat"
                        onSearch={debounce(searchAssignTo, 1000)}
                        placeholder="Search"
                        onSelect={selectAssignTo}
                    >
                        {assignToData.map((item: any, i: any) => (
                            <Select.Option
                                key={item.businessInfoId}
                                {...item}
                                value={item.businessName}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={16}>
                                        <span>{item.businessName}</span><br />
                                    </Col>
                                </Row>
                            </Select.Option>
                        ))}
                    </AutoComplete>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Assign
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default AssignDC;
