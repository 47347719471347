import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import { API_SERVICE } from "../../shared/services/api-services";
import Property from "./Property/Property";
import Collection from "./Collection/Collection";
import "./properties.scss";
import DrawerFile from "./Property/AddProperty/DrawerFile";
import AddCollection from "./Collection/AddCollection";

const Properties: React.FC = () => {

  const [objectList, setObjectList] = useState([])
  const [selectedObject, setSelectedObject] = useState(null as any);
  const [tabKey, setTabKey] = useState("PROPERTY");
  const [showAddPropery, setShowAddPropery] = useState(false);
  const [editPropertyData, setEditPropertyData] = useState(null as any);
  const [addCollection, setAddCollection] = useState(false);
  const [editCollectionData, setEditCollectionData] = useState(null as any);
  const [isEdit, setIsEdit] = useState(false);


  useEffect(() => {
    getObjectList()
  }, []);


  const getObjectList = (name: any = null) => {
    const params = { type: "OBJECT", name: name };
    API_SERVICE.getPropertyList(params)
      .then(({ data }: any) => {
        const displayNameListData = data.payload.content?.map((item: any) => {
          return { key: item.id, value: item.displayName };
        });
        setObjectList(displayNameListData);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
  };

  const onChangePropertyType = (id: any) => {
    setSelectedObject(id);
  }

  const onSearchPropertyType = (record: any) => {
    getObjectList(record);
  }

  return (
    <>
      {showAddPropery ?
        <DrawerFile
          setShowAddPropery={setShowAddPropery}
          editPropertyData={editPropertyData}
          getPropertyList={() => { }}                  
        />
        :
        addCollection ?
          <AddCollection
            setAddCollection={setAddCollection}
            getCollectionList={() => { }}
            editCollectionData={editCollectionData}
            isEdit={isEdit}
          />
          :
          (<>
            {tabKey === "PROPERTY" ? (
              <div className={"layout-main"} style={{ minHeight: "70px", marginTop: "20px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ padding: "5px" }}>Select an Object:</span>
                  <div>
                    <FormSelect
                      name="propertyType"
                      optionFilterProp="children"
                      placeholder="Enter Property Type"
                      // classNameForm="space-input"
                      options={objectList}
                      onChange={onChangePropertyType}
                      onSearch={onSearchPropertyType}
                      isBorder
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className={"layout-main"} style={{ marginTop: "20px" }}>
              <div style={{ padding: "10px" }}>
                <Tabs activeKey={tabKey} onChange={(key: any) => setTabKey(key)}>
                  <TabPane tab="PROPERTY" key="PROPERTY">
                    <Property
                      selectedObject={selectedObject}
                      setEditPropertyData={setEditPropertyData}
                      setShowAddPropery={setShowAddPropery}                     
                    />
                  </TabPane>
                  <TabPane tab="COLLECTION" key="COLLECTION" >
                    <Collection
                      setAddCollection={setAddCollection}
                      setEditCollectionData={setEditCollectionData}
                      setIsEdit={setIsEdit}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </>)}
    </>
  );
};

export default Properties;
