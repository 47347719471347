import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Tabs,
  notification,
} from "antd";
import FormJosnDataList from "../../ProductJourney/ProspectJourney/FormJosnDataList";
import FormTextArea from "../../../../shared/component/FormTextArea/FormTextArea";
import { useEffect, useState } from "react";
import FormButton from "../../../../shared/component/FormButton/FormButton";
import {
  setJourneyObject,
  setScreenTitle,
} from "../../../../shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../shared/redux/store";
import { viewTypeJourneyProduct } from "../Journey";
import { getEnumList, getEnumValue } from "../../../../shared/Utility/Utility";
import { API_SERVICE } from "../../../../shared/services/api-services";

interface IStartForm {
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
  fetchMasterProductList: any;
  productWorkflowId: any;
}

const StartForm: React.FC<IStartForm> = (props: IStartForm) => {
  const { setViewTypeJourney, fetchMasterProductList, productWorkflowId } =
    props;
  const [disableJson, setDisableJson] = useState(false);
  const [webFileData, setWebFileData] = useState(null as any);
  const [openPreview, setOpenPreview] = useState(false);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [startFormData, setStartFormData] = useState({} as any);
  const [formList, setFormList] = useState([]);
  const [templateType, setTemplateType] = useState("");
  const journey: any = useSelector((state: RootState) => state.journey);
  const dispatch = useDispatch();
  const [formJson] = Form.useForm() as any;

  useEffect(() => {
    startFormConfigration();
  }, []);

  const startFormConfigration = () => {
    API_SERVICE.getStartFormConfiguration(productWorkflowId)
      .then(({ data }) => {
        formListTemplate(data?.payload?.form?.templateId);
        setStartFormData(data?.payload);
        formJson.setFieldsValue({
          selectedForm: data?.payload?.form?.templateId ?? "CUSTOM",
          initialData: getEnumValue(
            "INITIAL_DATA_TRANSFORMER",
            data?.payload?.initialDataTransformer ?? ""
          ),
          webJson: JSON.stringify(
            data?.payload?.form?.definition ?? {},
            undefined,
            2
          ),
        });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const formListTemplate = (templateId: any) => {
    API_SERVICE.formTemplateList()
      .then(({ data }) => {
        const list = data?.payload.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
            formJson: item?.formJson,
            mobileFormJson: item?.mobileFormJson,
            templateType: item?.templateType,
            componentCode: item?.componentCode,
          };
        });
        list.push({
          value: "CUSTOM",
          label: "Custom",
        });
        setFormList(list ?? []);
        const findType: any = list?.find(
          (item: any) => item?.value == templateId
        );
        setTemplateType(findType?.templateType);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onSelectForm = (value: any) => {
    const findType: any = formList.find((item: any) => item?.value == value);
    setTemplateType(findType?.templateType);
    if (findType?.templateType == "REACT_UI") {
      setIsSaveDisable(false);
      const tmpStartData = { ...startFormData };
      tmpStartData.form = {
        componentCode: findType?.componentCode,
        templateId: value,
      };
      setStartFormData(tmpStartData);
    } else {
      setDisableJson(value != "CUSTOM");
      setOpenPreview(false);
      setIsSaveDisable(true);
      formJson.setFieldsValue({
        webJson: findType?.formJson
          ? JSON.stringify(findType?.formJson ?? {}, undefined, 2)
          : null,
      });
    }
  };

  const onInitialData = (value: any) => {
    const tmpStartData = { ...startFormData };
    tmpStartData.initialDataTransformer = value;
    setStartFormData(tmpStartData);
    setIsSaveDisable(false);
  };

  const previewDataList = (values: any) => {
    try {
      setWebFileData(JSON?.parse(values?.webJson));
      setOpenPreview(true);
      setIsSaveDisable(false);
      const tmpStartData = { ...startFormData };
      tmpStartData.form = {
        definition: JSON?.parse(values?.webJson),
        mobileDefinition: {},
        templateId: disableJson ? values?.selectedForm : null,
      };
      setStartFormData(tmpStartData);
    } catch (error) {
      notification.error({ message: "JSON not valid" });
    }
  };

  const updateStartFormData = () => {
    const payload = startFormData;
    API_SERVICE.updateStartFormConfiguration(productWorkflowId, payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  return (
    <div className={"layout-main"} style={{ marginTop: "20px" }}>
      <Form
        layout="inline"
        form={formJson}
        style={{ padding: "10px" }}
        onFinish={previewDataList}
      >
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <Space
              style={{ flex: 1, justifyContent: "center" }}
            >
              <Form.Item label="Initial Data Transformer" name={"initialData"}>
                <Select
                  style={{ width: "150px" }}
                  // options={getEnumList("INITIAL_DATA_TRANSFORMER")}
                  allowClear
                  className="select-loan"
                  dropdownStyle={{ width: "250px" }}
                  onChange={onInitialData}
                >
                  {getEnumList("INITIAL_DATA_TRANSFORMER")?.map(
                    (option: any, i: number) => (
                      <Select.Option key={i} value={option.key}>
                        {option.value}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item label="Form" name={"selectedForm"}>
                <Select
                  style={{ width: "150px" }}
                  options={formList}
                  allowClear
                  className="select-loan"
                  dropdownStyle={{ width: "250px" }}
                  defaultValue={"CUSTOM"}
                  onChange={onSelectForm}
                />
              </Form.Item>
            </Space>
            <Button
              type="link"
              style={{ fontSize: "16px" }}
              onClick={() => {
                setViewTypeJourney("journey");
                dispatch(setScreenTitle("Products Journeys"));
                dispatch(setJourneyObject({}));
                fetchMasterProductList(
                  journey?.pageNo,
                  journey?.pageSize,
                  journey.searchParam
                );
              }}
            >
              Back to Journey
            </Button>
          </div>
          <Divider />
          {templateType != "REACT_UI" && (
            <Row>
              <Col span={12}>
                <Tabs defaultActiveKey="form" className="Json-journey-tab">
                  <Tabs.TabPane
                    style={{ padding: "0 10px" }}
                    key="form"
                    tab={<span className="tabActive">FORM JSON</span>}
                  >
                    <FormTextArea
                      classNameForm="space-input disabled-text"
                      isBorder={true}
                      row={10}
                      name="webJson"
                      placeholder="Enter Form Json"
                      isRequired
                      requiredLabel="Form Json"
                      isDisabled={disableJson}
                      onChange={() => {
                        setOpenPreview(false);
                        setIsSaveDisable(true);
                      }}
                    />

                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{ marginTop: "10px" }}
                    >
                      Preview
                    </Button>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
              <Col span={12} style={{ padding: "65px 35px" }}>
                {openPreview && (
                  <Card title="Preview" className="card-Preview">
                    <div
                      style={{
                        height: "250px",
                        overflow: "auto",
                        padding: "20px",
                      }}
                    >
                      <FormJosnDataList fileData={webFileData} />
                    </div>
                  </Card>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Form>
      <Divider />
      <div
        style={{
          display: "grid",
          justifyContent: "end",
          alignItems: "center",
          height: "150px",
          width: "90%",
          marginRight: "20px",
        }}
      >
        <FormButton
          type="primary"
          style={{ width: "130px", height: "40px" }}
          size="large"
          label="Save"
          disabled={isSaveDisable}
          onclick={updateStartFormData}
        />
      </div>
    </div>
  );
};

export default StartForm;
