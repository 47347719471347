import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const dispositionSlice = createSlice({
    name: "DispositionReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setDispositionPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setDispositionPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setDispositionSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setDispositionPageNo, setDispositionPageSize, setDispositionSearchParam } = dispositionSlice.actions;
export default dispositionSlice.reducer;