import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const tenantSlice = createSlice({
    name: "TenantReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setTenantPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setTenantPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setTenantSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setTenantPageNo, setTenantPageSize, setTenantSearchParam } = tenantSlice.actions;
export default tenantSlice.reducer;