import React, { useEffect, useState } from "react";
import { Button, Drawer, Popconfirm, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import editIcon from "../../../assets/images/edit_white.svg";
import deleteIcon from "../../../assets/images/delete_gray.svg";
import FormButton from "../../../shared/component/FormButton/FormButton";
import AddCollection from "./AddCollection";
import NotificationMessage from "../../../shared/component/NotificationMessage/NotificationMessage";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useDispatch, useSelector } from "react-redux";
import {
  setCollectionPageNo,
  setCollectionPageSize,
} from "../../../shared/redux/collection-reducer";
import { RootState } from "../../../shared/redux/store";
import { IoCopy } from "react-icons/io5";
import "../properties.scss";

interface ICollection {
  setEditCollectionData: any;
  setIsEdit: any;
  setAddCollection: any
}

interface columns {
  key: React.ReactNode;
  name: string;
  numberOfProperties: number;
}
const Collection: React.FC<ICollection> = (props: ICollection) => {
  const { setEditCollectionData, setIsEdit, setAddCollection } = props;

  const [loading, setLoading] = useState(false);
  const [collectionList, setCollectionList] = useState([] as any);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();
  const collection: any = useSelector((state: RootState) => state.collection);

  const columns: ColumnsType<columns> = [
    // {
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 10,
    //   render: (_: any, record: any) => (

    //     <Button
    //       size="small"
    //       type="text"
    //       icon={<IoCopy />}
    //       onClick={() => navigator.clipboard.writeText(record?.id)}
    //     />

    //   ),
    // },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Number of Properties",
      dataIndex: ["properties"],
      key: "numberOfProperties",
      sorter: true,
      render: (_: any, record: any) => record?.properties?.length ?? 0,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "25%",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space className="action-button">
          <Button
            style={{ borderRadius: "6px", background: "#1A3175" }}
            size="small"
            type="primary"
            icon={<img src={editIcon} />}
            onClick={() => goToEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            placement="topRight"
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            className="remove"
            onConfirm={() => handleDelete(record?.id)}
          >
            <Button
              className="deleteButton"
              size="small"
              icon={<img src={deleteIcon} />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = (id: any) => {
    API_SERVICE.deleteCollection(id)
      .then(() => {
        getCollectionList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  useEffect(() => {
    getCollectionList();
  }, []);

  const goToEdit = (record: any) => {
    API_SERVICE.editCollectionData(record.id)
      .then(({ data }: any) => {
        setEditCollectionData(data?.payload);
        setAddCollection(true);
        setIsEdit(true);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCollectionList = (
    pageNo: number = 1,
    pageSize: any = 10,
    parentId: any = null,
    direction: any = null
  ) => {
    setLoading(true);
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      parentId: parentId,
      direction: direction,
    };
    API_SERVICE.getCollectionList(params)
      .then(({ data }: any) => {
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map((property: any) => {
            property.key = property.id;
          });
        }
        setCollectionList(data?.payload?.content ?? []);
        setTotalCount(data?.payload?.totalElements)
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setCollectionPageNo(current));
    dispatch(setCollectionPageSize(pageSize));
    getCollectionList(current, pageSize);
  };

  return (
    <>
      <FormButton
        label="Create Collection"
        type="primary"
        className="collection-btn"
        onclick={() => {
          setIsEdit(false)
          setAddCollection(true);
          setEditCollectionData({});
        }}
      />
      <Table
        columns={columns}
        dataSource={collectionList}
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          position: ["bottomCenter"],
          current: collection.pageNo,
          pageSize: collection.pageSize,
          total: totalCount,
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
      <NotificationMessage />
    </>
  );
};

export default Collection;
