import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const documentSlice = createSlice({
  name: "DoumentReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam:null },
  reducers: {
    setDoumentPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setDocumentPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setDocumentSearchParam: (state, action: PayloadAction<any>) => {
        state.searchParam = action.payload;
    }
  }
});

export const { setDoumentPageNo, setDocumentPageSize, setDocumentSearchParam } = documentSlice.actions;
export default documentSlice.reducer;