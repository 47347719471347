const APP_URL = process.env.REACT_APP_URL;
const DSA_API_URL = process.env.REACT_APP_DSA_API_URL;
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL;
const LENDER_ECO_SYSTEM_API_URL = process.env.REACT_APP_LENDER_ECO_SYSTEM_API_URL;

export default {
    AppUrl: APP_URL,
    ApiUrlDis: DSA_API_URL,
    ApiUrlCommon: COMMON_API_URL,
    ApiUrlLender: LENDER_ECO_SYSTEM_API_URL,
};