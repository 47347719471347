import React, { useEffect, useState } from "react";
import { Divider, Drawer, Form, Space, Spin, Typography } from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../../shared/services/api-services";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import { getEnumList } from "../../../shared/Utility/Utility";

const { Title } = Typography;

interface IAddDocumentType {
  openAdd: any;
  setOpenAdd: any;
  isEdit: boolean;
  editDocumentData: any;
  getDocumentTypeList: any;
}

const AddDocumentType: React.FunctionComponent<IAddDocumentType> = (
  props: IAddDocumentType
) => {
  const { openAdd, setOpenAdd, isEdit, editDocumentData, getDocumentTypeList } =
    props;

  const [categoryListOption, setCategoryListOption] = useState([] as any);
  const [fileTypes, setFileTypes] = useState([
    { key: "PNG", value: "png" },
    { key: "JPG", value: "jpg" },
  ] as any);
  const documentType: any = useSelector(
    (state: RootState) => state.documentType
  );

  const [productForm] = Form.useForm() as any;

  useEffect(() => {
    if (isEdit) {
      productForm.setFieldsValue({
        documentName: editDocumentData?.name,
        documentType: editDocumentData?.type,
        maximumSizeInMB: editDocumentData?.maximumSizeInMB,
        category: editDocumentData?.documentCategoryResponseList?.map(
          (item: any) => item.id
        ),
        fileTypes: editDocumentData?.appliedFileTypes?.map((item: any) => item),
      });
    }
    getDocumentCategorys();
  }, []);

  const getDocumentCategorys = () => {
    const params = { docStatus: "ACTIVE", pageSize: 100};
    API_SERVICE.getDocumentCategorys(params)
      .then((data) => {
        const categoryListOptionData = data?.data?.payload?.content?.map(
          (item: any) => {
            return { key: item.id, value: item.name };
          }
        );
        setCategoryListOption(categoryListOptionData);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const documentNameOnChange = (value: any) => {
    if (!isEdit) {
      productForm.setFieldsValue({
        documentType: value?.target?.value.replaceAll(" ", "_"),
      });
    }
  };

  const createDocumentType = (payload: any) => {
    API_SERVICE.createDocumentType(payload)
      .then((data) => {
        setOpenAdd(false);
        getDocumentTypeList(
          documentType.pageNo,
          documentType.pageSize,
          documentType.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const updateDocumentType = (payload: any) => {
    API_SERVICE.updateDocumentType(payload)
      .then((data) => {
        setOpenAdd(false);
        getDocumentTypeList(
          documentType.pageNo,
          documentType.pageSize,
          documentType.searchParam
        );
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const onFinalClick = (value: any) => {
    const createpayload = {
      appliedFileTypes: value?.fileTypes,
      documentCategoryId: value.category,
      maximumSizeInMB: value.maximumSizeInMB,
      name: value.documentName,
      type: value.documentType,
    };

    if (isEdit) {
      const updatepayload = {
        ...createpayload,
        id: editDocumentData.id,
      };
      updateDocumentType(updatepayload);
    } else {
      createDocumentType(createpayload);
    }
  };

  const handleListSearch = (record: any) => {
    getDocumentCategorys();
  };

  return (
    <>
      <Title level={5} style={{ paddingTop: "17px" }}>
        {isEdit ? "EDIT DOCUMENT TYPE" : "ADD NEW DOCUMENT TYPE"}
      </Title>
      <Divider />
      <Form
        form={productForm}
        layout="vertical"
        onFinish={onFinalClick}
        style={{ maxWidth: "400px" }}
      >
        <FormInput
          label="Document Name"
          classNameForm="space-input"
          isBorder
          name="documentName"
          placeholder="Enter Document Name"
          requiredLabel="Document Name"
          onChange={documentNameOnChange}
          isRequired
        />
        <FormInput
          label="Document Type"
          classNameForm="space-input"
          isBorder
          name="documentType"
          isReadonly
          placeholder="Enter Document Type"
        />

        <FormSelect
          label="Select Category"
          name="category"
          optionFilterProp="children"
          classNameForm="space-input"
          placeholder="Select Category"
          mode="multiple"
          maxTagCount="responsive"
          disabled={isEdit}
          isBorder
          options={categoryListOption}
          onSearch={debounce(handleListSearch, 1000)}
          isRequired
        />

        <FormInput
          label="Max FileSize(In MB)"
          classNameForm="space-input"
          isBorder
          name="maximumSizeInMB"
          placeholder="Enter maximumSizeInMB"
          requiredLabel="maximumSizeInMB"
          type="number"
          isRequired
        />

        <FormSelect
          label="Select File Types"
          name="fileTypes"
          optionFilterProp="children"
          classNameForm="space-input"
          placeholder="Select File Types"
          mode="multiple"
          maxTagCount="responsive"
          isBorder
          options={getEnumList("APPLICABLE_FILE_TYPES")}
          isRequired
        />

        <div>
          <Space className="step-button gap-20">
            <FormButton
              size="large"
              label="Save"
              type="primary"
              htmlType="submit"
            />
            <FormButton
              size="large"
              label={"Cancel"}
              type="default"
              onclick={() => {
                setOpenAdd(false);
              }}
            />
          </Space>
        </div>
      </Form>
    </>
  );
};

export default AddDocumentType;
