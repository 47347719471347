import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Form, Select, Space, notification } from "antd";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { viewTypeProduct } from "./Products";
import {
  setProductsObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import { setDecisioningSearchParam } from "../../../shared/redux/decisioning-reducer";
import { debounce } from "lodash";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import { RootState } from "../../../shared/redux/store";

interface IAssignProduct {
  setViewType: React.Dispatch<React.SetStateAction<viewTypeProduct>>;
  productData: any;
  getProductList: any;
}

const AssignProduct: React.FC<IAssignProduct> = (props: IAssignProduct) => {
  const { setViewType, productData, getProductList } = props;

  const [tanentList, setTanentlist] = useState([]);
  const [productList, setProductList] = useState([]);
  const [searchProductList, setSearchProductList] = useState([]);
  const [selectedTanent, setSelectedTanent] = useState("");
  const [selectedproduct, setSelectedProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const product: any = useSelector((state: RootState) => state.product);
  const [addProductForm] = Form.useForm() as any;

  useEffect(() => {
    searchTenant("");
  }, []);

  const selectTenant = (item: any, id: any) => {
    setSelectedTanent(id.key);
    setSelectedProduct("");
    addProductForm?.setFieldsValue({ loanproduct: "" });
    API_SERVICE.getLoanProduct(id.key)
      .then(({ data }) => {
        if (data.payload.length > 0) {
          setProductList(data.payload);
          setSearchProductList(data.payload);
        } else {
          API_SERVICE.handleErrors("No Loan Product found.");
        }
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const searchTenant = (ev: any) => {
    const params = { pageNo: 1, pageSize: 50, filterNameParam: ev };
    API_SERVICE.getListTenants(params)
      .then(({ data }) => setTanentlist(data?.payload?.content ?? []))
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const backproduct = () => {
    setViewType("product");
    dispatch(setScreenTitle("Products"));
    dispatch(setProductsObject({}));
    dispatch(setDecisioningSearchParam(null));
  };

  const onFinish = (value: any) => {
    const params = {
      masterProductID: productData?.productId,
      businessID: selectedTanent,
      loanProductId: selectedproduct,
      active: true,
    };
    setLoading(true);
    API_SERVICE.mapMasterLoanProduct(params)
      .then(({ data }) => {
        notification.success({ message: "Product assigned successfully!" });
        getProductList(product.pageNo, product.pageSize, product.searchParam);
        backproduct();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={"layout-main mt-20"}>
        <Form name="asd" form={addProductForm} onFinish={onFinish} style={{ padding: "10px", gap: "10px" }}>
          <Form.Item
            label="Tenant:"
            name="assignTo"
            className="assign-title"
            style={{ marginLeft: "40px" }}
            rules={[
              {
                required: true,
                message: `Please select Tenant`,
              },
            ]}
          >
            <AutoComplete
              className="custom-auto-compleat"
              onSearch={debounce(searchTenant, 1000)}
              placeholder="Search"
              onSelect={selectTenant}
            >
              {tanentList.map((item: any, i: any) => (
                <Select.Option
                  key={item.businessInfoId}
                  value={item.businessName}
                >
                  {item.businessName}
                </Select.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item
            label="Loan product:"
            name="loanproduct"
            className="assign-title"
            rules={[
              {
                required: true,
                message: `Please select Loan product`,
              },
            ]}
          >
            <AutoComplete
              className="custom-auto-compleat"
              placeholder="Search"
              onSearch={(ev: any) => {
                const search = productList.filter((item: any) =>
                  item.loanProduct.toLowerCase().includes(ev.toLowerCase())
                );
                setSearchProductList(search);
              }}
              onSelect={(_: any, option: any) => setSelectedProduct(option.key)}
            >
              {searchProductList.map((item: any, i: any) => (
                <Select.Option
                  key={item.loanProductId}
                  value={item.loanProduct}
                >
                  {item.loanProduct}
                </Select.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Space style={{ marginLeft: "110px" }}>
            <Form.Item name={"wde"}>
              <Button type="primary" htmlType="submit">
                Assign
              </Button>
            </Form.Item>
            <Form.Item name={"we"}>
              <Button type="default" onClick={backproduct}>
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </div>
      {loading && <AppLoading />}
    </>
  );
};

export default AssignProduct;
