import { Card, Divider, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
// @ts-ignore
import { Form as Formio } from "react-formio";
import "../ScoreCard/scoreCard.scss";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import OfferTestOutput from "./OfferTestOutput";

const { Title } = Typography;

interface ITestOfferCalculation {
  setIsShowTest: any;
  offerCalcData: any;
  setIsPublishDisabled:any;
}

const TestOfferCalculation: React.FunctionComponent<ITestOfferCalculation> = (
  props: ITestOfferCalculation
) => {
  const { setIsShowTest, offerCalcData, setIsPublishDisabled } = props;
  const [jsonSchema, setJsonSchema] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestedData, setIsTestedData] = useState(false);
  const [testedData, setTestedData] = useState({} as any);

  useEffect(() => {
    generateDecisioning();
  }, []);

  const generateDecisioning = () => {
    let payload = {
      offerCalculationId: offerCalcData?.offerCalculationId,
    };
    API_SERVICE.generateFormTemplate(payload)
      .then(({ data }) => {
        setJsonSchema(data?.payload?.form?.definition ?? {});
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const onTestOffer = (value: any) => {
    setIsLoading(true);
    const payload = {
      data: value?.data,
      decisioningTriggeredMode: "TEST",
      lenderBusinessId: offerCalcData?.lenderBusinessId,
      masterProductId: offerCalcData?.masterProductId,
      offerCalcId: offerCalcData?.offerCalculationId,
      purpose: "ABB",
    };
    API_SERVICE.testOfferCalculationMaster(payload)
      .then(({ data }) => {
        notification?.success({ message: data?.message });
        setTestedData(data?.payload);
        setIsTestedData(true);
        setIsPublishDisabled(false);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={"layout-main"} style={{ marginTop: "20px" }}>
        <Title level={3} className="condition-title">
          Test Required data
        </Title>
        <Divider style={{ margin: "0" }} />
        <div style={{ padding: "20px", display: "flex" }}>
          <div style={{ width: "50%" }}>
            <Formio
              key={"testDecisioningForm"}
              form={jsonSchema}
              jsonSchema={jsonSchema}
              onSubmit={onTestOffer}
              onCustomEvent={() => {
                setIsShowTest(false);
                setIsTestedData(false);
              }}
            />
          </div>
          {isTestedData && (
            <>
              <Divider type="vertical" style={{ height: "500px" }} />
              <div style={{ width: "50%", padding: "25px" }}>
                <OfferTestOutput offerTestedData={testedData} />
                {/* <Card title="Test Output">
                  <p className="tested-data">
                    Max Loan Amount
                    <span>RS. {testedData?.maxAllowedLoanAmount}</span>
                  </p>
                  <p className="tested-data">
                    Total EMI
                    <span>RS. {testedData?.totalEMI}</span>
                  </p>
                  <p className="tested-data">
                    EMI Per Lakh
                    <span>RS. {testedData?.emiPerLakh}</span>
                  </p>
                  <p className="tested-data">
                    Tenure
                    <span>{testedData?.tenureInMonths}</span>
                  </p>
                </Card> */}
              </div>
            </>
          )}
        </div>
        {isLoading && <AppLoading />}
      </div>
    </>
  );
};

export default TestOfferCalculation;
