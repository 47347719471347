import { Divider, Form, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormButton from "../../../shared/component/FormButton/FormButton";
import "./scoreCard.scss";
import { API_SERVICE } from "../../../shared/services/api-services";
const { Title } = Typography;

interface IPublishComment {
    setOpenPublishComment: any;
    decisionData: any;
    getDraftList:any;
}

const PublishComment: React.FunctionComponent<IPublishComment> = (props: IPublishComment) => {
    const { setOpenPublishComment, decisionData, getDraftList } = props;
    const [publishCommentForm] = Form.useForm() as any;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOpenPublishComment(true)
    }, []);

    const generateDecisioning = (value: any) => {
        setLoading(true);
        let payload = {
            publishingComment: value.commentPublish
        }
        API_SERVICE.generateDecisioning(decisionData.id, payload)
            .then((data) => {
                getDraftList();
                setOpenPublishComment(false);
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            }).finally(() => setLoading(false));
    };

    return (
        <>
            <div className={"layout-main"} style={{ marginTop: "20px" }}>
                <Title level={3} className="condition-title">
                    Publish Comment
                </Title>
                <Divider style={{ margin: "0" }} />
                <div className="decisioning-form">
                    <Form
                        form={publishCommentForm}
                        layout="vertical"
                        onFinish={generateDecisioning}
                    >
                        <FormTextArea
                            label="Comment"
                            classNameForm="space-input"
                            isBorder={true}
                            row={4}
                            name="commentPublish"
                            placeholder="Publish Comment..."
                            isRequired
                        />

                        <Space style={{ marginTop: "20px" }}>
                            <FormButton
                                size="large"
                                label="Save"
                                type="primary"
                                loading={loading}
                                htmlType="submit"
                            />
                            <FormButton
                                size="large"
                                label="Cancel"
                                onclick={() => setOpenPublishComment(false)}
                            />

                        </Space>
                    </Form>
                </div>
            </div>
        </>
    );
};


export default PublishComment;
